import { axiosApi, setToken } from "./index";

export async function getGameList(searchTerm = null) {
  let params = new URLSearchParams();
  if (searchTerm) params.append("name", searchTerm);
  setToken();
  return await axiosApi.get("games/", { params });
}

export async function updateGameData(model, id) {
  setToken();
  return await axiosApi.put(`game-update/${id}/`, model);
}

export async function getGameBySlug(slug) {
  setToken();
  return await axiosApi.get(`games/${slug}/`);
}
