import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getReferralDetail } from "../../services/referral_api_helper";
import { Link } from "react-router-dom";

const ViewDetails = (props) => {
  const [referralDetail, setReferrals] = useState([]);
  const [referralList, setLists] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getReferralDetails();
  }, []);

  const getReferralDetails = () => {
    setLoader(true);
    let userId = props.match.params.userId;
    if (userId) {
      getReferralDetail(userId).then((res) => {
        setReferrals(res);
        setLists(res.referral_details);
        setLoader(false);
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="REFERRAL Details" />
        <Row>
          <Row className="mb-4">
            <Col>
              <p>
                <Link to={"/referrals"}>
                  <i className="mdi mdi-arrow-left"></i> back
                </Link>
              </p>
            </Col>
          </Row>
          {loader ? (
            <div class="spinner-grow spinner-class" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <Col lg={12}>
              <Card className="mb-0">
                {referralDetail && (
                  <CardBody>
                    <Row>
                      <Col className="col-12 mb-4">
                        <h5>User details</h5>
                      </Col>
                      <Col className="col-12 mb-3">
                        Username : {referralDetail?.username}
                      </Col>
                      <Col className="col-12 mb-3">
                        Email : {referralDetail?.email}
                      </Col>
                      <Col className="col-12 mb-3">
                        Total Referral Earnings :
                        {referralDetail?.overall_earn === null
                          ? " $ 0.00"
                          : ` $ ${referralDetail?.overall_earn?.toFixed(2)}`}
                      </Col>{" "}
                      <Col className="col-12 mb-3">
                        Referral Earnings this Month :
                        {referralDetail?.monthly_earn === null
                          ? " $ 0.00"
                          : ` $ ${referralDetail?.monthly_earn?.toFixed(2)}`}
                      </Col>
                      <Col className="col-12 mb-3">
                        Total Successful Referrals :{" "}
                        {referralDetail?.total_join}
                      </Col>
                      <Col className="col-12 mb-3">
                        Referral Code : {referralDetail?.referral_code}
                      </Col>
                    </Row>

                    <div className="mt-3">
                      <h5>Earning details:</h5>
                    </div>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered list-of-all-users-table"
                        >
                          <Thead>
                            <Tr>
                              <Th>Friend’s Username</Th>
                              <Th>Earnings till date from referral</Th>
                              <Th>Date of Joining</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {referralList.length === 0 ? (
                              <h5 className="text-center my-5">
                                No Referrals Found
                              </h5>
                            ) : (
                              referralList.map((item, index) => (
                                <Tr>
                                  <Td>{item.referral__username}</Td>
                                  <Td>{item.earn}</Td>
                                  <Td>
                                    {new Date(
                                      item.referral__create_date
                                    ).toLocaleString("en-US", {
                                      //weekday: "short", // long, short, narrow
                                      day: "numeric", // numeric, 2-digit
                                      year: "numeric", // numeric, 2-digit
                                      month: "long", // numeric, 2-digit, long, short, narrow
                                      hour: "numeric", // numeric, 2-digit
                                      minute: "numeric", // numeric, 2-digit
                                      second: "numeric", // numeric, 2-digit
                                      timeZone: "UTC",
                                      hour12: false,
                                    })}
                                  </Td>
                                </Tr>
                              ))
                            )}
                          </Tbody>
                          {/* <Tbody>
                            {loader ? (
                              <div
                                class="spinner-grow spinner-class"
                                role="status"
                                style={{ marginTop: "40px" }}
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : referrals.length === 0 ? (
                              <h5 className="text-center my-5">
                                No Referrals Found
                              </h5>
                            ) : (
                              referrals.map((item, index) => (
                                <Tr key={index}>
                                  <Th>{item.username || "NA"}</Th>
                                  <Td>$ {item.total_earn || 0}</Td>
                                  <Td>{item.total_referrals || "NA"}</Td>
                                  <Td>
                                    $ {item?.monthly_earn.toFixed(2) || 0}
                                  </Td>
                                  
                                </Tr>
                              ))
                            )}
                          </Tbody> */}
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ViewDetails;
