import { axiosApi, setToken } from "./index";
// API Methods
export async function getBannerList() {
  setToken();
  return await axiosApi.get("sliderimage/");
}

export async function getBannerDetail(sliderId) {
  setToken();
  return await axiosApi.get(`sliderimage/${sliderId}/`);
}

export async function editBanner(id, model) {
  setToken();
  return await axiosApi.put(`sliderimage/${id}/`, model);
}

export async function addBanner(model) {
  setToken();
  return await axiosApi.post(`sliderimage/`, model);
}

export async function removeBanner(id) {
  setToken();
  return await axiosApi.delete(`sliderimage/${id}/`, {});
}
