import { Button } from "reactstrap";
import React, { useState } from "react";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/bin.svg";
import SweetAlert from "react-bootstrap-sweetalert";

function CMSRow({
  questionData = {},
  editCallBack = () => {},
  deleteCallback = () => {},
  enableEditView = () => {},
  deleteTopic = () => {},
  editTopic = () => {},
  isFaq = false,
  isFaqHeading = false,
  disableDeleteTopic = false,
  data,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showAlert = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };
  const closeAlert = () => setShowDeleteModal(false);
  const questionStyle = (isFaqL) => (isFaqL ? "faqList" : "ppkList");
  return (
    <>
      <div
        className={`d-flex justify-content-between ${
          isFaqHeading ? "faqHeadingList" : questionStyle(isFaq)
        }`}
      >
        <span
          className={`${isFaqHeading ? "faq-heading" : ""} ppkquestionWidth`}
        >
          {questionData?.question}
        </span>

        <div>
          {data?.change || data?.view ? (
            <Button
              type="submit"
              color="primary"
              className="edit-icon cmsEdit"
              onClick={(e) => {
                e.stopPropagation();
                enableEditView({ questionData: questionData });
              }}
            >
              <img alt="user-tick-icon" src={editIcon} />
            </Button>
          ) : null}
          {data?.delete ? (
            <Button
              type="submit"
              color="primary"
              className="edit-icon cmsEdit"
              onClick={showAlert}
              disabled={disableDeleteTopic}
            >
              <img alt="user-tick-icon" src={deleteIcon} />
            </Button>
          ) : null}
        </div>
      </div>
      {showDeleteModal ? (
        <SweetAlert
          title={isFaqHeading ? "Delete Topic" : "Delete Question"}
          warning
          showCancel
          confirmButtonText="Yes"
          confirmBtnBsStyle="success"
          cancelButtonText="No"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            if (isFaqHeading) {
              deleteTopic({
                topicSlug: questionData.topicData?.category?.slug,
                callback: closeAlert,
              });
            } else {
              deleteCallback({
                questionId: questionData?.id,
                callback: closeAlert,
              });
            }
          }}
          onCancel={() => closeAlert()}
        >
          Are you sure you want to delete this{" "}
          {isFaqHeading ? "topic" : "question"}
        </SweetAlert>
      ) : null}
    </>
  );
}

export default CMSRow;
