import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link, withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import ReactQuill from "react-quill"; //Editor
import "react-quill/dist/quill.snow.css";
// import switch
import Switch from "react-switch";
// import Dropdown Select
import Select from "react-select";
import { getGameList } from "../../services/game_api_helper";
import { addDemoPage, getPinnedList } from "../../services/demo_api_helper";
import { Multiselect } from "multiselect-react-dropdown";
import EditorToolbar, { formats, modules } from "../CMS/EditorToolbar";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../helpers/StringConstant";
// const modules = {
//   toolbar: [
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//     ["clean"],
//   ],

//   clipboard: {
//     // toggle to add extra line breaks when pasting HTML:
//     matchVisual: false,
//   },
// };

// const formats = [
//   "header",
//   "font",
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "bullet",
//   "indent",
// ];
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};
const AddDemoPage = (props) => {
  const goToListing = () => {
    props.history.push("/gameDemo");
  };

  //   showLoader(isLoad) {
  //     this.setState({
  //       isLoading: isLoad,
  //     });
  //   }
  useEffect(() => {
    gameList();
    pinnedList();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [optionGroup, setOptionGroup] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);
  //const [values, setValues] = useState(null);

  const [contentText, setContents] = useState(null);

  // const [disabled, setDisabled] = useState(false);

  const [contentFieldError, setContentFieldError] = useState("");
  const [selectGameError, setGameError] = useState("");
  const [active, setActive] = useState(true);
  const [errMsg, setErrorMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [replaceWith, setReplaceWith] = useState(null);
  const [pinDemo, setPinDemo] = useState(false);
  // const handleChange = (event) => {
  //   setValues({ [event.target.name]: event.target.value });
  // };
  const gameList = () => {
    setLoading(true);
    getGameList().then((res) => {
      let dataValue = res
        .map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          slug: item.slug,
        }))
        .filter((item) => item.game_status == 1);
      setOptionGroup(dataValue);
      //setSelectedGame(data[0]);
    });
    setLoading(false);
  };
  const pinnedList = () => {
    getPinnedList().then((res) => {
      let dataPin = res.results.map((item) => ({
        name: item.title,
        id: item.id,
      }));
      setOptions(dataPin);
    });
  };
  const handleSelectGroup = (selected) => {
    setSelectedGame(selected.value);
    setGameError("");
  };
  const setContent = (content, delta, source, editor) => {
    let rules = editor.getHTML();
    setContents(rules + "");
    setContentFieldError("");
    //setDisabled(false);
  };

  const handleValidSubmit = async (event, values) => {
    event.preventDefault();
    if (selectedGame === null && (contentText === null || contentText === "")) {
      setGameError(ERROR_MESSAGES.validGame);
      setContentFieldError(ERROR_MESSAGES.addContent);
    } else if (selectedGame === null) {
      setGameError(ERROR_MESSAGES.validGame);
    } else if (contentText === null || contentText === "") {
      setContentFieldError(ERROR_MESSAGES.addContent);
    } else {
      let model = {
        game_id: selectedGame,
        title: values.title,
        content: contentText,
        video: values.youtube_link,
        status: active ? 1 : 2,
        is_pinned: pinDemo,
        replace_with: replaceWith === null ? 0 : replaceWith,
      };

      setLoading(true);
      await addDemoPage(model).then(
        (data) => {
          if (data != undefined) {
            toast.success(SUCCESS_MESSAGES.gameDemoAddSuccess, toastrOptions);
          }
          goToListing();
          setLoading(false);
          setShowRaw(false);
        },
        (err) => {
          setLoading(false);
          setErrorMessage(err);
          setShowRaw(false);
        }
      );
    }
  };
  const selectPinned = (optionsSelect) => {
    setReplaceWith(optionsSelect[0].id);
  };
  const [raw_html, setRawHTML] = useState("");
  const [show_raw, setShowRaw] = useState(false);
  const handleClickShowRaw = () => {
    setShowRaw(!show_raw);
    if (show_raw) setRawHTML(contentText === null ? "" : contentText);
    // else setRawHTML(privacyPolicy);

    syncViews(show_raw);
  };

  const syncViews = (fromRaw) => {
    if (fromRaw) {
      setContents(raw_html + "");
    } else {
      setRawHTML(contentText === null ? "" : contentText);
    }
  };
  const handleChangeRaw = (html) => {
    setRawHTML(html + "");
    syncViews(show_raw);
  };
  return (
    <React.Fragment>
      <Loader showLoader={isLoading} />
      <div className="page-content">
        <Breadcrumbs breadcrumbItem={"Add Demo page"} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col>
                    <p>
                      <Link to="/gameDemo">
                        <i className="mdi mdi-arrow-left"></i> back
                      </Link>
                    </p>
                  </Col>
                </Row>
                <Row className="add-staff-member">
                  <Col className="col-lg-8 col-md-8 col-sm-8 col-12">
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div className="mb-3">
                        <Select
                          onChange={(event) => {
                            handleSelectGroup(event);
                          }}
                          options={optionGroup}
                          name="game"
                          classNamePrefix="select2-selection"
                        />
                        <label
                          className="errorMsgGames"
                          style={{ paddingTop: "10px" }}
                        >
                          {selectGameError}
                        </label>
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Add title"
                          placeholder="Enter here"
                          type="text"
                          // onChange={handleChange}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Title is required",
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: "Title can have 50 characters max",
                            },
                          }}
                        />
                      </div>
                      <div className={show_raw ? "mb-3 showRaw" : "mb-3"}>
                        <label
                          className={
                            contentFieldError.length === 0
                              ? ""
                              : "errorMsgGames"
                          }
                        >
                          Add content
                        </label>
                        <EditorToolbar
                          toolbarId="toolbar-1"
                          onClickRaw={handleClickShowRaw}
                        />
                        <ReactQuill
                          theme="snow"
                          modules={{
                            toolbar: {
                              container: `#toolbar-1`,
                            },
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={formats}
                          onChange={setContent}
                          value={contentText || ""}
                          placeholder="Enter content here"
                        />
                        <textarea
                          className={"raw-editor"}
                          onChange={(e) => handleChangeRaw(e.target.value)}
                          value={raw_html}
                        />
                        <label
                          className="errorMsgGames"
                          style={{ paddingTop: "70px" }}
                        >
                          {contentFieldError}
                        </label>
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="youtube_link"
                          label="Youtube Link"
                          placeholder="Enter Youtube Link Here"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Youtube Link is required",
                            },
                            pattern: {
                              value:
                                "^(https?://)?(www.youtube.com|youtu.be)/.+$",
                              errorMessage: "Invalid Youtube Link",
                            },
                          }}
                          // onChange={handleChange}
                        />
                      </div>

                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Pin demo</p>
                          </Col>
                          <Col className="col-lg-3 col-sm-3">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              className="form-label"
                              onChange={() => {
                                setPinDemo(!pinDemo);
                              }}
                              checked={pinDemo}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Replace with</p>
                          </Col>
                          <Col className="col-lg-3 col-sm-3">
                            <Multiselect
                              placeholder="Select"
                              options={options}
                              displayValue="name"
                              onSelect={(optionSelect) =>
                                selectPinned(optionSelect)
                              }
                              singleSelect
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Status</p>
                          </Col>
                          <Col className="col-lg-3 col-sm-3">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              className="form-label"
                              onChange={() => {
                                setActive(!active);
                              }}
                              checked={active}
                            />
                          </Col>
                        </Row>
                      </div>
                      {errMsg ? <p className="error-msg">{errMsg}</p> : null}
                      <FormGroup className="mt-4">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                            disabled={isLoading}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddDemoPage);
