import { SET_GAMES } from "./actionTypes"

const initialState = {
  game: {
    ppkGames: [],
    killRaceGames: [],
    traditionalGames: [],
    allGames:[],
    modeObj:{}
  },
  }
  
  const gameReducer = (state = initialState, {type,payload} = {}) => {
    switch (type) {
      case SET_GAMES : 
      return {...state,game : payload }
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default gameReducer
  