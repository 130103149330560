import { axiosApi, setToken } from "./index";

// API Methods

export async function getGlobalSettings() {
  setToken();
  return await axiosApi.get("config/");
}
export async function setGlobalSettings(model) {
  setToken();
  return await axiosApi.put("config/", model);
}
