import React from "react";

import { Col, Card, CardBody } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  gameStatsTableHeadForApex,
  gameStatsTableHeadForValorant,
  getGameFromId,
} from "../../helpers/util";

const LobbyResult = (props) => {
  const player_stats1 = props.data;

  const player_stats =
    player_stats1?.length === undefined ||
    player_stats1?.length === 0 ||
    player_stats1 === null
      ? ""
      : props?.type === "challenge"
      ? player_stats1.sort(function (a, b) {
          return b.player_winning - a.player_winning;
        })
      : props.gameData?.slug === "valorant"
      ? player_stats1.sort(function (a, b) {
          return b.winnings - a.winnings;
        })
      : player_stats1.sort(function (a, b) {
          return b.kills - a.kills;
        });
  const comparator = (a, b) => {
    return b.player_winning - a.player_winning;
  };
  return (
    <Col lg={8}>
      <Card>
        <CardBody>
          <div className="table-responsive lobby-results-table">
            {props?.type === "challenge" ? (
              <table
                table
                className="table results-table table-striped table-bordered lobbies-table responsiveTable"
              >
                <thead>
                  <tr>
                    <th>Team name</th>
                    <th>Player name</th>
                    <th>Kills</th>
                    <th>Winnings</th>
                    <th>Assists</th>
                    <th>Damage dealt</th>
                    <th>Survival time</th>
                    <th>Platform</th>
                  </tr>
                </thead>
                <tbody>
                  {player_stats === "" ? (
                    <td colspan="7">
                      <h5 className="text-center my-5">No Stat(s) yet</h5>
                    </td>
                  ) : (
                    player_stats &&
                    player_stats.map((stats, idx) => {
                      return stats?.sort(comparator).map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.teams.name}</td>
                            <td>{data.player_name || "NA"}</td>
                            <td>{data.kills}</td>
                            <td>{`$${data.player_winning}`}</td>
                            <td>{data.assists}</td>
                            <td>{data.damage_dealt}</td>
                            <td>{`${data.survival_time} sec`}</td>
                            <td>{data.hardware || "NA"}</td>
                            {/* <td>
                      <p>{stats.extras || "NA"}</p>
                    </td> */}
                          </tr>
                        );
                      });
                    })
                  )}
                </tbody>
              </table>
            ) : (
              <table className="table results-table table-striped table-bordered lobbies-table responsiveTable">
                <thead>
                  {props.gameData?.slug == "apex-legends" ? (
                    <tr>
                      {gameStatsTableHeadForApex.map((data, i) => {
                        return <th key={i}>{data}</th>;
                      })}
                    </tr>
                  ) : props.gameData?.slug == "valorant" ? (
                    <tr>
                      {gameStatsTableHeadForValorant.map((data, i) => {
                        return <th key={i}>{data}</th>;
                      })}
                    </tr>
                  ) : (
                    <tr>
                      <th>Sr. no.</th>
                      <th>Team number</th>
                      <th>Team name</th>
                      <th>Player name</th>
                      <th>Team placement</th>
                      {props.gameData?.slug == "call-of-duty-warzone" ? (
                        <th>Rank</th>
                      ) : (
                        <th>Survival time</th>
                      )}
                      <th>Assists</th>
                      <th>Damage dealt</th>
                      <th>Kills</th>
                      <th>Winnings</th>
                      {/* <th>Extras</th> */}
                      <th>Hardware</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {props?.data.length === undefined ||
                  props?.data?.length === 0 ? (
                    <tr>
                      <td colspan="11">
                        <h5 className="text-center my-5">No stat(s) found</h5>
                      </td>
                    </tr>
                  ) : (
                    player_stats &&
                    player_stats.map((stats, index) => {
                      return props.gameData.slug == "apex-legends" ? (
                        <tr key={index}>
                          <td>{stats.team_name || "NA"}</td>
                          <td>{stats.player_name || "NA"}</td>
                          <td>{stats.kills}</td>
                          <td>$ {stats.winnings}</td>
                          <td>{stats.team_placement}</td>
                          <td>{`${stats.survival_time} sec`}</td>
                          <td>{stats.damage_dealt}</td>
                          <td>{stats.hardware || "NA"}</td>
                          {/* <td>{stats.extras || "NA"}</td> */}
                        </tr>
                      ) : props.gameData.slug == "valorant" ? (
                        <tr key={index}>
                          <td>{stats.team_name || "NA"}</td>
                          <td>{stats.player_name || "NA"}</td>
                          <td>{stats.kills}</td>
                          <td>$ {stats.winnings}</td>
                          <td>{stats.assists}</td>
                          <td>{stats.damage_dealt}</td>
                          <td>{`${stats.survival_time} sec`}</td>
                          <td>{stats.hardware || "NA"}</td>
                          {/* <td>{stats.extras || "NA"}</td> */}
                        </tr>
                      ) : (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{stats.team_number}</td>
                          <td>{stats.team_name || "NA"}</td>

                          <td>{stats.player_name || "NA"}</td>
                          <td>{stats.team_placement}</td>

                          {props.gameData?.slug == "call-of-duty-warzone" ? (
                            <td>{stats.survival_time}</td>
                          ) : (
                            <td>{`${stats.survival_time} sec`}</td>
                          )}
                          <td>{stats.assists}</td>

                          <td>{stats.damage_dealt}</td>
                          <td>{stats.kills}</td>
                          <td>$ {stats.winnings}</td>

                          <td>{stats.hardware || "NA"}</td>
                          {/* <td>{stats.extras || "NA"}</td> */}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LobbyResult;
