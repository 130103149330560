import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link } from "react-router-dom";
import Loader from "../../components/Common/Loader";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import {
  editBanCountry,
  deleteCountryState,
} from "../../services/complaint_user_api_helper";
import { getStates } from "../../services/config_api_helper";

import { Multiselect } from "multiselect-react-dropdown";
import whiteCrossIcon from "../../assets/images/white-cross-icon.svg";
import { SUCCESS_MESSAGES } from "../../helpers/StringConstant";
const EditLocation = (props) => {
  const data = props.location.state.item;
  const [isLoading, setIsloading] = useState(false);
  const [state, setState] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [addMoreState, setAddMoreState] = useState([]);
  useEffect(() => {
    getStateList(data.country_id);
  }, []);
  const getStateList = async (id) => {
    await getStates(id).then((res) => {
      setState(res);
    });
  };
  const handleValidSubmit = async (event, values) => {
    event.preventDefault();
    //for removed list
    // let test = newState.filter(function (item) {
    //   return !addMoreState.includes(item.state_id);
    // });
    // const removed = test.map((item) => item.state_id);
    if (addMoreState.length === 0) {
      showLoader(true);
      await deleteCountryState(data.country_id).then(
        (res) => {
          showLoader(false);
          toast.success(SUCCESS_MESSAGES.deletedSuccess, toastrOptions);
          goToListing();
        },
        (err) => {
          showLoader(false);
        }
      );
    } else {
      let model = {
        country_id: data.country_id,
        location_id: addMoreState,
        location_type: "state",
      };
      showLoader(true);
      await editBanCountry(model, data.country_id).then(
        (_) => {
          showLoader(false);
          toast.success(SUCCESS_MESSAGES.updateSuccess, toastrOptions);
          goToListing();
        },
        (err) => {
          showLoader(false);
        }
      );
    }

    // }
  };

  const goToListing = () => {
    props.history.push("/locationLists");
  };

  const showLoader = (isLoad) => {
    setIsloading(isLoad);
  };
  const checkMultiSelectState = (selectedList, selectedItem) => {
    setAddMoreState(
      Array.isArray(selectedList) ? selectedList.map((x) => x.id) : []
    );
    setIsDisabled(false);
  };
  const onRemoveState = (selectedList, removedItem) => {
    setAddMoreState(
      Array.isArray(selectedList) ? selectedList.map((x) => x.id) : []
    );
    setIsDisabled(false);
  };
  const getMultiStateNames = (options, values) => {
    var finall = [];
    options.map((opt) => {
      for (let i = 0; i < values.length; i++) {
        if (values[i].state_id === opt.id) {
          finall.push({ name: opt.name, id: opt.id });
        }
      }
    });
    return finall;
  };
  return (
    <React.Fragment>
      <Loader showLoader={isLoading} />
      <div className="page-content">
        <Breadcrumbs breadcrumbItem={" Edit location"} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col>
                    <p>
                      <Link to="/locationLists">
                        <i className="mdi mdi-arrow-left"></i> back
                      </Link>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-8">
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="country"
                          label="Country*"
                          placeholder="Enter country here"
                          type="input"
                          value={data.country_name}
                          disabled
                        ></AvField>
                      </div>
                      <div className="mb-3">
                        <label>State</label>
                        <Multiselect
                          placeholder="Select state"
                          options={
                            //state &&
                            state
                          }
                          displayValue="name"
                          onSelect={checkMultiSelectState}
                          onRemove={onRemoveState}
                          selectedValues={getMultiStateNames(
                            //state &&
                            state,
                            data.all_states
                          )}
                          customCloseIcon={
                            <img
                              alt="white-cross-icon"
                              className="whiteCrossIcon"
                              src={whiteCrossIcon}
                            />
                          }
                        />
                      </div>
                      <FormGroup className="mt-4">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                            disabled={isDisabled ? isDisabled : isLoading}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EditLocation;
