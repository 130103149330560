import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Row,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import { getChallengesById } from "../../services/challenges_api_helper";
import classnames from "classnames";
import EnrolledChallengeTeamList from "./EnrolledChallengeTeamList";
import LobbyResult from "../LobbyList/LobbyResult";
import {
  GAME_MAP_CHALLENGES,
  competitionStatus,
  gameTypeEnumForChallenges,
  getGameFromId,
} from "../../helpers/util";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { permissionsStrings } from "../../helpers/StringConstant";
import DeleteAlert from "./DeleteAlert";
import ApexCodesChallenge from "./ApexCodesChallenge";
import { useSelector } from "react-redux";
const ChallengesDetail = (props) => {
  const returnUrl = "/challenges";
  const [teams, setTeam] = useState([]);
  const games = useSelector((state) => state.Games.game?.allGames);
  const [challenges, setChallenges] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const challengeid = window.location.href;
  const challengeId = challengeid.substring(challengeid.lastIndexOf("/") + 1);
  const [activeTab, setActiveTab] = useState("1");
  const [deleteId, setDeleteId] = useState(null);
  const [vs, setVs] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  useEffect(() => {
    if (challengeId > 0) {
      getDetail();
    } else {
      goToBack();
    }
  }, [challengeId]);
  const getDetail = async () => {
    setisLoading(true);
    await getChallengesById(challengeId)
      .then((res) => {
        setChallenges(res);
        const teamList = res.challenge_players;
        const ids = teamList?.map((input) =>
          input.teams === null ? [] : input.teams.team_id
        );
        const uniqIds = [...new Set(ids)];
        const combineSameTeamIds = uniqIds?.map((id) => {
          return teamList.filter((input, idx) =>
            input.teams === null ? input[idx] : input.teams.team_id === id
          );
        });
        setTeam(combineSameTeamIds);
        setisLoading(false);
      })
      .catch(() => {
        setChallenges(null);
        setisLoading(false);
        goToBack();
      });
  };

  const canPerform = (gameObjType, gameObjPermissionType) => {
    const filteredPermission = FilterPermission(props.permission, gameObjType);
    if (filteredPermission.length !== 0) {
      const setdelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        gameObjPermissionType
      );
      return setdelete;
    }
    return false;
  };

  const goToBack = () => {
    props.history.push(returnUrl);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const openAlert = (id, vrs) => {
    setDeleteId(id);
    setVs(vrs);
    setdeleteModal(true);
  };

  const handleCLoseAlert = () => {
    setdeleteModal(false);
  };

  return (
    <>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Challenge Detail" />
        <Row className="mb-4">
          <Col>
            <p>
              <Link to={{ pathname: returnUrl }}>
                <i className="mdi mdi-arrow-left"></i> back
              </Link>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Col lg={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Challenge Details</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Enrolled teams</span>
                  </NavLink>
                </NavItem>
                {challenges?.current_status === 3 ? (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">View Results</span>
                    </NavLink>
                  </NavItem>
                ) : (
                  ""
                )}
              </Nav>
            </Col>
            {isLoading ? (
              <div class="spinner-grow transaction-spinner" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              challenges && (
                <TabContent
                  activeTab={activeTab}
                  className="p-3 text-muted lobby-details-view"
                  style={{
                    marginTop: "-1pc",
                    width: "103%",
                    marginLeft: "-15px",
                  }}
                >
                  {" "}
                  <TabPane tabId="1">
                    <Card className="mb-0">
                      <CardBody>
                        <Row>
                          <Col className="col-12 mb-3">
                            Game :{getGameFromId(games, challenges.game)?.name}
                          </Col>
                          <Col className="col-12 mb-3">
                            Game Type :{" "}
                            {gameTypeEnumForChallenges[challenges.game_type]}
                          </Col>
                          <Col className="col-12 mb-3">
                            Game mode : {challenges?.modetag}
                          </Col>
                          <Col className="col-12 mb-3">
                            Competition :{" "}
                            {challenges?.versus.includes("N/A")
                              ? "Open"
                              : "Team"}
                          </Col>
                          <Col className="col-12 mb-3">
                            Created By :{" "}
                            {challenges.challenge_creator.creator_username}
                          </Col>
                          <Col className="col-12 mb-3">
                            Versus : {challenges.versus}
                          </Col>
                          <Col className="col-12 mb-3">
                            Enrolled Players : {challenges.enrolled_teams}
                          </Col>
                          <Col className="col-12 mb-3">
                            Create Date:{" "}
                            {new Date(challenges?.create_date).toLocaleString(
                              "en-US",
                              {
                                weekday: "short", // long, short, narrow
                                day: "numeric", // numeric, 2-digit
                                year: "numeric", // numeric, 2-digit
                                month: "long", // numeric, 2-digit, long, short, narrow
                                hour: "numeric", // numeric, 2-digit
                                minute: "numeric", // numeric, 2-digit
                                second: "numeric", // numeric, 2-digit
                                timeZone: "UTC",
                                hour12: false,
                              }
                            )}
                          </Col>
                          <Col className="col-12 mb-3">
                            Match Date : {challenges?.match_date}
                          </Col>
                          <Col className="col-12 mb-3">
                            Match Time : {challenges?.match_time}
                          </Col>
                          <Col className="col-12 mb-3">
                            Match End Time :{" "}
                            {challenges?.end_time === null
                              ? "NA"
                              : challenges?.end_time}
                          </Col>
                          <Col className="col-12 mb-3">
                            Entry Fee : ${" "}
                            {Number(challenges?.entry_fee)?.toFixed(2)}{" "}
                          </Col>
                          <Col className="col-12 mb-3">
                            Winning : ${" "}
                            {Number(challenges?.winning)?.toFixed(2)}{" "}
                          </Col>
                          <Col className="col-12 mb-3">
                            Competition Status :{" "}
                            {(challenges?.challenge_mode == 1 ||
                              challenges?.challenge_mode == 2) &&
                            challenges?.end_date === null
                              ? competitionStatus[2]
                              : competitionStatus[challenges?.challenge_mode]}
                          </Col>
                          {challenges.game == 2 ? (
                            <Col className="col-12 mb-3">
                              Map : {challenges.gamemap || "N/A"}
                            </Col>
                          ) : null}
                        </Row>
                        {getGameFromId(games, challenges.game)?.slug ==
                        "valorant" ? (
                          canPerform(
                            GAME_MAP_CHALLENGES[
                              getGameFromId(games, challenges.game)?.slug
                            ]?.permissionType,
                            GAME_MAP_CHALLENGES[
                              getGameFromId(games, challenges.game)?.slug
                            ]?.permissionDeleteType
                          ) ? (
                            <Button
                              type="button"
                              className="btn btn-danger"
                              // onClick={() => handleDelete(item.id)}
                              onClick={() => {
                                openAlert(challenges.id, challenges.versus);
                              }}
                              disabled={!challenges.challenge_delete_buffer}
                            >
                              Delete
                            </Button>
                          ) : null
                        ) : (
                          <ApexCodesChallenge
                            openAlert={openAlert}
                            challenges={challenges}
                            canPerform={canPerform}
                            setisLoading={setisLoading}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane
                    tabId="2"
                    className="p-3 enrolled-players-tab"
                    style={{
                      marginTop: "-1pc",
                      width: "154%",
                      marginLeft: "-15px",
                    }}
                  >
                    <EnrolledChallengeTeamList
                      playerList={challenges.challenge_players}
                    />
                  </TabPane>
                  <TabPane
                    tabId="3"
                    className="p-3 lobby-results-tab"
                    style={{
                      marginTop: "-1pc",
                      width: "154%",
                      marginLeft: "-15px",
                    }}
                  >
                    <LobbyResult
                      data={teams}
                      type="challenge"
                      gameData={challenges.game}
                    />
                  </TabPane>
                </TabContent>
              )
            )}
          </Col>
        </Row>
        {deleteModal ? (
          <DeleteAlert
            DeleteId={deleteId}
            Versus={vs}
            onClose={() => handleCLoseAlert()}
            callApi={() => goToBack()}
            Loader={() => setisLoading(true)}
            StopLoader={() => setisLoading(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default withRouter(ChallengesDetail);
