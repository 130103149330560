import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import {
  releaseWinningOfUser,
  seizeParticularWinningOfUser,
  userTransactionHistory,
  seizeWalletOfUser,
} from "../../services/user_api_helper";
import PaymentConstantTypes from "../../constants/paymentConstantTypes";
import SweetAlert from "react-bootstrap-sweetalert";
import Paginator from "../../helpers/paginator/paginator";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { getPaymentMethod } from "../../helpers/util";
const LatestTransaction = (props) => {
  const pageSize = 25;
  const [pageNumber, setpageNumber] = useState(1);
  const [totalCount, settotalCount] = useState(null);
  const [transactionId, setTransactionId] = useState("");
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [confirm_alert_For_Seize_Wallet, setConfirm_alert_For_Seize_Wallet] =
    useState(false);

  const [openSuccessMessage2, setOpenSuccessMessage2] = useState(false);
  const [successMessage2, setSuccessMessage2] = useState("");
  const [wallet_amount, setWalletAmount] = useState("");
  const [disableSwal, setDisabled] = useState(false);
  const [disableSwal2, setDisabled2] = useState(false);
  const [disableSwalOnSeize, setDisabledOnSeizeParticular] = useState(false);
  //seize particular winning
  const [open_modal_of_hold_status, Set_Open_Modal_Of_Hold_Status] =
    useState(false);
  // const [permission, setPermission] = useState([]);
  const [changePermission, setChange] = useState(false);
  useEffect(() => {
    setLoader(true);
    if (props.data.id !== undefined) {
      callTransaction();
    }
    if (props.permission === null || props.permission.length === 0) {
      setChange(true);
    } else {
      callSetPermission();
    }
  }, [pageNumber]);
  const callSetPermission = () => {
    const type = "Transaction Detail";
    const typeChange = "change_transactiondetail";
    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      setChange(setchange);
      //  setPermission(filteredPermission[0].permissions);
    }
  };
  const callTransaction = () => {
    userTransactionHistory(props?.data?.id, pageNumber, pageSize).then(
      (res) => {
        setLoader(false);
        setTransactionHistory(res.data);
        settotalCount(res.total);
        setWalletAmount(
          res.data.length === 0
            ? props.data.profile.wallet_balance
            : res?.data[0]?.current_wallet_balance
        );
      }
    );
  };

  //Seize wallet
  const openAlertForSeizeWallet = () => {
    setConfirm_alert_For_Seize_Wallet(true);
  };

  const closeSeizeWalletAlert = () => {
    setConfirm_alert_For_Seize_Wallet(false);
  };
  const seizeWalletOnSubmit = () => {
    // if (!this.state.deactivateReason || this.state.deactivateReason === "") {
    //   return;
    // }

    // let currentStatus = this.state.user.status === 1 ? 2 : 1;

    setDisabled2(true);
    seizeWalletOfUser(props.data.id)
      .then((res) => {
        setDisabled2(false);
        setOpenSuccessMessage2(true);
        setSuccessMessage2(res.success);
        setTimeout(() => {
          setConfirm_alert_For_Seize_Wallet(false);
          setOpenSuccessMessage2(false);
          window.location.reload();
        }, 3000);
      })
      .catch(() => {
        setDisabled2(false);
        setConfirm_alert_For_Seize_Wallet(false);
        setOpenSuccessMessage2(false);
      });
  };

  //seize particular winnings
  const openHoldStatusTransaction = (id) => {
    setTransactionId(id);
    Set_Open_Modal_Of_Hold_Status(true);
  };
  const close_modal_of_hold_status = () => {
    Set_Open_Modal_Of_Hold_Status(false);
  };
  const onRelease = () => {
    setDisabled(true);
    var userid = window.location.href;
    var userId = userid.substring(userid.lastIndexOf("/") + 1);
    releaseWinningOfUser(userId, transactionId)
      .then((res) => {
        setDisabled(false);
        setOpenSuccessMessage(true);
        setSuccessMessage("Amount released successfully");
        Set_Open_Modal_Of_Hold_Status(false);
        setTimeout(() => {
          setOpenSuccessMessage(false);
        }, 2000);
        callTransaction();
      })
      .catch(() => {
        setDisabled(false);
        Set_Open_Modal_Of_Hold_Status(false);
        setOpenSuccessMessage(false);
      });
  };
  const onSeizeParticularTransaction = () => {
    setDisabledOnSeizeParticular(true);
    var userid = window.location.href;
    var userId = userid.substring(userid.lastIndexOf("/") + 1);
    seizeParticularWinningOfUser(userId, transactionId)
      .then((res) => {
        setDisabledOnSeizeParticular(false);
        setOpenSuccessMessage(true);
        setSuccessMessage(res.success);
        Set_Open_Modal_Of_Hold_Status(false);
        setTimeout(() => {
          setOpenSuccessMessage(false);
        }, 2000);
        callTransaction();
      })
      .catch(() => {
        setDisabled(false);
        Set_Open_Modal_Of_Hold_Status(false);
      });
  };
  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  return (
    <Col lg={8}>
      <>
        {changePermission ? (
          <Button
            type="submit"
            color="warning"
            disabled={props.data.status === 2}
            onClick={() => {
              openAlertForSeizeWallet();
            }}
            className="btn btn-warning waves-effect waves-light seize-wallet-btn"
          >
            Seize Wallet
          </Button>
        ) : null}
        <Card>
          <CardBody>
            {/* <CardTitle className="h4 mb-4">Latest Transaction</CardTitle> */}
            <div className="table-responsive seize-wallet-table">
              <table className="table">
                <thead>
                  <tr>
                    <td scope="col">Sr.No</td>
                    <td scope="col">Date</td>
                    <td scope="col">Payee name</td>
                    <td scope="col">Receiver name</td>
                    <td scope="col">Payment type</td>
                    <td scope="col">Payment Method</td>
                    <td scope="col">Amount</td>
                    <td scope="col">Current wallet balance</td>
                    <td scope="col">Payment Status</td>
                    {changePermission ? (
                      <td scope="col">Seize winning amount</td>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <div class="spinner-grow transaction-spinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : transactionHistory.length === 0 ? (
                    <tr>
                      <td colspan="9">
                        <h5 className="text-center my-5">
                          No transaction history found
                        </h5>
                      </td>
                    </tr>
                  ) : (
                    transactionHistory &&
                    transactionHistory.map((item, index) => {
                      const paymentConst = PaymentConstantTypes.map(
                        (type) => type
                      );
                      const paymentConstFilter = paymentConst.filter(
                        (paymentType) => paymentType.value === item.type
                      );
                      return (
                        <tr key={index}>
                          <td>{pageSize * (pageNumber - 1) + index + 1}</td>
                          {/* <td>{new Date(item?.create_date).toLocaleString()}</td> */}
                          <td>{item.create_date}</td>
                          <td>{item.payee.name}</td>
                          <td>{item.receiver.name}</td>
                          <td>{paymentConstFilter[0].label}</td>
                          <td>{getPaymentMethod(item.payment_method)}</td>
                          <td> $ {item.amount}</td>
                          <td> $ {item.current_wallet_balance}</td>
                          <td>
                            {item.status === 3 ? (
                              <span className="badge badge-soft-danger font-size-12">
                                {" "}
                                {paymentConstFilter[0].label} (pending)
                              </span>
                            ) : item.status === 4 ? (
                              <span className="badge badge-soft-warning font-size-12">
                                ON HOLD
                              </span>
                            ) : item.status === 2 || item?.type == 12 ? (
                              <span className="badge badge-soft-danger font-size-12">
                                {" "}
                                {item.status === 2
                                  ? "FAILED"
                                  : "INACTIVITY CHARGES"}
                              </span>
                            ) : item?.transaction_mode === 1 ? (
                              <span className="badge badge-soft-success font-size-12">
                                PROMO BONUS
                              </span>
                            ) : (
                              <span className="badge badge-soft-success font-size-12">
                                {" "}
                                {paymentConstFilter[0].label}
                              </span>
                            )}
                          </td>
                          {changePermission ? (
                            <td>
                              {item.status === 4 ? (
                                <button
                                  onClick={() => {
                                    openHoldStatusTransaction(item.id);
                                  }}
                                  className="btn btn-primary btn-sm"
                                >
                                  Seize/Release Winning Amount{" "}
                                </button>
                              ) : (
                                "-"
                              )}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {openSuccessMessage ? (
              <SweetAlert success showConfirm={false}>
                {successMessage}
              </SweetAlert>
            ) : null}
            {confirm_alert_For_Seize_Wallet && wallet_amount !== "0.00" ? (
              <SweetAlert
                title="Seize wallet"
                warning
                showCancel
                confirmButtonText="Yes"
                confirmBtnBsStyle="success"
                cancelButtonText="No"
                cancelBtnBsStyle="danger"
                onConfirm={() => seizeWalletOnSubmit()}
                onCancel={() => closeSeizeWalletAlert()}
                disabled={disableSwal2}
              >
                Are you sure you want to seize the wallet of this user?
              </SweetAlert>
            ) : (
              confirm_alert_For_Seize_Wallet && (
                <SweetAlert
                  title="Seize wallet"
                  warning
                  showCancel
                  showConfirm={false}
                  cancelButtonText="No"
                  cancelBtnBsStyle="danger"
                  onCancel={() => closeSeizeWalletAlert()}
                >
                  Wallet balance is low $ {wallet_amount}
                </SweetAlert>
              )
            )}
            {openSuccessMessage2 ? (
              <SweetAlert
                title="Seize wallet success"
                success
                showConfirm={false}
              >
                {successMessage2}
              </SweetAlert>
            ) : null}
            {open_modal_of_hold_status ? (
              <SweetAlert
                title=" Seize/Release Winning Amount"
                warning
                showCancel
                showConfirm={false}
                cancelButtonText="No"
                cancelBtnBsStyle="danger"
                onCancel={() => close_modal_of_hold_status()}
                customButtons={
                  <React.Fragment>
                    <Button
                      type="submit"
                      color="primary"
                      className="ms-1"
                      onClick={onRelease}
                      disabled={disableSwal}
                    >
                      Release payment
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="ms-1"
                      onClick={onSeizeParticularTransaction}
                      disabled={disableSwalOnSeize}
                    >
                      Seize particular transaction
                    </Button>
                  </React.Fragment>
                }
              ></SweetAlert>
            ) : null}
            <Paginator
              totalCount={totalCount}
              pageSize={pageSize}
              pageClick={handlePageClick}
            />
          </CardBody>
        </Card>
      </>
    </Col>
  );
};

export default LatestTransaction;
