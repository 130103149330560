import { axiosApi, setToken } from "./index";
export async function getTermsAndCondition() {
  setToken();
  return await axiosApi
    .get(`pages/terms-and-conditions`)
    .then((response) => response);
}
export async function getPrivacyPolicy() {
  setToken();
  return await axiosApi
    .get(`pages/privacy-policy`)
    .then((response) => response);
}

export async function getCaliforniaPrivacyPolicy() {
  setToken();
  return await axiosApi
    .get(`pages/california-privacy`)
    .then((response) => response);
}

export async function addTermsCMS(model) {
  setToken();
  return await axiosApi.post(`pages/`, model).then((response) => response);
}
export async function editTermsCMS(model) {
  setToken();
  return await axiosApi
    .put(`pages/terms-and-conditions/`, model)
    .then((response) => response);
}
export async function addPrivacyCMS(model) {
  setToken();
  return await axiosApi.post(`pages/`, model).then((response) => response);
}

export async function addCaliforniaPrivacyCMS(model) {
  setToken();
  return await axiosApi
    .post(`pages/california-privacy`, model)
    .then((response) => response);
}

export async function editPrivacyCMS(model) {
  setToken();
  return await axiosApi
    .put(`pages/privacy-policy/`, model)
    .then((response) => response);
}

export async function editCaliforniaPrivacyCMS(model) {
  setToken();
  return await axiosApi
    .put(`pages/california-privacy/`, model)
    .then((response) => response);
}

export async function getQuestions({ filter = "" }) {
  setToken();
  return await axiosApi.get(`faq/?type=${filter}`).then((response) => response);
}

export async function postQuestion(model) {
  setToken();
  return await axiosApi.post(`faq/`, model).then((response) => response);
}

export async function editQuestion({ payload, questionId }) {
  setToken();
  return await axiosApi
    .put(`faq/${questionId}/`, payload)
    .then((response) => response);
}

export async function deleteQuestion({ questionId }) {
  setToken();
  return await axiosApi
    .delete(`faq/${questionId}`)
    .then((response) => response);
}

export async function deleteTopic({ topicSlug }) {
  setToken();
  return await axiosApi
    .delete(`faq/topics/${topicSlug}`)
    .then((response) => response);
}

export async function getTopics({ topicType }) {
  setToken();
  return axiosApi
    .get(`faq/topics/?type=${topicType}`)
    .then((response) => response);
}

export async function editTopic({ payload, topicSlug }) {
  setToken();
  return await axiosApi
    .put(`faq/topics/${topicSlug}/`, payload)
    .then((response) => response);
}
