import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { reportsTableConstant } from "../../../constants/reportsTableConstant";
import Paginator from "../../../helpers/paginator/PaginatorReport";
import FilterPermission from "../../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../../helpers/PermissionUtils";
import ReportNavigationLink from "../ReportNavigationLink";
import { dynamicTableValuesTransactions } from "./utils";
import { FreeText, getPaymentMethod } from "../../../helpers/util";
function TableBasedOnActiveTab({
  data,
  type,
  setDetailView,
  detailView,
  totalCount,
  handlePageClick,
  pageNum,
  transactionFilter,
  props,
  active,
  setDates,
}) {
  const tableList = reportsTableConstant.map((labels) => labels);
  const displayTable = tableList.filter((item) => item.type == type);
  const dynamicTableHeader = () => {
    let header = displayTable.map((group, id) => {
      return type == 1
        ? group[dynamicTableValuesTransactions[transactionFilter]]?.map(
            (dataTransaction, i) => {
              return <Th key={i}>{dataTransaction.label}</Th>;
            }
          )
        : group?.tableHead?.map((dataHead, i) => {
            return <Th key={i}>{dataHead.label}</Th>;
          });
    });
    return header;
  };
  useEffect(() => {
    if (props.permission !== null || props.permission.length !== 0) {
      callSetPermission();
    }
  }, [props.permission]);

  const [viewPermission, setView] = useState(false);
  const [codMobile, setPermissionForCODMobile] = useState({
    View: false,
  });
  const [fortnite, setPermissionForFortnite] = useState({
    View: false,
  });
  const [pubgmobile, setPermissionForpubgmobile] = useState({
    View: false,
  });
  const [pubgpc, setPermissionForpubgpc] = useState({
    View: false,
  });
  // const checkIsAuthorized = ({ type, typeView }) => {
  //const
  //   const filteredPermission = FilterPermission(props.permission, type);
  //   if (filteredPermission.length !== 0) {
  //     const setview = filterOutPermissionToShowHide(
  //       filteredPermission[0]?.permissions,
  //       typeView
  //     );
  //     console.log({ setview, filteredPermission });
  //     return setview;
  //   }
  //   return false;
  // };

  const callSetPermission = () => {
    if (type === "1") {
      const typeLabel = "Transaction Detail";
      const typeView = "view_transactiondetail";
      const filteredPermission = FilterPermission(props.permission, typeLabel);
      if (filteredPermission.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0]?.permissions,
          typeView
        );
        setView(setview);
      }
    }
    if (type === "2") {
      const typeLabel = "Users";
      const typeView = "view_user";
      const filteredPermission = FilterPermission(props.permission, typeLabel);
      if (filteredPermission.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0]?.permissions,
          typeView
        );
        setView(setview);
      }
    } else if (type === "3") {
      const typeLabel = "Apex";
      const typeView = "view_apex";

      const filteredPermission = FilterPermission(props.permission, typeLabel);
      if (filteredPermission.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        setView(setview);
      }
      const typecodmobile = "COD Mobile";
      const typeViewcodmobile = "view_codmobile";

      const filteredPermissioncodmobile = FilterPermission(
        props.permission,
        typecodmobile
      );
      if (filteredPermissioncodmobile.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermissioncodmobile[0].permissions,
          typeViewcodmobile
        );
        setPermissionForCODMobile({
          View: setview,
        });
      }

      const typeFortnite = "Fortnite";
      const typeViewFortnite = "view_fortnite";

      const filteredPermissionFortnite = FilterPermission(
        props.permission,
        typeFortnite
      );
      if (filteredPermissionFortnite.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermissionFortnite[0].permissions,
          typeViewFortnite
        );
        setPermissionForFortnite({
          View: setview,
        });
      }
      const typepubgmobile = "PUBG Mobile";
      const typeViewpubgmobile = "view_pubgmobile";

      const filteredPermissionpubgmobile = FilterPermission(
        props.permission,
        typepubgmobile
      );
      if (filteredPermissionpubgmobile.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermissionpubgmobile[0].permissions,
          typeViewpubgmobile
        );
        setPermissionForpubgmobile({
          View: setview,
        });
      }
      const typepubgpc = "PUBG PC";
      const typeViewpubgpc = "view_pubgpc";

      const filteredPermissionpubgpc = FilterPermission(
        props.permission,
        typepubgpc
      );
      if (filteredPermissionpubgpc.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermissionpubgpc[0].permissions,
          typeViewpubgpc
        );
        setPermissionForpubgpc({
          View: setview,
        });
      }
    } else if (type === "4") {
      const typeLabel = "Complaint";
      const typeView = "view_complaint";
      const filteredPermission = FilterPermission(props.permission, typeLabel);
      if (filteredPermission.length !== 0) {
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0]?.permissions,
          typeView
        );
        setView(setview);
      }
    }
  };
  const dynamicTableValue = (dataValue, item) => {
    if (isNaN(parseInt(item[dataValue.key])) && !item[dataValue.key])
      return "NA";
    else if (dataValue.key === "transaction_type") {
      return item[dataValue.key]?.toUpperCase();
    } else if (dataValue.type == "date") {
      const date = item[dataValue.key]?.slice(0, 10);
      const time = item[dataValue.key]?.slice(11);
      return (
        <>
          <p className="mb-1">{date}</p> <p className="mb-0">{time}</p>
        </>
      );
    } else if (type == 1 && dataValue.key == "player") {
      return viewPermission ? (
        <ReportNavigationLink
          url={"/user/" + item[dataValue.key]?.id}
          activeTab={type}
          activeFilter={active}
          transactionFilter={transactionFilter}
          detailView={detailView}
          showData={detailView}
          setDates={setDates}
        >
          {item[dataValue.key]?.email}
        </ReportNavigationLink>
      ) : (
        item[dataValue.key]?.email
      );
    } else if (type == 1 && dataValue.key == "lobby") {
      return viewPermission ||
        codMobile.View ||
        fortnite.View ||
        pubgmobile.View ||
        pubgpc.View ? (
        <ReportNavigationLink
          url={"/lobby/" + item[dataValue.key]?.id}
          activeTab={type}
          activeFilter={active}
          transactionFilter={transactionFilter}
          detailView={detailView}
          showData={detailView}
          setDates={setDates}
        >
          {item[dataValue.key]?.name}
        </ReportNavigationLink>
      ) : (
        item[dataValue.key]?.name
      );
    } else if (
      (type == 2 && dataValue.key == "email") ||
      (type == 1 && dataValue.key == "email")
    ) {
      return viewPermission ? (
        <ReportNavigationLink
          url={"/user/" + (type == 2 ? item?.id : item?.user_id)}
          activeTab={type}
          activeFilter={active}
          transactionFilter={transactionFilter}
          detailView={detailView}
          showData={detailView}
          setDates={setDates}
        >
          {item[dataValue.key]}
        </ReportNavigationLink>
      ) : (
        item[dataValue.key]
      );
    } else if (type == 3 && dataValue.key == "name") {
      return viewPermission ||
        codMobile.View ||
        fortnite.View ||
        pubgmobile.View ||
        pubgpc.View ? (
        <ReportNavigationLink
          url={"/lobby/" + item?.id}
          activeTab={type}
          activeFilter={active}
          transactionFilter={transactionFilter}
          detailView={detailView}
          showData={detailView}
          setDates={setDates}
          nameOfGame={item.name_of_the_game}
        >
          {item[dataValue.key]}
        </ReportNavigationLink>
      ) : (
        item[dataValue.key]
      );
    } else if (type == 4 && dataValue.key == "lobby_name") {
      return viewPermission ? (
        <ReportNavigationLink
          url={"/complaint/" + item?.id}
          activeTab={type}
          activeFilter={active}
          transactionFilter={transactionFilter}
          detailView={detailView}
          showData={detailView}
          setDates={setDates}
          userTab={"2"}
        >
          {item[dataValue.key]}
        </ReportNavigationLink>
      ) : (
        item[dataValue.key]
      );
    } else if (
      (dataValue.key == "entry_fee" && item.free_lobby) ||
      dataValue.key == "transaction_amount"
    ) {
      const [amount, ...freeLobbyText] = item[dataValue.key]?.split(" ");
      return FreeText(amount, freeLobbyText);
    } else if (dataValue.key == "payment_method") {
      return getPaymentMethod(item[dataValue.key]);
    }
    // else if (dataValue.key == "transaction_amount") {
    //   const [amount, ...freeLobbyText] = item[dataValue.key]?.split(" ");
    //   return FreeText(amount, freeLobbyText);
    // }
    else {
      return item[dataValue.key];
    }
  };

  const tableData = () => {
    return data?.results?.length === 0 ? (
      <tr>
        <td colspan="15" className="text-center mt-4">
          No Data Found
        </td>
      </tr>
    ) : (
      data?.results?.map((item, index) => {
        return (
          <Tr key={index}>
            {displayTable.map((group, id) => {
              return type == 1
                ? group[dynamicTableValuesTransactions[transactionFilter]]?.map(
                    (dataTransaction, i) => {
                      return (
                        <Td
                          key={i}
                          className={`${
                            item?.unclaimed_money < 0 &&
                            dataTransaction.key === "unclaimed_money"
                              ? "negative-unclaimed-money"
                              : ""
                          } ${
                            dataTransaction.type === "date" ? "date-table" : ""
                          } ${
                            dataTransaction.key == "last_enrollement_date"
                              ? "lastEnrollmentDate"
                              : ""
                          }`}
                        >
                          {dataTransaction?.units &&
                          !isNaN(item?.[dataTransaction.key])
                            ? dataTransaction?.units
                            : null}
                          {dynamicTableValue(dataTransaction, item)}
                        </Td>
                      );
                    }
                  )
                : group?.tableHead?.map((dataOtherTableHead, i) => {
                    return (
                      <Td
                        key={i}
                        className={`${
                          dataOtherTableHead.type === "date" ? "date-table" : ""
                        }`}
                      >
                        {dataOtherTableHead?.units &&
                        !isNaN(item?.[dataOtherTableHead.key])
                          ? dataOtherTableHead?.units
                          : null}
                        {dynamicTableValue(dataOtherTableHead, item)}
                      </Td>
                    );
                  });
            })}
          </Tr>
        );
      })
    );
  };
  return (
    <Row>
      <Col className="col-12">
        <div className="table-rep-plugin">
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <div className="d-flex justify-content-between">
              <h4>{displayTable[0]?.heading}</h4>
              {detailView ? null : (
                <span
                  className="reportsViewAll"
                  onClick={() => {
                    setDetailView(true);
                  }}
                >
                  View All
                </span>
              )}
            </div>
            <Table className="table table-striped table-bordered lobbies-table">
              <Thead>
                <Tr>{dynamicTableHeader()}</Tr>
              </Thead>
              <Tbody>{tableData()}</Tbody>
            </Table>

            {detailView ? (
              <div>
                {data?.results?.length > 0 ? (
                  <span className="reports-result-count">
                    {(pageNum[type] - 1) * 10 + data?.results?.length} of{" "}
                    {totalCount}
                  </span>
                ) : null}
                <Paginator
                  totalCount={totalCount}
                  pageSize={detailView ? 10 : 5}
                  pageClick={(num) => handlePageClick(num, type)}
                  forcePage={pageNum[type]}
                />
              </div>
            ) : data?.results?.length > 0 ? (
              <span className="reports-result-count">
                {data?.results?.length} of {totalCount}
              </span>
            ) : null}
          </div>
        </div>
      </Col>
    </Row>
  );
}
export default TableBasedOnActiveTab;
