import React, { useEffect, useState } from "react";
import { getComplaintList } from "../../services/complaint_user_api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Paginator from "../../helpers/paginator/paginator";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { permissionsStrings } from "../../helpers/StringConstant";

const ComplaintList = (props) => {
  const [complains, setcomplains] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(null);

  //filter keys
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);

  // const [permission, setPermission] = useState([]);
  const [viewPermission, setView] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  useEffect(() => {
    if (props.permission === null || props.permission.length === 0) {
      setView(true);
    } else {
      callSetPermission();
    }
    getList();
  }, [pageNumber]);
  const callSetPermission = () => {
    const type = permissionsStrings.complaintList;
    const typeView = permissionsStrings.typeComplaintListView;
    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setview = filterOutPermissionToShowHide(
        filteredPermission[0]?.permissions,
        typeView
      );
      setView(setview);
      //setPermission(filteredPermission[0].permissions);
    }

    const typeUser = permissionsStrings.userList;
    const typeUserView = permissionsStrings.typeUserListView;
    const filteredPermissionUser = FilterPermission(props.permission, typeUser);
    if (filteredPermissionUser.length != 0) {
      const setViewuser = filterOutPermissionToShowHide(
        filteredPermissionUser[0]?.permissions,
        typeUserView
      );
      setViewUser(setViewuser);
    }
  };
  const getList = async () => {
    setisLoading(true);
    await getComplaintList()
      .then((res) => {
        setcomplains(res);
        settotalCount(0);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs breadcrumbItem="Manage Complaints" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered manage-complaints-table"
                      >
                        <Thead>
                          <Tr>
                            <Th>Sr.no.</Th>
                            <Th>Lobby Name</Th>
                            <Th data-priority="1">Reported By</Th>
                            <Th data-priority="1">Accused username</Th>
                            <Th data-priority="1">Complaint date</Th>
                            <Th data-priority="1">Status</Th>

                            {viewPermission || viewUser ? (
                              <Th data-priority="6">Actions</Th>
                            ) : null}
                          </Tr>
                        </Thead>

                        <Tbody>
                          {isLoading ? (
                            <div
                              class="spinner-grow transaction-spinner"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : complains.length === 0 ? (
                            <h5 className="text-center mt-4">
                              No Complaint(s) Found
                            </h5>
                          ) : (
                            complains.map((item, index) => (
                              <Tr key={index}>
                                <Td>{index + 1}</Td>
                                <Td>{item?.lobby.name}</Td>
                                <Td>{item?.reporter.username}</Td>
                                <Td>{item?.offender.username}</Td>
                                <Td>
                                  {new Date(item?.create_date).toLocaleString(
                                    "en-US",
                                    {
                                      weekday: "short", // long, short, narrow
                                      day: "numeric", // numeric, 2-digit
                                      year: "numeric", // numeric, 2-digit
                                      month: "long", // numeric, 2-digit, long, short, narrow
                                      hour: "numeric", // numeric, 2-digit
                                      minute: "numeric", // numeric, 2-digit
                                      second: "numeric", // numeric, 2-digit
                                      timeZone: "UTC",
                                      hour12: false,
                                    }
                                  )}
                                </Td>

                                <Td>
                                  {item?.status === 1 ? "Pending" : "Resolved"}
                                </Td>

                                {viewPermission ? (
                                  <Td>
                                    <Link
                                      className="btn btn-secondary btn-sm complaint-view-btn"
                                      role="button"
                                      to={`/complaint/${item.id}`}
                                    >
                                      View
                                    </Link>
                                  </Td>
                                ) : null}

                                {viewUser ? (
                                  <Td className="view-deactivate-btns">
                                    <Link
                                      className="btn btn-secondary btn-sm complaint-view-btn"
                                      role="button"
                                      to={{
                                        pathname: "/user/" + item?.offender.id,
                                        data: window.location.pathname,
                                      }}
                                    >
                                      Go to Accused Account
                                    </Link>
                                  </Td>
                                ) : null}
                              </Tr>
                            ))
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>

                  <Paginator
                    totalCount={totalCount}
                    pageSize={pageSize}
                    pageClick={handlePageClick}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </>
  );
};

export default ComplaintList;
