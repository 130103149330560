import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getGameList } from "../../services/game_api_helper";
import { Link } from "react-router-dom";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { GameTypeMap } from "../LobbyForm/data";

const GamesList = (props) => {
  const [games, setGames] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [permission, setPermission] = useState([]);
  const [changePermission, setChange] = useState(false);
  // const [deletePermission, setDelete] = useState(false);
  // const [addPermission, setAdd] = useState(false);
  // const [viewPermission, setView] = useState(false);
  useEffect(() => {
    setLoader(true);
    getGameList().then((res) => {
      setGames(res);
      setLoader(false);
    });
    if (props.permission === null || props.permission.length === 0) {
      setChange(true);
      // setDelete(true);
      // setAdd(true);
      // setView(true);
    } else {
      callSetPermission();
    }
  }, []);
  const callSetPermission = () => {
    const type = "Game";
    const typeChange = "change_game";
    // const typeDelete = "delete_game";
    // const typeAdd = "add_game";
    // const typeView = "view_game";
    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      // const setdelete = filterOutPermissionToShowHide(
      //   filteredPermission[0].permissions,
      //   typeDelete
      // );
      // const setadd = filterOutPermissionToShowHide(
      //   filteredPermission[0].permissions,
      //   typeAdd
      // );

      // const setview = filterOutPermissionToShowHide(
      //   filteredPermission[0].permissions,
      //   typeView
      // );
      setChange(setchange);
      // setDelete(setdelete);
      // setAdd(setadd);
      // setView(setview);
      //setPermission(filteredPermission[0].permissions);
    }
  };
  // const handleSearch = (event) => {
  //   let term = event.target.value.trim();
  //   getGameList(term).then((res) => {
  //     setGames(res);
  //   });
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Games" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <Row className="mb-3">
                <Col className="col-4">
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search here"
                      onChange={(event) => handleSearch(event)}
                    />
                  </div>
                </Col>
              </Row> */}

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered games-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Game Image</Th>
                          <Th data-priority="3">Game Name</Th>
                          <Th data-priority="1">Game Type</Th>
                          {changePermission ? (
                            <Th data-priority="1">Action</Th>
                          ) : null}
                        </Tr>
                      </Thead>
                      {games && (
                        <Tbody>
                          {loader ? (
                            <div
                              class="spinner-grow spinner-class"
                              role="status"
                              style={{ marginTop: "40px" }}
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : (
                            games.map((item, index) => (
                              <Tr key={index}>
                                <Td>
                                  <a
                                    href={item.background_image}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      height="100"
                                      src={item.background_image}
                                      alt="banner"
                                    />
                                  </a>
                                </Td>
                                <Td>{item.name}</Td>
                                <Td>
                                  {item.slug === "call-of-duty-warzone"
                                    ? "Kill Race"
                                    : item.game_type}
                                </Td>
                                {changePermission ? (
                                  <Td>
                                    <Link
                                      to={{
                                        pathname: `/game/edit/${item?.slug}/${item?.id}`,
                                        data: {
                                          rules: item?.game_rules,
                                          gameImage: item?.background_image,
                                          helperImage: item?.helper_image,
                                        },
                                      }}
                                    >
                                      Edit {"  "}
                                    </Link>
                                  </Td>
                                ) : null}
                              </Tr>
                            ))
                          )}
                        </Tbody>
                      )}
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default GamesList;
