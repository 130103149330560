import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import CMSRow from "./CMSRow";

function PPKDranNDrop({
  questions = [],
  enableEditView = () => {},
  deleteQuestion = () => {},
  droppableId = "droppable",
  isFaq = false,
  subQuestionid = "",
  questionNum = "",
  data,
}) {
  return (
    <Droppable droppableId={`droppableId${subQuestionid}`} type={questionNum}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {questions?.length
            ? questions?.map((question, index) => {
                return (
                  <Draggable
                    key={`${questionNum}${question.id}`}
                    draggableId={`${questionNum + "-"}${question.id}`}
                    index={index}
                    isDragDisabled={!data?.change}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CMSRow
                          key={question.id}
                          questionData={question}
                          enableEditView={enableEditView}
                          deleteCallback={deleteQuestion}
                          isFaq={isFaq}
                          data={data}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })
            : null}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default PPKDranNDrop;
