export const passwordCharacterData = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "!",
  "#",
  "$",
  "%",
  "&",
  "'",
  "(",
  ")",
  "*",
  "+",
  ",",
  "-",
  ".",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "@",
  "[",
  "]",
  "^",
  "_",
  "`",
  "{",
  "|",
  "}",
  "~",
];

export const randomPasswordGenerator = ({ passWordLength, data }) => {
  let password = "";
  const array = new Uint32Array(data.length);
  window.crypto.getRandomValues(array);

  for (let i = 0; i < passWordLength; i++) {
    password += data[array[i] % data.length];
  }
  return password;
};

export const getPaymentMethod = (payment_method) => {
  return payment_method == "Stripe Instant" || payment_method == "Stripe"
    ? payment_method == "Stripe Instant"
      ? "Instant"
      : "Standard"
    : payment_method;
};
export const convertDateToHyphenFormat = (fieldDate) => {
  if (fieldDate === null) {
    return;
  } else {
    var formatToHyphenDate = new Date(fieldDate);
    var month = "" + (formatToHyphenDate?.getMonth() + 1);
    var day = "" + formatToHyphenDate?.getDate();
    var year = formatToHyphenDate?.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let joinDate = [year, month, day].join("-");
    return joinDate;
  }
};
export const gameStatsTableHeadForApex = [
  "Team name",
  "Player name",
  "Kills",
  "Winnings",
  "Team placement",
  "Survival time",
  "Damage dealt",
  "Platform",
];
export const gameStatsTableHeadForValorant = [
  "Team name",
  "Player name",
  "Kills",
  "Winnings",
  "Assists",
  "Damage dealt",
  "Survival time",
  "Platform",
];
export const FreeText = (freeAmountText, freeLobbyText) => {
  return (
    <>
      <p className="mb-1">{"$" + freeAmountText}</p>{" "}
      <p className="mb-0 freeEntry">{freeLobbyText?.join(" ")}</p>
    </>
  );
};
export const gameTypeEnumForChallenges = {
  1: "PPK",
  2: "Kill Race",
  3: "Traditional",
};
export const competitionStatus = {
  1: "Upcoming",
  2: "Active",
  3: "Ended",
  4: "Cancelled",
  5: "Completed",
};

export const compareDatesDDMMYYYY = (date1, date2) => {
  // Split each date into an array of [month, day, year]
  const date1Array = date1.split("-");
  const date2Array = date2.split("-");

  // Convert each element to an integer
  const date1Int = date1Array.map(Number);
  const date2Int = date2Array.map(Number);

  // Compare the year, month, and day in that order
  if (date1Int[2] > date2Int[2]) {
    return 1;
  } else if (date1Int[2] < date2Int[2]) {
    return -1;
  } else {
    if (date1Int[0] > date2Int[0]) {
      return 1;
    } else if (date1Int[0] < date2Int[0]) {
      return -1;
    } else {
      if (date1Int[1] > date2Int[1]) {
        return 1;
      } else if (date1Int[1] < date2Int[1]) {
        return -1;
      } else {
        return 0; // The dates are equal
      }
    }
  }
};
export const dropdownOptions = [
  {
    value: "username",
    text: "Username : Asc",
  },
  {
    value: "-username",
    text: "Username : Desc",
  },

  {
    value: "email",
    text: "Email : Asc",
  },
  {
    value: "-email",
    text: "Email : Desc",
  },
  {
    value: "create_date",
    text: "Date & Time : Asc",
  },
  {
    value: "-create_date",
    text: "Date & Time : Desc",
  },
];
export const DropDownOptionsChallenges = [
  {
    value: "match_date",
    text: "Match Date : Asc",
  },
  {
    value: "-match_date",
    text: "Match Date : Desc",
  },
  // {
  //   value: "entry_fee",
  //   text: "Entry fee : Asc",
  // },
  // {
  //   value: "-entry_fee",
  //   text: "Entry fee : Desc",
  // },
  {
    value: "1",
    text: "Challenge type : Team",
  },
  {
    value: "2",
    text: "Challenge type : Open",
  },
];
export const dropdownOptionsForChallengeMode = [
  {
    value: "1",
    text: "Open",
  },
  {
    value: "2",
    text: "Team",
  },
];

export const challengesStatusOption = [
  {
    value: "1",
    text: "Active",
  },
  {
    value: "2",
    text: "Cancelled",
  },
  {
    value: "3",
    text: "Completed",
  },
  {
    value: "4",
    text: "Ended",
  },
];
export const dropdownOptionsForReferral = [
  {
    value: "",
    text: "All",
  },
  {
    value: "total_earn",
    text: "Total Earn : Asc",
  },
  {
    value: "-total_earn",
    text: "Total Earn : Desc",
  },
  {
    value: "referee__create_date",
    text: "Joining Date : Asc",
  },
  {
    value: "-referee__create_date",
    text: "Joining Date : Desc",
  },
  {
    value: "referee__username",
    text: "Username : Asc",
  },
  {
    value: "-referee__username",
    text: "Username : Desc",
  },
  {
    value: "referee__email",
    text: "Email : Asc",
  },
  {
    value: "-referee__email",
    text: "Email : Desc",
  },
];

export const gamesToCategories = (games) => {
  let traditional = [];
  let ppk = [];
  let killRace = [];
  games.forEach((data) => {
    data.types_of_game.forEach((gameType) => {
      switch (gameType) {
        case "1":
          ppk.push({ ...data, value: data.id, label: data.name });
          break;
        case "2":
          killRace.push({ ...data, value: data.id, label: data.name });
          break;
        case "3":
          traditional.push({ ...data, value: data.id, label: data.name });
          break;
        default:
          break;
      }
    });
  });
  return {
    ppkGames: ppk,
    traditionalGames: traditional,
    killRaceGames: killRace,
    allGames: games.map((game) => ({
      ...game,
      value: game.id,
      label: game.name,
    })),
    modeObj: {
      1: ppk,
      2: killRace,
      3: traditional.filter((game) => game.slug != "apex-legends"),
    },
    modeGameTypeObj: getModesOfGame([...ppk, ...traditional, ...killRace]),
  };
};

export const getModesOfGame = (games) => {
  const temp = {};
  games.forEach((game) => {
    temp[game.slug] = game.game_allowed_mode?.map((mode) => ({
      ...mode,
      value: mode.mode,
      label: mode.mode_type,
    }));
  });
  temp["apex-legends"] = temp["apex-legends"].filter((mode) => mode.mode != 6);
  return temp;
};

export const getModeLabel = (type, mode) => {
  return type == 1
    ? lobbyModeFilters[mode]?.label
    : challengeModeFilters[mode]?.label;
};

export const getModeLabelsSelect = (type, modeArr, text = "") => {
  if (!modeArr?.length) return [];
  const ans = modeArr?.map((mode) => ({
    ...mode,
    label: getModeLabel(type.value, mode.mode),
  }));
  return ans;
};

export const lobbyModeFilters = {
  1: { value: 1, label: "Solos" },
  2: { value: 2, label: "Duos" },
  3: { value: 3, label: "Trios" },
  4: { value: 4, label: "Quads" },
  5: { value: 5, label: "Penta" },
  6: { value: 6, label: "Hexa" },
};
export const challengeModeFilters = {
  1: { value: 1, label: "1v1" },
  2: { value: 2, label: "2v2" },
  3: { value: 3, label: "3v3" },
  4: { value: 4, label: "4v4" },
  5: { value: 5, label: "5v5" },
  6: { value: 6, label: "6v6" },
};

export const gameTypeFilters = [
  { value: 1, label: "PPK" },
  { value: 2, label: "Kill Race" },
  { value: 3, label: "Traditional" },
];

export const getGameFromId = (games = [], gameId) => {
  const res = games?.filter(({ id }) => gameId == id)?.[0];
  return res;
};
export const GAME_MAP_CHALLENGES = {
  "apex-legends": {
    label: "Apex Legends",
    permissionType: "Apex",
    permissionDeleteType: "delete_apex",
    permissionChangeType: "change_apex",
  },
  valorant: {
    label: "Valorant",
    permissionType: "Valorant",
    permissionDeleteType: "delete_valorant",
    permissionChangeType: "change_valorant",
  },
};

export const canEditLobbyField = ({
  editMode,
  is_manual,
  current_status,
  editIfActive,
}) => {
  return !(
    !editMode ||
    current_status == 1 ||
    (current_status == 2 && editIfActive && is_manual)
  );
};

export const canFetchResults = (item) =>
  item?.is_manual && item.stats_status == 1 ;
