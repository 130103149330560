import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  permission,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }
      if (!isAuthProtected && localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{
              pathname: path,
              state: {
                from: props.location,
                //  permission: permission,
              },
            }}
          />
        );
      }
      const obj = JSON.parse(localStorage.getItem("authUser"));
      return (
        <Layout>
          <Component
            {...props}
            permission={
              permission === null
                ? obj === null
                  ? null
                  : obj.extras?.permissions.length === 0
                  ? null
                  : obj.extras?.permissions
                : permission
            }
          />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  permission: PropTypes.any,
  layout: PropTypes.any,
};

export default Authmiddleware;
