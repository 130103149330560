import { axiosApi, setToken } from "./index";
export async function getCommunityList(filterModel) {
  let params = new URLSearchParams();
  setToken();
  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);
  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);
  return await axiosApi.get("community/", { params }).then((response) => {
    return { data: response.results, total: response.total };
  });
}
export async function getCommunityDetail(id) {
  setToken();
  return await axiosApi.get(`community/${id}/`).then((response) => response);
}
export async function addCommunity(model) {
  setToken();
  return await axiosApi.post(`community/`, model);
}
export async function deleteCommunity(id) {
  setToken();
  return await axiosApi.delete(`community/${id}`);
}
export async function editCommunity(id, model) {
  setToken();
  return await axiosApi.put(`community/${id}/`, model);
}
export async function getLobbyList() {
  setToken();
  return await axiosApi.get("all-lobbies/").then((response) => {
    return { data: response };
  });
}

export async function getUserNameList() {
  setToken();
  return await axiosApi.get("all-username/?no_page").then((response) => {
    return { data: response };
  });
}
