import React from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import switch
import Switch from "react-switch";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addCommunity,
  getCommunityDetail,
  editCommunity,
  getLobbyList,
  getUserNameList,
} from "../../services/community_api_helper";
import { Link, withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { Multiselect } from "multiselect-react-dropdown";
import ReactPlayer from "react-player";
import whiteCrossIcon from "../../assets/images/white-cross-icon.svg";
import { ERROR_MESSAGES, MESSAGES } from "../../helpers/StringConstant";
// import "./responsive-player.css";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};
const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};
class AddEditCommunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      editMode: false,
      lobbyId: 0,
      optionGroup: [],
      optionGroupUser: [],
      errorMsg: null,
      game: [],
      username: [],
      admins: null,
      youtube_link: "",
      twitch_link: "",
      twitter_link: "",
      description: "",
      youtube: "",
      filterId: null,
      filterIdUser: null,
      errorMessage: null,
      errorMessageUser: null,
      lobby_id_Test: "",
      removeLobby: false,

      status: false,
      user_id_Test: "",
      removeUser: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  currentDate = new Date();

  componentDidMount() {
    let lobbyId = this.props?.match?.params?.lobbyId;
    this.lobbyList();
    this.userNameList();
    if (lobbyId) {
      this.setState({
        editMode: true,
        lobbyId: lobbyId,
      });
      this.lobbyDetail(lobbyId);
    }
  }

  async lobbyDetail(lobbyId) {
    this.showLoader(true);
    await getCommunityDetail(lobbyId)
      .then((res) => {
        this.mapDetailsToForm(res);
        this.showLoader(false);
        this.setState({
          filterId: res.lobby,

          filterIdUser: res.username,
        });
      })
      .catch((err) => {
        this.showLoader(false);
      });
  }

  lobbyList() {
    //const dummySelect = [{ label: "Select lobby", value: 0 }];
    getLobbyList().then((res) => {
      let data = res?.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      this.setState({
        // optionGroup: [...dummySelect, ...data],
        optionGroup: data,
      });
    });
  }
  userNameList() {
    getUserNameList().then((res) => {
      let data = res.data.map((item) => ({
        label: item.username,
        value: item.id,
      }));
      let data1 = data.filter(function (item) {
        return item.label !== null;
      });
      this.setState({
        // optionGroup: [...dummySelect, ...data],
        optionGroupUser: data1,
      });
    });
  }
  mapDetailsToForm(res) {
    let data1 = this.state.optionGroup.filter(function (item) {
      return item.value === res.lobby;
    });
    let data2 = this.state.optionGroupUser.filter(function (item) {
      return item.label === res.username;
    });
    this.setState({
      youtube_link: res.youtube_link,
      twitch_link: res.twitch,
      twitter_link: res.twitter,
      description: res.description,
      lobby_id_Test: data1,
      user_id_Test: data2,
      status: res.pinned,
      youtube: res.youtube,
    });
  }

  async handleValidSubmit(event, values) {
    event.preventDefault();
    this.setState({ errorMsg: null });
    if (this.state.editMode) {
      if (this.state.removeLobby) {
        if (this.state.removeLobby && this.state.removeUser) {
          if (
            this.state.game.length === 0 &&
            this.state.username.length === 0
          ) {
            this.setState({
              errorMessage: ERROR_MESSAGES.lobbyRequired,
              errorMessageUser: ERROR_MESSAGES.usernameRequired,
            });
          }
        }
        if (this.state.game.length === 0) {
          this.setState({ errorMessage: ERROR_MESSAGES.lobbyRequired });
        } else {
          this.showLoader(true);
          let model = {
            youtube_link: this.state.youtube_link,
            description: this.state.description,
            lobby_id: this.state.game,
            username: this.state.username,
            twitch: this.state.twitch_link,
            twitter: this.state.twitter_link,
            pinned: this.state.status,
            youtube: this.state.youtube,
          };
          await editCommunity(this.state.lobbyId, model).then(
            (_) => {
              this.showLoader(false);
              toast.success(
                `${MESSAGES.communityM} ${
                  this.state.editMode
                    ? MESSAGES.communityU
                    : MESSAGES.communityA
                }${MESSAGES.communityS}`,
                toastrOptions
              );
              this.goToListing();
            },
            (err) => {
              this.showLoader(false);
              this.setState({ errorMsg: err });
            }
          );
        }
      } else if (this.state.removeUser) {
        if (this.state.removeLobby && this.state.removeUser) {
          if (
            this.state.game.length === 0 &&
            this.state.username.length === 0
          ) {
            this.setState({
              errorMessage: ERROR_MESSAGES.lobbyRequired,
              errorMessageUser: ERROR_MESSAGES.usernameRequired,
            });
          }
        }
        if (this.state.username.length === 0) {
          this.setState({ errorMessageUser: ERROR_MESSAGES.usernameRequired });
        } else {
          this.showLoader(true);
          let model = {
            youtube_link: this.state.youtube_link,
            description: this.state.description,
            lobby_id:
              this.state.game.length !== 0
                ? this.state.game
                : this.state.lobby_id_Test[0].value,

            username: this.state.username,
            twitch: this.state.twitch_link,
            twitter: this.state.twitter_link,
            pinned: this.state.status,
            youtube: this.state.youtube,
          };
          await editCommunity(this.state.lobbyId, model).then(
            (_) => {
              this.showLoader(false);
              toast.success(
                `${MESSAGES.communityM} ${
                  this.state.editMode
                    ? MESSAGES.communityU
                    : MESSAGES.communityA
                }${MESSAGES.communityS}`,
                toastrOptions
              );
              this.goToListing();
            },
            (err) => {
              this.showLoader(false);
              this.setState({ errorMsg: err });
            }
          );
        }
      } else {
        this.showLoader(true);
        let model = {
          youtube_link: this.state.youtube_link,
          description: this.state.description,
          lobby_id:
            this.state.game.length !== 0
              ? this.state.game
              : this.state.lobby_id_Test[0].value,

          username:
            this.state.username.length !== 0
              ? this.state.username
              : this.state.user_id_Test[0]?.label,
          twitch: this.state.twitch_link,
          twitter: this.state.twitter_link,
          pinned: this.state.status,
          youtube: this.state.youtube,
        };
        await editCommunity(this.state.lobbyId, model).then(
          (_) => {
            this.showLoader(false);
            toast.success(
              `${MESSAGES.communityM} ${
                this.state.editMode ? MESSAGES.communityU : MESSAGES.communityA
              }${MESSAGES.communityS}`,
              toastrOptions
            );
            this.goToListing();
          },
          (err) => {
            this.showLoader(false);
            this.setState({ errorMsg: err });
          }
        );
      }
    } else {
      if (this.state.game.length === 0 && this.state.username.length === 0) {
        this.setState({
          errorMessage: ERROR_MESSAGES.lobbyRequired,
          errorMessageUser: ERROR_MESSAGES.usernameRequired,
        });
      } else if (this.state.game.length === 0) {
        this.setState({ errorMessage: ERROR_MESSAGES.lobbyRequired });
      } else if (this.state.username.length === 0) {
        this.setState({ errorMessageUser: ERROR_MESSAGES.usernameRequired });
      } else {
        this.showLoader(true);
        let model = {
          youtube_link: this.state.youtube_link,
          description: this.state.description,
          lobby_id: this.state.game,
          username: this.state.username,
          twitch: this.state.twitch_link,
          twitter: this.state.twitter_link,
          pinned: this.state.status,
          youtube: this.state.youtube,
        };
        await addCommunity(model).then(
          (_) => {
            this.showLoader(false);
            toast.success(
              `${MESSAGES.communityM} ${
                this.state.editMode ? MESSAGES.communityU : MESSAGES.communityA
              }${MESSAGES.communityS}`,
              toastrOptions
            );
            this.goToListing();
          },
          (err) => {
            this.showLoader(false);
            toast.error(err, toastrOptions);
            this.setState({ errorMsg: err });
          }
        );
      }
    }
  }

  goToListing() {
    this.props.history.push("/community");
  }

  showLoader(isLoad) {
    this.setState({
      isLoading: isLoad,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  checkSingleSelectLobby = (selectedList, selectedItem) => {
    this.setState({
      game: selectedItem.value,
      errorMessage: null,
      removeLobby: false,
    });
  };

  checkSingleSelectUsername = (selectedList, selectedItem) => {
    this.setState({
      username: selectedItem.label,
      errorMessageUser: null,
      removeUser: true,
    });
  };
  getMultiStateNames = (options, values) => {
    let data1 = options.filter(function (item) {
      return item.value === values;
    });
    return data1;
  };
  getMultiUserNames = (options, values) => {
    let data1 = options.filter(function (item) {
      return item.label === values;
    });
    return data1;
  };
  setDefaultItem = (options, values) => {
    let data1 = options.filter(function (item) {
      return item.value === values;
    });
    return data1;
  };
  onRemoveLobby = (selectedList, removedItem) => {
    this.setState({ lobby_id_Test: "", removeLobby: true, game: [] });
    // this.setState({
    //   addMoreState: Array.isArray(selectedList)
    //     ? selectedList.map((x) => x._id)
    //     : [],
    // });
  };
  onRemoveUser = (selectedList, removedItem) => {
    this.setState({ user_id_Test: "", removeUser: true, username: [] });
    // this.setState({
    //   addMoreState: Array.isArray(selectedList)
    //     ? selectedList.map((x) => x._id)
    //     : [],
    // });
  };
  render() {
    return (
      <React.Fragment>
        <Loader showLoader={this.state.isLoading} />
        <div className="page-content">
          <Breadcrumbs
            breadcrumbItem={
              (this.state.editMode ? "Edit" : "Add") + " Community"
            }
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <p>
                        <Link to="/community">
                          <i className="mdi mdi-arrow-left"></i> back
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Row className="add-lobby-row">
                    <Col className="col-lg-8 col-md-8 col-sm-12">
                      <AvForm
                        onValidSubmit={(e, v) => {
                          this.handleValidSubmit(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="youtube_link"
                            label="Youtube Link"
                            placeholder="Enter Youtube Link Here"
                            type="text"
                            value={this.state.youtube_link}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Youtube Link is required",
                              },
                              pattern: {
                                value:
                                  "^(https?://)?(www.youtube.com|youtu.be)/.+$",
                                errorMessage: "Invalid Youtube Link",
                              },
                            }}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="youtube"
                            label="Youtube User Profile Link"
                            placeholder="Enter Youtube User Profile Link Here"
                            type="text"
                            value={this.state.youtube}
                            validate={{
                              // required: {
                              //   value: true,
                              //   errorMessage:
                              //     "Youtube User Profile Link is required",
                              // },
                              pattern: {
                                value:
                                  "((http|https)://|)(www.|)youtube.com/(channel/|user/)[a-zA-Z0-9_-]{1,}",
                                errorMessage:
                                  "Invalid Youtube User Profile Link",
                              },
                            }}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="twitter_link"
                            label="Twitter User Profile Link"
                            placeholder="Enter Twitter User Profile Link Here"
                            type="text"
                            value={this.state.twitter_link}
                            validate={{
                              // required: {
                              //   value: true,
                              //   errorMessage:
                              //     "Twitter User Profile Link is required",
                              // },
                              pattern: {
                                value:
                                  "/http(?:s)?://(?:www.)?twitter.com/([a-zA-Z0-9_]+)/",
                                errorMessage:
                                  "Invalid Twitter User Profile Link",
                              },
                            }}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="twitch_link"
                            label="Twitch Link"
                            placeholder="Enter Twitch User Profile Link Here"
                            type="text"
                            value={this.state.twitch_link}
                            validate={
                              {
                                // required: {
                                //   value: true,
                                //   errorMessage:
                                //     "Twitch User Profile Link is required",
                                // },
                                // pattern: {
                                //   value:
                                //     "^(https?://)?(www.youtube.com|youtu.be)/.+$",
                                //   errorMessage: "Invalid Youtube Link",
                                // },
                              }
                            }
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="description"
                            label="Description"
                            placeholder="Enter Description Here"
                            type="text"
                            value={this.state.description}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Description is required",
                              },
                            }}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <Label
                            className={`select-lobby-label ${
                              this.state.errorMessage !== null
                                ? "lobby-error-message"
                                : ""
                            }`}
                          >
                            Select Lobby
                          </Label>
                          {this.state.editMode ? (
                            <Multiselect
                              options={this.state?.optionGroup}
                              displayValue="label"
                              onSelect={this.checkSingleSelectLobby}
                              onRemove={this.onRemoveLobby}
                              selectedValues={this.getMultiStateNames(
                                this.state?.optionGroup,
                                this.state.filterId
                              )}
                              selectionLimit={1}
                              customCloseIcon={
                                <img
                                  alt="white-cross-icon"
                                  className="whiteCrossIcon"
                                  src={whiteCrossIcon}
                                />
                              }
                            />
                          ) : (
                            <Multiselect
                              options={this.state?.optionGroup}
                              displayValue="label"
                              onSelect={this.checkSingleSelectLobby}
                              onRemove={this.onRemoveLobby}
                              selectionLimit={1}
                              customCloseIcon={
                                <img
                                  alt="white-cross-icon"
                                  className="whiteCrossIcon"
                                  src={whiteCrossIcon}
                                />
                              }
                            />
                          )}
                          <h6 class="lobby-error lobby-error-message">
                            {this.state.errorMessage !== null
                              ? this.state.errorMessage
                              : null}
                          </h6>
                        </div>
                        <div className="mb-3">
                          <Label
                            className={`select-user-label ${
                              this.state.errorMessageUser !== null
                                ? "user-error-message"
                                : ""
                            }`}
                          >
                            Select Username
                          </Label>
                          {this.state.editMode ? (
                            <Multiselect
                              options={this.state?.optionGroupUser}
                              displayValue="label"
                              onSelect={this.checkSingleSelectUsername}
                              onRemove={this.onRemoveUser}
                              selectedValues={this.getMultiUserNames(
                                this.state?.optionGroupUser,
                                this.state.filterIdUser
                              )}
                              selectionLimit={1}
                              customCloseIcon={
                                <img
                                  alt="white-cross-icon"
                                  className="whiteCrossIcon"
                                  src={whiteCrossIcon}
                                />
                              }
                            />
                          ) : (
                            <Multiselect
                              options={this.state?.optionGroupUser}
                              displayValue="label"
                              onSelect={this.checkSingleSelectUsername}
                              onRemove={this.onRemoveUser}
                              selectionLimit={1}
                              customCloseIcon={
                                <img
                                  alt="white-cross-icon"
                                  className="whiteCrossIcon"
                                  src={whiteCrossIcon}
                                />
                              }
                            />
                          )}
                          <h6 class="user-error user-error-message">
                            {this.state.errorMessageUser !== null
                              ? this.state.errorMessageUser
                              : null}
                          </h6>
                        </div>
                        <div className="mt-3">
                          <Row className="align-items-center verified-check-row">
                            <Col className="col-lg-3 col-sm-3">
                              <p className="verified-check">Pinned</p>
                            </Col>
                            <Col className="col-lg-3 col-sm-3">
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#626ed4"
                                className="form-label"
                                onChange={() => {
                                  this.setState({
                                    status: !this.state.status,
                                  });
                                }}
                                checked={this.state.status}
                              />
                            </Col>
                          </Row>
                        </div>
                        <FormGroup className="mt-4">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className=""
                              disabled={this.state.isLoading}
                            >
                              Submit
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </Col>
                  </Row>
                  <div className="player-wrapper">
                    <ReactPlayer
                      url={this.state.youtube_link}
                      className="video-player react-player"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddEditCommunity);
