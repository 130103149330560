import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link, withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import {
  addeditAdminUser,
  getGroupLists,
  getStaffDetailByID,
  getGroupDetailByID,
} from "../../services/admin_user_api_helper";
import { getCountries } from "../../services/config_api_helper";
import FindAllowedPermission from "../../helpers/FindAllowedPermission";
import FindPermission from "../../helpers/Find_Permission";

class AdminUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      editMode: false,
      adminId: null,
      userId: 0,
      errorMsg: null,
      countries: null,
      firstName: null,
      lastName: null,
      email: null,
      username: null,
      phoneNumber: null,
      street: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
      selectLevel: null,
      options: [],
      assignedPermissionLists: [],
      editAllPermissions: [],
      listing: [],
      givenPermission: [],
      allowedPermission: [],
      showPermission: false,
      defaultViewPermissionIds: [],
      Owner: false,
      showHideOwner: JSON.parse(localStorage.getItem("authUser")),
      uploadPermission: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
  }

  componentDidMount() {
    let adminId = this.props.match.params.adminId;
    if (adminId) {
      this.setState({
        editMode: true,
        adminId: adminId,
      });

      this.showLoader(true);
      this.getCountryList();
      this.getGroup();
      // this.getStaffDetail(adminId);
    } else {
      this.getCountryList();
      this.getGroup();
    }
  }

  async getCountryList() {
    await getCountries().then((res) => {
      this.setState({ countries: res });
    });
  }
  async getGroup() {
    await getGroupLists().then((res) => {
      this.setState({ options: res });
    });
    if (this.state.editMode) {
      let adminId = this.props.match.params.adminId;
      this.getStaffDetail(adminId);
    }
  }
  async getStaffDetail(adminId) {
    await getStaffDetailByID(adminId).then((res) => {
      this.mapDetailsToForm(res);
    });
  }
  mapDetailsToForm(res) {
    let checkLevel = this.state.options
      .filter((value) => value.name == res.group_name)
      .map((item) => item?.id);
    this.setState({
      firstName: res.first_name,
      lastName: res.last_name,
      email: res.email,
      username: res.username,
      phoneNumber: res.phone,
      street: res.address.street_address,
      city: res.address.city,
      state: res.address.state,
      postalCode: res.address.postal_code,
      country: res.address.country_id,
      selectLevel: checkLevel[0],
      assignedPermissionLists: res.permission,
      Owner: res.is_owner,
    });
    this.callPermission(res.permission);
    this.showLoader(false);
  }
  callPermission = (trueValue) => {
    const setGivenPermission = FindPermission(trueValue);
    const setAllowedPermission = FindAllowedPermission(trueValue);
    let defaultViewId = [];
    let checkForDefaultUpload = [];
    setAllowedPermission.forEach((data) => {
      data.permissions.forEach((a) => {
        const permissionName = a.name.split(" ");
        if (permissionName[1] === "view" && data.permissions.length != 5) {
          defaultViewId.push(a.id);
        } else if (permissionName[1] === "Upload" && a.type_) {
          checkForDefaultUpload.push(a.id);
        }
      });
    });
    // const findDefaultViewPermissionObj =
    //   setAllowedPermission.permissions.at(-1);
    this.setState(
      {
        givenPermission: setGivenPermission,
        allowedPermission: setAllowedPermission,
        showPermission: true,
        defaultViewPermissionIds: defaultViewId,
        uploadPermission: checkForDefaultUpload,
      },
      () => {}
    );
  };
  async handleValidSubmit(event, values) {
    event.preventDefault();
    if (this.state.Owner) {
      let model = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        username: this.state.username,
        phone: this.state.phoneNumber,
        street_address: this.state.street,
        city: this.state.city,
        state: this.state.state,
        postal_code: this.state.postalCode,
        country_id: +this.state.country,
        is_owner: this.state.Owner,
      };
      this.showLoader(true);
      this.setState({ errorMsg: null });
      await addeditAdminUser(
        model,
        this.state.editMode,
        this.state.adminId
      ).then(
        (_) => {
          this.showLoader(false);
          toast.success(
            `Staff Member ${
              this.state.editMode ? "Updated" : "Added"
            } Successfully`,
            toastrOptions
          );
          this.goToListing();
        },
        (err) => {
          this.showLoader(false);
          if (err.length === 2) {
            this.setState({ errorMsg: null });
          } else {
            this.setState({ errorMsg: err });
          }
        }
      );
    } else if (
      this.state.givenPermission.length === 0 &&
      this.state.defaultViewPermissionIds.length === 0
    ) {
      this.setState({ errorMsg: "Please allow some permissions" });
    } else {
      const finalPermissions = [
        ...this.state.givenPermission,
        ...this.state.defaultViewPermissionIds,
      ];
      const permissionsWithoutDuplicates = [...new Set(finalPermissions)];
      let model = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        username: this.state.username,
        phone: this.state.phoneNumber,
        street_address: this.state.street,
        city: this.state.city,
        state: this.state.state,
        postal_code: this.state.postalCode,
        country_id: +this.state.country,
        group: parseInt(values.selectLevel),
        permissions: permissionsWithoutDuplicates,
        is_owner: this.state.Owner,
      };
      this.showLoader(true);
      this.setState({ errorMsg: null });
      await addeditAdminUser(
        model,
        this.state.editMode,
        this.state.adminId
      ).then(
        (_) => {
          this.showLoader(false);
          toast.success(
            `Staff Member ${
              this.state.editMode ? "Updated" : "Added"
            } Successfully`,
            toastrOptions
          );
          this.goToListing();
        },
        (err) => {
          this.showLoader(false);
          if (err.length === 2) {
            this.setState({ errorMsg: null });
          } else {
            this.setState({ errorMsg: err });
          }
        }
      );
    }
  }

  goToListing() {
    this.props.history.push("/staff");
  }

  showLoader(isLoad) {
    this.setState({
      isLoading: isLoad,
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]:
        event.target.name === "Owner"
          ? event.target.value === "false"
            ? true
            : false
          : event.target.value,
    });
  }

  handleSelected = async (event) => {
    this.setState({ assignedPermissionLists: [], showPermission: false });
    if(!event.target.value) return

    await getGroupDetailByID(event.target.value).then(
      (res) => {
        this.setState({
          assignedPermissionLists: res.permissions,
          errorMsg: null,
          showPermission: true,
        });
        this.callPermission(res.permissions);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  handleCheckboxPermission = (newPermission, type, upload, allData) => {
    let findDefaultViewPermissionObj = [];
    if (upload == "Upload" && !type) {
      allData.forEach((a) => {
        const permissionName = a.name.split(" ");
        if (permissionName[1] === "view") {
          findDefaultViewPermissionObj.push(a.id);
        }
      });
    }
    if (this.state.editMode) {
      this.setState(
        {
          givenPermission: this.state.givenPermission.includes(newPermission)
            ? this.state.givenPermission.filter((n) => n !== newPermission)
            : type
            ? [...this.state.givenPermission, newPermission]
            : this.state.givenPermission,
          uploadPermission:
            upload == "Upload"
              ? this.state.uploadPermission.includes(newPermission)
                ? this.state.uploadPermission.filter((n) => n !== newPermission)
                : type
                ? [...this.state.uploadPermission, newPermission]
                : this.state.uploadPermission
              : this.state.uploadPermission,
          defaultViewPermissionIds:
            upload == "Upload"
              ? !type
                ? [
                    ...this.state.defaultViewPermissionIds,
                    findDefaultViewPermissionObj[0],
                  ]
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds.includes(newPermission)
              ? this.state.defaultViewPermissionIds.filter(
                  (n) => n !== newPermission
                )
              : this.state.defaultViewPermissionIds,

          errorMsg: null,
        },
        () => {
          const idsOfGameUpload = allData
            .filter((el) => this.state.givenPermission.includes(el.id))
            .map((filterId) => {
              return filterId.id;
            });
          //to remove ids if admin has selected upload permission (remove other game permission)
          const permissionAfterRemovingIds =
            type && upload === "Upload"
              ? this.state.givenPermission.includes(newPermission)
                ? this.state.givenPermission.filter((ele) => {
                    if (ele === newPermission) {
                      return idsOfGameUpload.includes(ele);
                    } else {
                      return !idsOfGameUpload.includes(ele);
                    }
                  })
                : this.state.givenPermission
              : this.state.givenPermission;
          let filterDview = [];
          if (type && upload === "Upload") {
            allData.forEach((a) => {
              const permissionName = a.name.split(" ");
              if (permissionName[1] === "view") {
                filterDview.push(a.id);
              }
            });
          }
          const viewIds =
            type && upload === "Upload"
              ? this.state.defaultViewPermissionIds.includes(filterDview[0])
                ? this.state.defaultViewPermissionIds.filter(
                    (n) => n !== filterDview[0]
                  )
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds;
          this.setState(
            {
              givenPermission: permissionAfterRemovingIds,
              defaultViewPermissionIds: viewIds,
            },
            () => {}
          );
        }
      );
    } else {
      this.setState(
        {
          givenPermission: this.state.givenPermission.includes(newPermission)
            ? this.state.givenPermission.filter((n) => n !== newPermission)
            : type
            ? [...this.state.givenPermission, newPermission]
            : this.state.givenPermission,

          uploadPermission:
            upload == "Upload"
              ? this.state.uploadPermission.includes(newPermission)
                ? this.state.uploadPermission.filter((n) => n !== newPermission)
                : type
                ? [...this.state.uploadPermission, newPermission]
                : this.state.uploadPermission
              : this.state.uploadPermission,
          defaultViewPermissionIds:
            upload == "Upload"
              ? !type
                ? [
                    ...this.state.defaultViewPermissionIds,
                    findDefaultViewPermissionObj[0],
                  ]
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds.includes(newPermission)
              ? this.state.defaultViewPermissionIds.filter(
                  (n) => n !== newPermission
                )
              : this.state.defaultViewPermissionIds,
        },
        () => {
          const idsOfGameUpload = allData
            .filter((el) => this.state.givenPermission.includes(el.id))
            .map((filterId) => {
              return filterId.id;
            });
          //to remove ids if admin has selected upload permission (remove other game permission)
          const permissionAfterRemovingIds =
            type && upload === "Upload"
              ? this.state.givenPermission.includes(newPermission)
                ? this.state.givenPermission.filter((elem) => {
                    if (elem === newPermission) {
                      return idsOfGameUpload.includes(elem);
                    } else {
                      return !idsOfGameUpload.includes(elem);
                    }
                  })
                : this.state.givenPermission
              : this.state.givenPermission;
          let filterDview = [];
          if (type && upload === "Upload") {
            allData.forEach((a) => {
              const permissionName = a.name.split(" ");
              if (permissionName[1] === "view") {
                filterDview.push(a.id);
              }
            });
          }
          const viewIds =
            type && upload === "Upload"
              ? this.state.defaultViewPermissionIds.includes(filterDview[0])
                ? this.state.defaultViewPermissionIds.filter(
                    (n) => n !== filterDview[0]
                  )
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds;
          this.setState(
            {
              givenPermission: permissionAfterRemovingIds,
              defaultViewPermissionIds: viewIds,
            },
            () => {}
          );
        }
      );
    }
  };
  handleRadioPermission = (data, id, type) => {
    let findDefaultViewPermissionObj = [];
    data.permissions.forEach((a) => {
      const permissionName = a.name.split(" ");
      if (permissionName[1] === "view") {
        findDefaultViewPermissionObj.push(a.id);
      }
    });
    let checkForDefaultUpload = [];
    data.permissions.forEach((a) => {
      const permissionName = a.name.split(" ");
      if (permissionName[1] === "Upload" && a.type_) {
        checkForDefaultUpload.push(a.id);
      }
    });
    this.setState(
      {
        allowedPermission: this.state.allowedPermission.includes(data)
          ? this.state.allowedPermission.filter((n) => n !== data)
          : type
          ? [...this.state.allowedPermission, data]
          : this.state.allowedPermission.filter(
              (val) => val.label != data.label
            ),
        givenPermission: !type
          ? this.state.givenPermission.filter(
              (iGiven) => !data.permissions.find((f) => f.id === iGiven)
            )
          : [...this.state.givenPermission, ...checkForDefaultUpload],
        defaultViewPermissionIds: type
          ? [
              ...this.state.defaultViewPermissionIds,
              findDefaultViewPermissionObj[0],
            ]
          : this.state.defaultViewPermissionIds.includes(
              findDefaultViewPermissionObj[0]
            )
          ? this.state.defaultViewPermissionIds.filter(
              (n) => n !== findDefaultViewPermissionObj[0]
            )
          : this.state.defaultViewPermissionIds,
        uploadPermission: !type
          ? this.state.uploadPermission.filter(
              (iUpload) => !data.permissions.find((f) => f.id === iUpload)
            )
          : [...this.state.uploadPermission, ...checkForDefaultUpload],
      },
      () => {}
    );
  };

  render() {
    return (
      <React.Fragment>
        <Loader showLoader={this.state.isLoading} />
        <div className="page-content">
          <Breadcrumbs
            breadcrumbItem={
              (this.state.editMode ? "Edit" : "Add") + " Staff Member"
            }
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <p>
                        <Link to="/staff">
                          <i className="mdi mdi-arrow-left"></i> back
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Row className="add-staff-member">
                    <Col className="col-lg-8 col-md-8 col-sm-8 col-12">
                      <AvForm
                        onValidSubmit={(e, v) => {
                          this.handleValidSubmit(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="firstName"
                            label="First Name*"
                            placeholder="Enter first name here"
                            type="text"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "First name is required",
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "First name can have 50 characters max",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="lastName"
                            label="Last Name"
                            placeholder="Enter last name here"
                            type="text"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            validate={{
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "First name can have 50 characters max",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email Address*"
                            placeholder="Enter email address here"
                            type="text"
                            value={this.state.email}
                            onChange={this.handleChange}
                            validate={{
                              email: {
                                value: true,
                                errorMessage: "Invalid email address format",
                              },
                              required: {
                                value: true,
                                errorMessage: "Email address is required",
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "Email address can have 50 characters max",
                              },
                            }}
                            disabled={this.state.editMode}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="username"
                            label="Username*"
                            placeholder="Enter username here"
                            type="text"
                            value={this.state.username}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Username is required",
                              },
                              maxLength: {
                                value: 16,
                                errorMessage:
                                  "Username can have 16 characters max",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="phoneNumber"
                            label="Phone Number*"
                            placeholder="Enter phone number here"
                            type="number"
                            value={this.state.phoneNumber}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Phone number is required",
                              },
                              maxLength: {
                                value: 15,
                                errorMessage:
                                  "Phone number can have 15 digits max",
                              },
                              // pattern: {
                              //   value: /(7|8|9)\d{9}/,
                              //   errorMessage: "Invalid phone number",
                              // },
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="street"
                            label="Street*"
                            placeholder="Enter street address here"
                            type="text"
                            value={this.state.street}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Street address is required",
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "Street address can have 50 characters max",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="city"
                            label="City*"
                            placeholder="Enter city here"
                            type="text"
                            value={this.state.city}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "City is required",
                              },
                              maxLength: {
                                value: 50,
                                errorMessage: "City can have 50 characters max",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="state"
                            label="State*"
                            placeholder="Enter state here"
                            type="text"
                            value={this.state.state}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "State is required",
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "State can have 50 characters max",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="postalCode"
                            label="Postal Code*"
                            placeholder="Enter postal code here"
                            type="text"
                            value={this.state.postalCode}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Postal code is required",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Postal code can have 10 characters max",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="country"
                            label="Country*"
                            placeholder="Enter country here"
                            type="select"
                            value={this.state.country}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Country is required",
                              },
                            }}
                          >
                            {this.state.countries &&
                              this.state.countries.map((country) => (
                                <option value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                          </AvField>
                        </div>
                        {this.state.showHideOwner.extras.role === 2 ||
                        this.state.showHideOwner.extras.is_admin_owner ? (
                          <div className="mb-3">
                            <AvField
                              name="Owner"
                              label="Super Admin"
                              type="checkbox"
                              value={this.state.Owner}
                              onChange={this.handleChange}
                              checked={this.state.Owner}
                            />
                          </div>
                        ) : null}

                        {this.state.Owner ? null : (
                          <>
                            <div className="mb-3">
                              <AvField
                                type="select"
                                name="selectLevel"
                                label="Select Level"
                                value={this.state.selectLevel}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Select Level is required",
                                  },
                                }}
                                onChange={this.handleSelected}
                              >
                                <option value=""> Select level</option>
                                {this.state.options.map((data, index) => {
                                  return (
                                    <option value={data.id} key={index}>
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </AvField>
                            </div>
                            {this.state.showPermission ? (
                              <div className="mb-3 permissionsBoxes">
                                {this.state.editMode
                                  ? this.state.assignedPermissionLists.map(
                                      (item, i) => {
                                        return (
                                          <div key={i} className="permission">
                                            <div className="d-flex justify-content-between">
                                              <lable className="permissionLable">
                                                {item.label}
                                              </lable>
                                              <div className="premissionsDiv">
                                                <span className="allowPermissions">
                                                  <input
                                                    type="radio"
                                                    id={`permissionButton${i}`}
                                                    name={item.label}
                                                    //value={data?.id}
                                                    onChange={() =>
                                                      this.handleRadioPermission(
                                                        item,
                                                        i,
                                                        true
                                                      )
                                                    }
                                                    defaultChecked={
                                                      item.permissions.some(
                                                        (e) => e?.type_ == true
                                                      )
                                                        ? "checked"
                                                        : ""
                                                    }
                                                  />
                                                  Allow
                                                </span>
                                                <span>
                                                  <input
                                                    type="radio"
                                                    id={`permissionButton${i}`}
                                                    name={item.label}
                                                    // value={data?.id}
                                                    onChange={() =>
                                                      this.handleRadioPermission(
                                                        item,
                                                        i,
                                                        false
                                                      )
                                                    }
                                                    defaultChecked={
                                                      item.permissions.every(
                                                        (e) => e?.type_ == false
                                                      )
                                                        ? "checked"
                                                        : ""
                                                    }
                                                  />
                                                  Deny
                                                </span>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                              {this.state.allowedPermission
                                                ?.length === 0
                                                ? null
                                                : this.state.allowedPermission?.map(
                                                    (data, index) => {
                                                      if (data?.length === 0) {
                                                        return null;
                                                      } else if (
                                                        item.label !==
                                                        data.label
                                                      ) {
                                                        return null;
                                                      } else {
                                                        return data?.permissions?.map(
                                                          (
                                                            permissionLists,
                                                            idx
                                                          ) => {
                                                            const permissionName =
                                                              permissionLists.name.split(
                                                                " "
                                                              );
                                                            const disableWholeIndex =
                                                              data?.permissions.filter(
                                                                (ind) =>
                                                                  this.state.uploadPermission.includes(
                                                                    ind.id
                                                                  )
                                                              );
                                                            return (
                                                              <div
                                                                className={
                                                                  permissionName[1] ==
                                                                  "Upload"
                                                                    ? "d-flex allPermissions col-4"
                                                                    : "d-flex allPermissions col-2"
                                                                }
                                                                key={idx}
                                                              >
                                                                {item.label ==
                                                                data.label ? (
                                                                  <>
                                                                    <input
                                                                      className={
                                                                        permissionName[1] ==
                                                                        "Upload"
                                                                          ? "form-check-input viewUploadInput"
                                                                          : "form-check-input"
                                                                      }
                                                                      type="checkbox"
                                                                      id={`permissionButton${idx}`}
                                                                      name={
                                                                        permissionLists.codename
                                                                      }
                                                                      // value={permissionLists?.id}
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.handleCheckboxPermission(
                                                                          permissionLists.id,
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          permissionName[1],
                                                                          data?.permissions
                                                                        )
                                                                      }
                                                                      defaultChecked={
                                                                        permissionLists.type_ ===
                                                                        true
                                                                          ? "checked"
                                                                          : permissionName[1] ==
                                                                            "view"
                                                                          ? "checked"
                                                                          : this.state.givenPermission.includes(
                                                                              permissionLists.id
                                                                            )
                                                                          ? "checked"
                                                                          : ""
                                                                      }
                                                                      disabled={
                                                                        disableWholeIndex.length !=
                                                                        0
                                                                          ? this.state.uploadPermission.some(
                                                                              (
                                                                                itemUpload
                                                                              ) =>
                                                                                itemUpload ===
                                                                                permissionLists.id
                                                                            )
                                                                            ? false
                                                                            : true
                                                                          : this.state.defaultViewPermissionIds.includes(
                                                                              permissionLists.id
                                                                            )
                                                                          ? true
                                                                          : permissionName[1] ==
                                                                            "view"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                    />
                                                                    <p>
                                                                      {permissionName[1] ==
                                                                      "Upload"
                                                                        ? permissionLists.name
                                                                        : permissionName[1].toUpperCase()}
                                                                    </p>
                                                                  </>
                                                                ) : null}
                                                              </div>
                                                            );
                                                          }
                                                        );
                                                      }
                                                    }
                                                  )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : this.state.assignedPermissionLists.map(
                                      (item, id) => {
                                        return (
                                          <div key={id} className="permission">
                                            <div className="d-flex justify-content-between">
                                              <lable className="permissionLable">
                                                {item.label}
                                              </lable>
                                              <div className="premissionsDiv">
                                                <span className="allowPermissions">
                                                  <input
                                                    type="radio"
                                                    id={`permissionButton${id}`}
                                                    name={item.label}
                                                    //value={data?.id}
                                                    onChange={() =>
                                                      this.handleRadioPermission(
                                                        item,
                                                        id,
                                                        true
                                                      )
                                                    }
                                                    defaultChecked={
                                                      item.permissions.some(
                                                        (e) => e?.type_ == true
                                                      )
                                                        ? "checked"
                                                        : ""
                                                    }
                                                  />
                                                  Allow
                                                </span>
                                                <span>
                                                  <input
                                                    type="radio"
                                                    id={`permissionButton${id}`}
                                                    name={item.label}
                                                    // value={data?.id}
                                                    onChange={() =>
                                                      this.handleRadioPermission(
                                                        item,
                                                        id,
                                                        false
                                                      )
                                                    }
                                                    defaultChecked={
                                                      item.permissions.every(
                                                        (e) => e?.type_ == false
                                                      )
                                                        ? "checked"
                                                        : ""
                                                    }
                                                  />
                                                  Deny
                                                </span>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                              {this.state.allowedPermission
                                                ?.length === 0
                                                ? null
                                                : this.state.allowedPermission?.map(
                                                    (data, index) => {
                                                      if (data?.length === 0) {
                                                        return null;
                                                      } else if (
                                                        item.label !==
                                                        data.label
                                                      ) {
                                                        return null;
                                                      } else {
                                                        return data?.permissions?.map(
                                                          (
                                                            permissionLists,
                                                            idx
                                                          ) => {
                                                            const permissionName =
                                                              permissionLists.name.split(
                                                                " "
                                                              );
                                                            const disableWholeIndex =
                                                              data?.permissions.filter(
                                                                (ind) =>
                                                                  this.state.uploadPermission.includes(
                                                                    ind.id
                                                                  )
                                                              );
                                                            return (
                                                              <div
                                                                className={
                                                                  permissionName[1] ==
                                                                  "Upload"
                                                                    ? "d-flex allPermissions col-4"
                                                                    : "d-flex allPermissions col-2"
                                                                }
                                                                key={idx}
                                                              >
                                                                {item.label ==
                                                                data.label ? (
                                                                  <>
                                                                    <input
                                                                      className={
                                                                        permissionName[1] ==
                                                                        "Upload"
                                                                          ? "form-check-input viewUploadInput"
                                                                          : "form-check-input"
                                                                      }
                                                                      type="checkbox"
                                                                      id={`permissionButton${idx}`}
                                                                      name={
                                                                        permissionLists.codename
                                                                      }
                                                                      // value={permissionLists?.id}
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.handleCheckboxPermission(
                                                                          permissionLists.id,
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          permissionName[1],
                                                                          data?.permissions
                                                                        )
                                                                      }
                                                                      defaultChecked={
                                                                        permissionLists.type_ ===
                                                                        true
                                                                          ? "checked"
                                                                          : permissionName[1] ==
                                                                            "view"
                                                                          ? "checked"
                                                                          : this.state.givenPermission.includes(
                                                                              permissionLists.id
                                                                            )
                                                                          ? "checked"
                                                                          : ""
                                                                      }
                                                                      disabled={
                                                                        disableWholeIndex.length !=
                                                                        0
                                                                          ? this.state.uploadPermission.some(
                                                                              (
                                                                                itemCheck
                                                                              ) =>
                                                                                itemCheck ===
                                                                                permissionLists.id
                                                                            )
                                                                            ? false
                                                                            : true
                                                                          : this.state.defaultViewPermissionIds.includes(
                                                                              permissionLists.id
                                                                            )
                                                                          ? true
                                                                          : permissionName[1] ==
                                                                            "view"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                    />
                                                                    <p>
                                                                      {permissionName[1] ==
                                                                      "Upload"
                                                                        ? permissionLists.name
                                                                        : permissionName[1].toUpperCase()}
                                                                    </p>
                                                                  </>
                                                                ) : null}
                                                              </div>
                                                            );
                                                          }
                                                        );
                                                      }
                                                    }
                                                  )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                              </div>
                            ) : null}
                          </>
                        )}

                        {this.state.errorMsg ? (
                          <p className="error-msg">{this.state.errorMsg}</p>
                        ) : null}

                        <FormGroup className="mt-4">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="ms-1"
                              disabled={this.state.isLoading}
                            >
                              Submit
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AdminUserForm);
