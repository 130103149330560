import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row, FormGroup, Button } from "reactstrap";
import { getComplaintDetail } from "../../services/complaint_user_api_helper";
import Loader from "../../components/Common/Loader";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";

const ComplaintDetail = (props) => {
  const returnUrl = "/complaints";
  //const { compId } = useParams();
  const [complaint, setcomplaint] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const compid = window.location.href;
  const compId = compid.substring(compid.lastIndexOf("/") + 1);
  useEffect(() => {
    if (compId > 0) {
      getDetail();
    } else {
      goToBack();
    }
    if (props.permission !== null || props.permission.length !== 0) {
      callSetPermission();
    }
  }, [compId]);
  const callSetPermission = () => {
    const typeUser = "Users";
    const typeUserView = "view_user";
    const filteredPermissionUser = FilterPermission(props.permission, typeUser);
    if (filteredPermissionUser.length != 0) {
      const setViewuser = filterOutPermissionToShowHide(
        filteredPermissionUser[0]?.permissions,
        typeUserView
      );
      setViewUser(setViewuser);
    }
  };
  const getDetail = async () => {
    setisLoading(true);
    await getComplaintDetail(compId)
      .then((res) => {
        setcomplaint(res);
        setisLoading(false);
      })
      .catch(() => {
        setcomplaint(null);
        setisLoading(false);
        goToBack();
      });
  };

  const goToBack = () => {
    props.history.push(returnUrl);
  };
  return (
    <>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Complaint Detail" />
        <Loader showLoader={isLoading} />
        <Row>
          <Col>
            <Card>
              {complaint && (
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <p>
                        <Link
                          to={
                            props.location.data?.prevPath === "/report"
                              ? {
                                  pathname: props.location.data?.prevPath,
                                  state: {
                                    active: props.location.data.activeTab,
                                    filter: props.location.data.filter,
                                    activeFilter:
                                      props.location.data.activeFilter,
                                    detailView: props.location.data.detailView,
                                    setDates: props.location.data.setDates,
                                  },
                                }
                              : {
                                  pathname: returnUrl,
                                }
                          }

                          // onClick={goBack}
                        >
                          <i className="mdi mdi-arrow-left"></i> back
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-12">
                      <h4 className="mb-4">{complaint?.lobby?.name}</h4>
                    </Col>
                    <Col className="col-12 mb-3">
                      Reporter : {complaint?.reporter?.username}
                    </Col>
                    <Col className="col-12 mb-3">
                      Accused : {complaint?.offender?.username}
                    </Col>
                    <Col className="col-12 mb-3">
                      Registered On :{" "}
                      {new Date(complaint?.create_date).toLocaleString(
                        "en-US",
                        {
                          weekday: "short", // long, short, narrow
                          day: "numeric", // numeric, 2-digit
                          year: "numeric", // numeric, 2-digit
                          month: "long", // numeric, 2-digit, long, short, narrow
                          hour: "numeric", // numeric, 2-digit
                          minute: "numeric", // numeric, 2-digit
                          second: "numeric", // numeric, 2-digit
                          timeZone: "UTC",
                          hour12: false,
                        }
                      )}
                    </Col>
                    <Col className="col-12 mb-3">
                      Streaming Link :{" "}
                      <a
                        href={complaint?.streaming_link}
                        target="_blank"
                        rel="noreferrer"
                        title={complaint?.streaming_link}
                      >
                        Link
                      </a>
                    </Col>
                    <Col className="col-12 mb-3">
                      Message : {complaint?.message}
                    </Col>
                    <Col className="col-12 mb-3">
                      Status :{" "}
                      {complaint?.status === 1 ? "Pending" : "Resolved"}
                    </Col>
                    {viewUser ? (
                      <Col className="col-12 mb-3">
                        <FormGroup className="mt-4">
                          <div>
                            <Link
                              to={{
                                pathname: "/user/" + complaint?.offender?.id,
                                data: window.location.pathname,
                              }}
                            >
                              <Button color="primary" className="ms-1">
                                Go to Accused Account
                              </Button>
                            </Link>
                          </div>
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(ComplaintDetail);
