import { axiosApi, setToken } from "./index";
export async function getComplaintList() {
  setToken();
  return await axiosApi.get(`complaint/`).then((response) => response);
}
export async function getComplaintDetail(id) {
  setToken();
  return await axiosApi.get(`complaint/${id}/`).then((response) => response);
}
export async function getBannedCountryList() {
  setToken();
  return await axiosApi.get(`banned-locations/`).then((response) => response);
}
export async function addBanCountry(model) {
  setToken();
  return await axiosApi.post(`banned-locations/`, model);
}
export async function deleteCountryState(id) {
  setToken();
  return await axiosApi.delete(`banned-locations/${id}`);
}
export async function editBanCountry(model, id) {
  setToken();
  return await axiosApi.put(`banned-locations/${id}/`, model);
}
