import React, { useEffect, useState } from "react";
import {
  getBannedCountryList,
  deleteCountryState,
} from "../../services/complaint_user_api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Paginator from "../../helpers/paginator/paginator";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { permissionsStrings } from "../../helpers/StringConstant";
const LocationList = (props) => {
  const [counrtyList, setCountryList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const [totalCount, settotalCount] = useState(null);

  //filter keys
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);

  //const [permission, setPermission] = useState([]);
  const [changePermission, setChange] = useState(false);
  const [deletePermission, setDelete] = useState(false);
  const [addPermission, setAdd] = useState(false);
  useEffect(() => {
    getBannedCountry();
    if (props.permission === null || props.permission.length === 0) {
      setChange(true);
      setDelete(true);
      setAdd(true);
    } else {
      callSetPermission();
    }
  }, [pageNumber]);
  const callSetPermission = () => {
    const type = permissionsStrings.bannedLocation;
    const typeChange = permissionsStrings.typeBannedLocationChange;
    const typeDelete = permissionsStrings.typeBannedLocationDelete;
    const typeAdd = permissionsStrings.typeBannedLocationAdd;

    const filteredPermission = FilterPermission(props.permission, type);
    const setchange =
      filteredPermission.length === 0
        ? false
        : filterOutPermissionToShowHide(
            filteredPermission[0].permissions,
            typeChange
          );
    const setdelete =
      filteredPermission.length === 0
        ? false
        : filterOutPermissionToShowHide(
            filteredPermission[0].permissions,
            typeDelete
          );
    const setadd =
      filteredPermission.length === 0
        ? false
        : filterOutPermissionToShowHide(
            filteredPermission[0].permissions,
            typeAdd
          );
    setChange(setchange);
    setDelete(setdelete);
    setAdd(setadd);
    // setPermission(filteredPermission[0].permissions);
  };
  const getBannedCountry = async () => {
    setisLoading(true);
    await getBannedCountryList()
      .then((res) => {
        setCountryList(res);
        settotalCount(0);

        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  const openAlert = (id) => {
    setSelectedCountryName(id);
    setdeleteModal(true);
  };
  async function deleteCountryName() {
    await deleteCountryState(selectedCountryName).then((res) => {
      getBannedCountry();
      toast.success(res.message, toastrOptions);
      closeAlert();
    });
  }

  const closeAlert = () => {
    setdeleteModal(false);
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs breadcrumbItem="Manage Locations" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="search-box">
                    {addPermission ? (
                      <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3">
                        <Link to="/locationLists/banLocation">
                          <button className="btn btn-primary">
                            {" "}
                            Ban location{" "}
                          </button>
                        </Link>
                      </Col>
                    ) : null}
                  </Row>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered ban-location-table"
                      >
                        <Thead>
                          <Tr>
                            <Th>Sr. no.</Th>
                            <Th>Country</Th>
                            <Th>State</Th>
                            {changePermission || deletePermission ? (
                              <Th data-priority="6" colSpan="2">
                                Actions
                              </Th>
                            ) : null}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {isLoading ? (
                            <div
                              class="spinner-grow transaction-spinner"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          ) : counrtyList.length === 0 ? (
                            <h5 className="text-center mt-4">
                              No Location(s) Found
                            </h5>
                          ) : (
                            counrtyList.map((item, index) => {
                              return (
                                <Tr key={index}>
                                  <Td>{index + 1}</Td>
                                  <Td>{item?.country_name}</Td>
                                  <Td>
                                    {Object.keys(item?.all_states)
                                      .map(function (k) {
                                        return item?.all_states[k].state_name;
                                      })
                                      .join(", ")}
                                  </Td>
                                  {changePermission ? (
                                    <Td>
                                      <Link
                                        to={{
                                          pathname: `/locationLists/editLocation/${item?.country_id}`,
                                          state: { item },
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    </Td>
                                  ) : null}
                                  {deletePermission ? (
                                    <Td className="location-delete-btn">
                                      <button
                                        className="btn btn-secondary btn-sm complaint-view-btn"
                                        onClick={() => {
                                          openAlert(item.country_id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </Td>
                                  ) : null}
                                </Tr>
                              );
                            })
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  {isLoading ? null : (
                    <Paginator
                      totalCount={totalCount}
                      pageSize={pageSize}
                      pageClick={handlePageClick}
                    />
                  )}
                </CardBody>
                {deleteModal ? (
                  <SweetAlert
                    title="Delete Location"
                    warning
                    showCancel
                    confirmButtonText="Yes"
                    confirmBtnBsStyle="success"
                    cancelButtonText="No"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => deleteCountryName()}
                    onCancel={() => closeAlert()}
                  ></SweetAlert>
                ) : null}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </>
  );
};

export default LocationList;
