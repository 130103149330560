import React, { useCallback, useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getActivityList, getEventList } from "../../services/user_api_helper";

// Paginator
import Paginator from "../../helpers/paginator/paginator";
import FilterPermission from "../../helpers/FilterPermission";
import DateFiltersReports from "../../components/Common/DateFiltersReports";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { cloneDeep, debounce } from "lodash";
import { convertDateToHyphenFormat, dropdownOptions } from "../../helpers/util";
import { permissionsStrings } from "../../helpers/StringConstant";
const ActivityLog = (props) => {
  const [logs, setLogs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [active, setactive] = useState({
    today: "",
    week: "",
    month: "",
    year: "",
    clear: "",
  });
  // filter model
  const pageSize = 25;
  const [totalCount, settotalCount] = useState(null);
  const [pageNumber, setpageNumber] = useState(1);
  //const [permission, setPermission] = useState([]);
  const [singlebtn, setSinglebtn] = useState(false);
  const [selectedDropdown, setselectedDropdown] = useState(null);
  const [sortBy, setsortBy] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const dropdownChange = (value) => {
    setselectedDropdown(value);
    setsortBy(value);
    setpageNumber(1);
    // setactive("");
  };

  useEffect(() => {
    getListing();
    // callSetPermission();
  }, []);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const handleToday = useCallback(() => {
    const newEndDate = new Date();
    setDateRange([newEndDate, newEndDate]);
    setactive({
      today: "1",
      week: "",
      month: "",
      year: "",
      clear: "",
    });
    // stopAnotherApiCall(false);
    setpageNumber(1);
  }, []);

  const handleWeek = useCallback(() => {
    const newStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    const newEndDate = endOfWeek(new Date(), { weekStartsOn: 1 });
    setDateRange([newStartDate, newEndDate]);
    setactive({
      today: "",
      week: "2",
      month: "",
      year: "",
      clear: "",
    });
    // stopAnotherApiCall(false);
    setpageNumber(1);
  }, []);

  const handleMonth = useCallback(() => {
    const newStartDate = startOfMonth(new Date());
    const newEndDate = endOfMonth(new Date());
    setDateRange([newStartDate, newEndDate]);
    setactive({
      today: "",
      week: "",
      month: "3",
      year: "",
      clear: "",
    });
    setpageNumber(1);
  }, []);

  const handleYear = useCallback(() => {
    const newStartDate = startOfYear(new Date());
    const newEndDate = endOfYear(new Date());
    setDateRange([newStartDate, newEndDate]);
    setactive({
      today: "",
      week: "",
      month: "",
      year: "4",
      clear: "",
    });
    //stopAnotherApiCall(false);
    setpageNumber(1);
  }, []);
  const [clear, setClear] = useState(false);
  const handleClear = () => {
    setDateRange([null, null]);
    setClear(true);
    setactive({
      today: "",
      week: "",
      month: "",
      year: "",
      clear: "5",
    });
    setpageNumber(1);
    setSearchTerm();
    setsortBy(null);
    setselectedDropdown(null);
    setSelectedType(null);
    // setselectedTypeDropdown(null);
    // setAllType([]);
    setSelectedPlayerEventListId([]);
    setSelectedAdminEventListId([]);
    setSelectedOtherEventListId([]);
    setAllEvent({
      player: null,
      admin: null,
      other: null,
    });
    setPlayerEventShowMore(false);
    setAdminEventShowMore(false);
    setOtherEventShowMore(false);
    // getListing();
  };
  const updateDateFromPicker = useCallback((dates) => {
    // stopAnotherApiCall(false);
    const [start, end] = dates;
    if (start && end) {
      setactive({
        today: "",
        week: "",
        month: "",
        year: "",
        clear: "",
      });
    }
    setDateRange(dates);
    setpageNumber(1);
  }, []);

  // const callSetPermission = () => {
  //   const type = permissionsStrings.log;
  //   const filteredPermission = FilterPermission(props.permission, type);
  //   if (filteredPermission.length !== 0) {
  //     setPermission(filteredPermission[0].permissions);
  //   }
  // };

  // useEffect(() => {
  //   // you will get updated finalData here, each time it changes
  //   console.log(selectedType);
  //   // you can trigger your function from here
  //   if (clear || selectedType != null) getListing();
  // }, [selectedType, clear]);
  const [playerEvent, setPlayerEvent] = useState([]);
  const [adminEvent, setAdminEvent] = useState([]);
  const [otherEvent, setOtherEvent] = useState([]);
  const [playerEventConstant, setConstantPlayerEvent] = useState([]);
  const [adminEventConstant, setConstantAdminEvent] = useState([]);
  const [otherEventConstant, setConstantOtherEvent] = useState([]);
  const getListing = (type) => {
    getEventList(selectedType)
      .then((res) => {
        if (type !== undefined) {
          if (type == null) {
            setPlayerEvent(res[0]);
            setConstantPlayerEvent(res[0]);
            setAdminEvent(res[1]);
            setConstantAdminEvent(res[1]);
            setOtherEvent(res[2]);
            setConstantOtherEvent(res[2]);
          } else if (type === 1) {
            setPlayerEvent(res[0]);
            setConstantPlayerEvent(res[0]);
            setAdminEvent([]);
            setConstantAdminEvent([]);
            setOtherEvent([]);
            setConstantOtherEvent([]);
          } else if (type === 2) {
            setAdminEvent(res[1]);
            setConstantAdminEvent(res[1]);
            setPlayerEvent([]);
            setConstantPlayerEvent([]);
            setOtherEvent([]);
            setConstantOtherEvent([]);
          } else if (type === 3) {
            setOtherEvent(res[2]);
            setConstantOtherEvent(res[2]);
            setPlayerEvent([]);
            setConstantPlayerEvent([]);
            setAdminEvent([]);
            setConstantAdminEvent([]);
          }
        } else {
          if (selectedType == null) {
            setPlayerEvent(res[0]);
            setConstantPlayerEvent(res[0]);
            setAdminEvent(res[1]);
            setConstantAdminEvent(res[1]);
            setOtherEvent(res[2]);
            setConstantOtherEvent(res[2]);
          } else if (selectedType === 1) {
            setOtherEvent(res[2]);
            setConstantOtherEvent(res[2]);
            setPlayerEvent([]);
            setConstantPlayerEvent([]);
            setAdminEvent([]);
            setConstantAdminEvent([]);
          } else if (selectedType === 2) {
            setAdminEvent(res[1]);
            setConstantAdminEvent(res[1]);
            setPlayerEvent([]);
            setConstantPlayerEvent([]);
            setOtherEvent([]);
            setConstantOtherEvent([]);
          } else if (selectedType === 3) {
            setPlayerEvent(res[0]);
            setConstantPlayerEvent(res[0]);
            setAdminEvent([]);
            setConstantAdminEvent([]);
            setOtherEvent([]);
            setConstantOtherEvent([]);
          }
        }

        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };
  // const [eventId, setSelectedEventListId] = useState([]);
  const [selectedPlayerEventListId, setSelectedPlayerEventListId] = useState(
    []
  );
  const [selectedAdminEventListId, setSelectedAdminEventListId] = useState([]);
  const [selectedOtherEventListId, setSelectedOtherEventListId] = useState([]);
  const handleSelectEvent = (id, selectedEventId, type) => {
    //for custom react-select
    // const selectedEventId = [];
    // selected.map((data) => selectedEventId.push(data.id));
    // setSelectedEventListId(selectedEventId);
    // setSelectedEventList(selected);
    if (id === 1) {
      setSelectedPlayerEventListId(
        selectedPlayerEventListId.includes(selectedEventId)
          ? selectedPlayerEventListId.filter((n) => n !== selectedEventId)
          : type
          ? [...selectedPlayerEventListId, selectedEventId]
          : selectedPlayerEventListId
      );
    }

    if (id === 2) {
      setSelectedAdminEventListId(
        selectedAdminEventListId.includes(selectedEventId)
          ? selectedAdminEventListId.filter((n) => n !== selectedEventId)
          : type
          ? [...selectedAdminEventListId, selectedEventId]
          : selectedAdminEventListId
      );
    }

    if (id === 3) {
      setSelectedOtherEventListId(
        selectedOtherEventListId.includes(selectedEventId)
          ? selectedOtherEventListId.filter((n) => n !== selectedEventId)
          : type
          ? [...selectedOtherEventListId, selectedEventId]
          : selectedOtherEventListId
      );
    }
    // setSelectedEventListId(
    //   eventId.includes(selectedEventId)
    //     ? eventId.filter((n) => n !== selectedEventId)
    //     : type
    //     ? [...eventId, selectedEventId]
    //     : eventId
    // );
  };
  const [viewAllModal, setViewAllModal] = useState(false);
  const openModal = () => {
    setViewAllModal(!viewAllModal);
    setactive({
      today: active.today,
      week: active.week,
      month: active.month,
      year: active.year,
      clear: "",
    });
  };
  const closeModal = () => {
    setViewAllModal(false);
    setSearchTerm();
    setAllEvent({
      player: null,
      admin: null,
      other: null,
    });
    setSelectedPlayerEventListId([]);
    setSelectedAdminEventListId([]);
    setSelectedOtherEventListId([]);
    setPlayerEventShowMore(false);
    setAdminEventShowMore(false);
    setOtherEventShowMore(false);
    // handleCloseMenu(false);

    //setAllType([]);
    getListing();
    setClear(true);
  };
  const getLogs = ({ obj }, pageN) => {
    const payloadStartDate = cloneDeep(
      obj === undefined ? startDate : obj.StartDate
    );
    const payloadEndDate = cloneDeep(obj === undefined ? endDate : obj.EndDate);

    const from_date = convertDateToHyphenFormat(payloadStartDate);
    const to_date = convertDateToHyphenFormat(payloadEndDate);
    // only call api if both have dates or both dont have dates , if only one of them has date then dont call calendar picker date

    if ((from_date && to_date) || (!from_date && !to_date)) {
      callLogs(to_date, from_date, pageN);
    }
  };
  useEffect(() => {
    //  if (!stopApiCall)
    //if (!viewAllModal)
    let obj = {
      StartDate: startDate,
      EndDate: endDate,
      Clear: clear,
    };
    getLogs({ obj }, pageNumber);
  }, [
    startDate,
    endDate,
    pageNumber,
    clear,
    sortBy,
    // selectedType
  ]);
  const callLogs = (to, from, pageN) => {
    if (!clear) {
      const filters = filterModel(to, from, pageN);
      setLoader(true);
      getActivityList(filters)
        .then((res) => {
          setLogs(res.data);
          settotalCount(res.total);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
    setClear(false);
  };
  function filterModel(to, from, pageN) {
    const player = selectedPlayerEventListId.length === 0 ? [] : [3];

    const admin = selectedAdminEventListId.length === 0 ? [] : [2];
    const other = selectedOtherEventListId.length === 0 ? [] : [1];
    const allType = [...player, ...admin, ...other];
    let model = {
      event: [
        ...selectedPlayerEventListId,
        ...selectedAdminEventListId,
        ...selectedOtherEventListId,
      ],
      from_date: from,
      to_date: to,
      sortBy: sortBy,
      //for single ttype type: selectedType,
      type: allType,
      page_size: 25,
      // page: eventId.length >= 1 ? 1 : pageNumber,
      page: pageN,
    };
    return model;
  }
  const applyEvents = () => {
    setpageNumber(1);
    setViewAllModal(false);
    setSearchTerm("");
    setPlayerEventShowMore(false);
    setAdminEventShowMore(false);
    setOtherEventShowMore(false);
    getLogs({ startDate, endDate });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (
    e,
    playerSearchEvent,
    adminSearchEvent,
    otherSearchEvent,
    type
  ) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    const keyword = e.target.value;
    if (keyword !== "") {
      if (type === null) {
        const playerEventSearch = playerSearchEvent.events.filter((data) => {
          return data.name.toLowerCase().includes(keyword.toLowerCase());
        });
        const playerData = {
          type: "Player",
          events: [...playerEventSearch],
        };
        // console.log(playerEventSearch, ">>>>>");
        // if (playerEventSearch.length === 0) {
        //   setPlayerEvent(playerData);
        // } else {
        setPlayerEvent(playerData);
        // }
        const adminEventSearch = adminSearchEvent.events.filter((data) => {
          return data.name.toLowerCase().includes(keyword.toLowerCase());
        });
        const adminData = {
          type: "Admin",
          events: [...adminEventSearch],
        };
        // if (adminEventSearch.length === 0) {
        //   setAdminEvent(adminData);
        // } else {
        setAdminEvent(adminData);
        //}
        const otherEventSearch = otherSearchEvent.events.filter((data) => {
          return data.name.toLowerCase().includes(keyword.toLowerCase());
        });
        const otherData = {
          type: "Other",
          events: [...otherEventSearch],
        };
        // if (otherEventSearch.length === 0) {
        //   setOtherEvent(otherData);
        // } else {
        setOtherEvent(otherData);
        // }
      } else if (type === 1) {
        const otherEventSearch = otherSearchEvent.events.filter((data) => {
          return data.name.toLowerCase().includes(keyword.toLowerCase());
        });
        const otherData = {
          type: "Other",
          events: [...otherEventSearch],
        };
        // if (otherEventSearch.length === 0) {
        //   setOtherEvent(otherData);
        // } else {
        setOtherEvent(otherData);
        // }
      } else if (type === 2) {
        const adminEventSearch = adminSearchEvent.events.filter((data) => {
          return data.name.toLowerCase().includes(keyword.toLowerCase());
        });
        const adminData = {
          type: "Admin",
          events: [...adminEventSearch],
        };
        // if (adminEventSearch.length === 0) {
        //   setAdminEvent(adminData);
        // } else {
        setAdminEvent(adminData);
        // }
      } else if (type === 3) {
        const playerEventSearch = playerSearchEvent.events.filter((data) => {
          return data.name.toLowerCase().includes(keyword.toLowerCase());
        });
        const playerData = {
          type: "Player",
          events: [...playerEventSearch],
        };
        // if (playerEventSearch.length === 0) {
        //   setPlayerEvent(playerData);
        // } else {
        setPlayerEvent(playerData);
        // }
      }
    } else {
      getListing(type);
    }
    //   const results = item.filter((data) => {
    //     return data.name.toLowerCase().startsWith(keyword.toLowerCase());
    //   });
    //   console.log(results);
    //   if (results.length === 0) {
    //     setNewEventList([]);
    //   } else {
    //     setNewEventList(results);
    //   }
    // } else {
    //   setTest(true);
    // }
    // setTest(false);
  };
  const debouncedResults = useCallback(
    debounce(
      (
        e,
        playerSearchEvent,
        adminSearchEvent,
        otherSearchEvent,
        selectedTypeN
      ) => {
        handleChange(
          e,
          playerSearchEvent,
          adminSearchEvent,
          otherSearchEvent,
          selectedTypeN
        );
      },
      300
    ),
    []
  );

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  const [allEvent, setAllEvent] = useState({
    player: null,
    admin: null,
    other: null,
  });
  // const [allType, setAllType] = useState([]);
  const handleSelectAllEvent = (id, checked) => {
    if (id === 1) {
      setAllEvent({
        player: checked ? 1 : null,
        admin: allEvent.admin,
        other: allEvent.other,
      });
      const playerId = playerEvent.events.map((d) => {
        return d.id;
      });
      const filteredPlayerId = checked
        ? [...selectedPlayerEventListId, ...playerId]
        : null;
      const finalPlayerId = [...new Set(filteredPlayerId)];
      setSelectedPlayerEventListId(
        checked
          ? [...finalPlayerId]
          : selectedPlayerEventListId.filter((e) => !playerId.includes(e))
      );
    }

    if (id === 2) {
      setAllEvent({
        player: allEvent.player,
        admin: checked ? 2 : null,
        other: allEvent.other,
      });
      const adminId = adminEvent.events.map((d) => {
        return d.id;
      });
      const filteredAdminId = checked
        ? [...selectedAdminEventListId, ...adminId]
        : null;
      const finalAdminId = [...new Set(filteredAdminId)];
      setSelectedAdminEventListId(
        checked
          ? [...finalAdminId]
          : selectedAdminEventListId.filter((e) => !adminId.includes(e))
      );
    }

    if (id === 3) {
      setAllEvent({
        player: allEvent.player,
        admin: allEvent.admin,
        other: checked ? 3 : null,
      });
      const otherId = otherEvent.events.map((d) => {
        return d.id;
      });

      const filteredOtherId = checked
        ? [...selectedOtherEventListId, ...otherId]
        : null;
      const finalOtherId = [...new Set(filteredOtherId)];
      setSelectedOtherEventListId(
        checked
          ? [...finalOtherId]
          : selectedOtherEventListId.filter((e) => !otherId.includes(e))
      );
    }
    //remove this code after type filter is enabled
    // setAllType(
    //   allType.includes(id)
    //     ? allType.filter((n) => n !== id)
    //     : checked
    //     ? [...allType, id]
    //     : allType
    // );
    //upto this
  };
  const [playerEventShowMore, setPlayerEventShowMore] = useState(false);
  const [adminEventShowMore, setAdminEventShowMore] = useState(false);
  const [otherEventShowMore, setOtherEventShowMore] = useState(false);
  const handleShowMore = (id, check) => {
    if (id === 1) {
      setPlayerEventShowMore(check);
    } else if (id === 2) {
      setAdminEventShowMore(check);
    } else {
      setOtherEventShowMore(check);
    }
  };
  const handleShowLess = (id, check) => {
    if (id === 1) {
      setPlayerEventShowMore(check);
    } else if (id === 2) {
      setAdminEventShowMore(check);
    } else {
      setOtherEventShowMore(check);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Activity Log" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="search-box">
                  <div className="col d-flex mb-3 ">
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => {
                        setSinglebtn(!singlebtn);
                        setactive({
                          today: active.today,
                          week: active.week,
                          month: active.month,
                          year: active.year,
                          clear: "",
                        });
                      }}
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split sort-by-btn"
                      >
                        {/* <i className="mdi mdi-filter-outline"></i> */}
                        <span>Sort By</span>
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu className="lobbies-menuListing">
                        {dropdownOptions.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => dropdownChange(item.value)}
                              dropdownvalue={item.value}
                            >
                              {item.text}
                              {selectedDropdown === item.value ? (
                                <i className="mdi mdi-check-circle-outline"></i>
                              ) : null}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                    {/* <Dropdown
                        isOpen={eventType}
                        toggle={() => setEventType(!eventType)}
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                          className="dropdown-toggle-split sort-by-btn ms-2"
                        >
                          <span>Type</span>
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu className="lobbies-menuListing">
                          {dropdownOptionsForEventfilter.map((item, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                onClick={() =>
                                  dropdownChangeForEventType(item.value)
                                }
                                dropdownvalue={item.value}
                              >
                                {item.text}
                                {selectedTypeDropdown === item.value ? (
                                  <i className="mdi mdi-check-circle-outline"></i>
                                ) : null}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown> */}
                    <Button
                      onClick={openModal}
                      className="dropdown-toggle-split users-sort-by-btn ms-3"
                      color="primary"
                    >
                      Events
                    </Button>
                    {/* {toggle ? (
                        <>
                          <CustomSelect
                            isOpen={toggle}
                            options={eventList}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                              MultiValue,
                              animatedComponents,
                            }}
                            onChange={handleSelectEvent}
                            allowSelectAll={true}
                            value={selectedEventList}
                            closeMenu={() => handleCloseMenu()}
                          />
                        </>
                      ) : null}

                      <Button onClick={openModal}>View All</Button> */}
                    <DateFiltersReports
                      className="ms-2"
                      startDate={startDate}
                      endDate={endDate}
                      updateDateFromPicker={updateDateFromPicker}
                      handleToday={handleToday}
                      handleWeek={handleWeek}
                      handleMonth={handleMonth}
                      handleYear={handleYear}
                      active={active}
                      handleClear={handleClear}
                    />
                  </div>
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered list-of-all-users-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>User Name</Th>
                          <Th data-priority="1">Email</Th>
                          <Th data-priority="3">Events</Th>
                          <Th data-priority="1">IP Address</Th>
                          <Th data-priority="3">VPN</Th>
                          <Th data-priority="6" className="no-wrap">
                            Date & Time
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {loader ? (
                          <div
                            class="spinner-grow spinner-class"
                            role="status"
                            style={{ marginTop: "40px" }}
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : logs.length === 0 ? (
                          <tr>
                            <td colspan="15" className="text-center mt-4">
                              No Logs Found
                            </td>
                          </tr>
                        ) : (
                          logs.map((item, index) => {
                            const date = new Date(item?.create_date);
                            const options = {
                              weekday: "short",
                              day: "numeric",
                              year: "numeric",
                              month: "long",
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                              timeZone: "America/New_York",
                              hour12: false,
                            };
                            const dateTime = date.toLocaleString(
                              "en-US",
                              options
                            );
                            return (
                              <Tr key={index}>
                                <Td className="username-field">
                                  {/* <Link to={`/user/${item.id}`}>
                                      {item.username}
                                    </Link> */}

                                  {item.username == null
                                    ? "NA"
                                    : item.username || "NA"}
                                </Td>
                                <Th>
                                  {item.email == null
                                    ? "NA"
                                    : item.email || "NA"}
                                </Th>
                                <Td>
                                  {item.action_name == null
                                    ? "NA"
                                    : item.action_name || "NA"}
                                </Td>
                                <Td>{item.ip || "NA"}</Td>
                                <Td>{item.vpn ? "Yes" : "No"} </Td>
                                <Td>{dateTime}</Td>
                              </Tr>
                            );
                          })
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
                <Paginator
                  totalCount={totalCount}
                  pageSize={pageSize}
                  pageClick={handlePageClick}
                  forcePage={pageNumber}
                />
              </CardBody>
              <Modal
                isOpen={viewAllModal}
                scrollable={true}
                className="custom-modal w-706"
              >
                <ModalHeader toggle={closeModal} tag="h4">
                  View All Events
                </ModalHeader>
                <ModalBody className="events">
                  <Label>
                    <span className="dark">
                      Events (
                      {selectedType === null
                        ? playerEvent?.events?.length +
                          adminEvent?.events?.length +
                          otherEvent?.events?.length
                        : selectedType === 1
                        ? otherEvent?.events?.length
                        : selectedType === 2
                        ? adminEvent?.events?.length
                        : selectedType === 3
                        ? playerEvent?.events?.length
                        : ""}
                      ){" "}
                    </span>
                    <span className="light ms-2">
                      {selectedPlayerEventListId.length +
                        selectedAdminEventListId.length +
                        selectedOtherEventListId.length}
                      {selectedPlayerEventListId.length +
                        selectedAdminEventListId.length +
                        selectedOtherEventListId.length >
                      1
                        ? " Events Selected"
                        : " Event Selected"}
                    </span>
                  </Label>
                  <div className="seacrh-box w-100 mt-2 mb-3">
                    <input
                      type="text"
                      className="w-100 searchEventInput"
                      placeholder="Search Event ..."
                      onChange={(e) =>
                        debouncedResults(
                          e,
                          playerEventConstant,
                          adminEventConstant,
                          otherEventConstant,
                          selectedType
                        )
                      }
                      defaultValue={searchTerm}
                    />
                  </div>
                  {/* <CustomSelect
                      isOpen={viewAllModal}
                      options={eventList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                        MultiValue,
                        animatedComponents,
                      }}
                      onChange={handleSelectEvent}
                      allowSelectAll={true}
                      value={selectedEventList}
                      //closeMenu={() => handleCloseMenu()}
                    /> */}
                  <div
                    className={
                      playerEvent.events?.length === 0
                        ? "allPermissions"
                        : "d-flex flex-wrap allPermissions"
                    }
                  >
                    {playerEventConstant.length === 0 ? null : (
                      <label className="eventsName">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          //id={`permissionButton${index}`}
                          onChange={(e) =>
                            handleSelectAllEvent(1, e.target.checked)
                          }
                          defaultChecked={
                            allEvent.player !== null ? "checked" : ""
                          }
                          disabled={
                            playerEvent.events?.length === 0 ? true : false
                          }
                        />
                        {playerEvent.type}
                      </label>
                    )}
                    {playerEvent.events?.length === 0 ? (
                      <p>No data found</p>
                    ) : (
                      <Row className="eventsRow">
                        {playerEvent.events
                          ?.slice(
                            0,
                            allEvent.player === 1 || playerEventShowMore
                              ? playerEvent.events?.length
                              : 9
                          )
                          .map((item, index) => {
                            return (
                              <Col key={index} className="col-4">
                                <div
                                  className={
                                    item.name.length > 30
                                      ? "updateProfileCheckbox d-flex"
                                      : "d-flex"
                                  }
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    //id={`permissionButton${index}`}
                                    name={item.name}
                                    onChange={(e) =>
                                      handleSelectEvent(
                                        1,
                                        item.id,
                                        e.target.checked
                                      )
                                    }
                                    checked={
                                      selectedPlayerEventListId.includes(
                                        item.id
                                      )
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                  <p className="ms-2">
                                    {item.name.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )}
                                  </p>
                                </div>
                              </Col>
                            );
                          })}
                        {allEvent.player === 1 ? null : playerEvent.length ===
                            0 ||
                          playerEvent.events?.length <=
                            9 ? null : playerEventShowMore ? (
                          <Button
                            className="viewMoreButton"
                            onClick={() => handleShowLess(1, false)}
                          >
                            View less
                          </Button>
                        ) : (
                          <Button
                            className="viewMoreButton"
                            onClick={() => handleShowMore(1, true)}
                          >
                            View more
                          </Button>
                        )}
                      </Row>
                    )}
                  </div>
                  <div
                    className={
                      adminEvent.events?.length === 0
                        ? "allPermissions"
                        : "d-flex flex-wrap allPermissions"
                    }
                  >
                    {adminEventConstant.length === 0 ? null : (
                      <label className="eventsName">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          //id={`permissionButton${index}`}
                          onChange={(e) =>
                            handleSelectAllEvent(2, e.target.checked)
                          }
                          defaultChecked={
                            allEvent.admin !== null ? "checked" : ""
                          }
                          disabled={
                            adminEvent.events?.length === 0 ? true : false
                          }
                        />
                        {adminEvent.type}
                      </label>
                    )}
                    {adminEvent.events?.length === 0 ? (
                      <p>No data found</p>
                    ) : (
                      <Row className="eventsRow">
                        {adminEvent.events
                          ?.slice(
                            0,
                            allEvent.admin === 2 || adminEventShowMore
                              ? adminEvent.events?.length
                              : 9
                          )
                          .map((item, index) => {
                            return (
                              <Col key={item.id} className="col-4">
                                <div
                                  className={
                                    item.name.length > 30
                                      ? "updateProfileCheckbox d-flex"
                                      : "d-flex"
                                  }
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    //id={`permissionButton${index}`}
                                    name={item.name}
                                    onChange={(e) =>
                                      handleSelectEvent(
                                        2,
                                        item.id,
                                        e.target.checked
                                      )
                                    }
                                    checked={
                                      selectedAdminEventListId.includes(item.id)
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                  <p className="ms-2">
                                    {item.name.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )}
                                  </p>
                                </div>
                              </Col>
                            );
                          })}
                        {allEvent.admin === 2 ? null : adminEvent.length ===
                            0 ||
                          adminEvent?.events?.length <=
                            9 ? null : adminEventShowMore ? (
                          <Button
                            className="viewMoreButton"
                            onClick={() => handleShowLess(2, false)}
                          >
                            View less
                          </Button>
                        ) : (
                          <Button
                            className="viewMoreButton"
                            onClick={() => handleShowMore(2, true)}
                          >
                            View more
                          </Button>
                        )}
                      </Row>
                    )}
                  </div>
                  <div
                    className={
                      otherEvent.events?.length === 0
                        ? "allPermissions"
                        : "d-flex flex-wrap allPermissions"
                    }
                  >
                    {otherEventConstant.length === 0 ? null : (
                      <label className="eventsName">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          //id={`permissionButton${index}`}
                          onChange={(e) =>
                            handleSelectAllEvent(3, e.target.checked)
                          }
                          defaultChecked={
                            allEvent.other !== null ? "checked" : ""
                          }
                          disabled={
                            otherEvent.events?.length === 0 ? true : false
                          }
                        />
                        {otherEvent.type}
                      </label>
                    )}

                    {otherEvent.events?.length === 0 ? (
                      <p>No data found</p>
                    ) : (
                      <Row className="eventsRow">
                        {otherEvent.events
                          ?.slice(
                            0,
                            allEvent.other === 3 || otherEventShowMore
                              ? otherEvent.events?.length
                              : 9
                          )
                          .map((item, index) => {
                            return (
                              <Col key={item.id} className="col-4">
                                <div
                                  className={
                                    item.name.length > 30
                                      ? "updateProfileCheckbox d-flex"
                                      : "d-flex"
                                  }
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    //id={`permissionButton${index}`}
                                    name={item.name}
                                    onChange={(e) =>
                                      handleSelectEvent(
                                        3,
                                        item.id,
                                        e.target.checked
                                      )
                                    }
                                    checked={
                                      selectedOtherEventListId.includes(item.id)
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                  <p className="ms-2">
                                    {item.name.replace(
                                      /(^\w{1})|(\s+\w{1})/g,
                                      (letter) => letter.toUpperCase()
                                    )}
                                  </p>
                                </div>
                              </Col>
                            );
                          })}

                        {allEvent.other === 3 ? null : otherEvent.length ===
                            0 ||
                          otherEvent?.events?.length <=
                            9 ? null : otherEventShowMore ? (
                          <Button
                            className="viewMoreButton"
                            onClick={() => handleShowLess(3, false)}
                          >
                            View less
                          </Button>
                        ) : (
                          <Button
                            className="viewMoreButton"
                            onClick={() => handleShowMore(3, true)}
                          >
                            View more
                          </Button>
                        )}
                      </Row>
                    )}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={applyEvents}
                    disabled={
                      selectedPlayerEventListId.length +
                        selectedAdminEventListId.length +
                        selectedOtherEventListId.length ===
                      0
                        ? true
                        : false
                    }
                  >
                    Apply
                  </Button>{" "}
                </ModalFooter>
              </Modal>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default ActivityLog;
