import React from "react";
import { Row, Card, CardHeader, CardBody, CardText } from "reactstrap";
import { reportCardsConstant } from "../../../constants/reportCardsConstant";
import info from "../../../assets/images/info-icon.svg";
import infoWhite from "../../../assets/images/info-icon-white.svg";
function FiltersResultInButton({
  data,
  type,
  detailView,
  setTransactionFilter = () => {},
  transactionFilter,
}) {
  const cardList = reportCardsConstant.map((labels) => labels);
  const displayCards = cardList.filter((item) => item.type == type);
  const handleTransactionCardClick = (item) => {
    setTransactionFilter((filter) =>
      filter == item?.tileKey ? 0 : item?.tileKey
    );
  };
  return (
    // <div className="d-flex align-items-center">
    detailView ? null : (
      <div className="d-flex flex-wrap mb-4 report-cards">
        {displayCards.map((group) => {
          return group.cardLabels.map((item, id) => {
            const keys = Object.keys(data);
            const matchingKeys = keys.filter((x) => x === item.key);
            const finalValue =
              matchingKeys.length !== 0 ? `${data[item.key]}` : "0.00";
            return (
              <Row key={id} className="depositCards">
                <Card
                  className="my-2 p-2"
                  color={
                    transactionFilter == item?.tileKey
                      ? "activeCards"
                      : "unactiveCards"
                  }
                  onClick={() => {
                    handleTransactionCardClick(item);
                  }}
                >
                  <CardHeader>
                    {item.label}{" "}
                    {item.label.toLowerCase() == "dormant money" ? (
                      <>
                        <span className="info-icon stats-infoIcon">
                          <img
                            src={
                              transactionFilter == item?.tileKey
                                ? infoWhite
                                : info
                            }
                            alt="info"
                            className="info-image"
                            data-tip
                            data-for="loginTip"
                          />
                          <span class="tooltiptext">
                            Filters are not applicable on this tile
                          </span>
                        </span>
                      </>
                    ) : null}
                  </CardHeader>
                  <CardBody>
                    <CardText>
                      {item?.units}
                      {finalValue}
                    </CardText>
                  </CardBody>
                </Card>
              </Row>
            );
          });
        })}
      </div>
    )
  );
}

export default FiltersResultInButton;
