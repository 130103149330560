import React, { useEffect, useState } from "react";
import { Button, CardTitle } from "reactstrap";
import CMSRow from "./CMSRow";
import {
  deleteQuestion,
  deleteTopic,
  editQuestion,
  editTopic,
  getQuestions,
  postQuestion,
} from "../../services/cms_api_helper";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import Loader from "../../components/Common/Loader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddQuestion from "./AddQuestion";
import QuestionList from "./QuestionList";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./accordian.scss";
import EditTopic from "./EditTopic";

function FAQ(props) {
  const [listView, setListView] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [topic, setTopic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [editQuestionData, setEditQuestionData] = useState({});
  const getFAQQuestions = async () => {
    try {
      setIsLoading(true);
      const filter = { filter: "FAQ" };
      const res = await getQuestions(filter);
      setIsLoading(false);
      setQuestions(res?.results);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const postQuestionFAQ = async ({ filter, callback }) => {
    try {
      setIsLoading(true);
      await postQuestion(filter);
      callback();
      setIsLoading(false);

      toast.success("Question Added Successfully", toastrOptions);
      // setListView(true)
      getFAQQuestions();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteQuestionFAQ = async ({ questionId, callback }) => {
    try {
      setIsLoading(true);
      await deleteQuestion({ questionId });

      toast.success("Question Deleted Successfully", toastrOptions);
      getFAQQuestions();
    } catch (error) {
      callback();
      setIsLoading(false);
    }
  };

  const deleteTopicFAQ = async ({ topicSlug, callback }) => {
    try {
      setIsLoading(true);
      await deleteTopic({ topicSlug });

      toast.success("Topic Deleted Successfully", toastrOptions);
      getFAQQuestions();
    } catch (error) {
      callback();
      setIsLoading(false);
    }
  };

  const editQuestionFAQ = async ({ payload, questionId }) => {
    try {
      setIsLoading(true);
      await editQuestion({ payload, questionId });
      disableEditView();
      toast.success("Question Edited Successfully", toastrOptions);
      getFAQQuestions();
    } catch (error) {
      disableEditView();
      setIsLoading(false);
    }
  };

  const reorderQuestion = async ({ payload, questionId }) => {
    try {
      await editQuestion({ payload, questionId });
      await getFAQQuestions();
      toast.success("Question Reorder Successfully", toastrOptions);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const reorderTopic = async ({ payload, topicSlug }) => {
    try {
      await editTopic({ payload, topicSlug });
      await getFAQQuestions();
      toast.success("Topic Reorder Successfully", toastrOptions);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const editTopicFAQ = async ({ payload, topicSlug, callback = () => {} }) => {
    try {
      await editTopic({ payload, topicSlug });
      await getFAQQuestions();
      callback();
      toast.success("Topic Edited Successfully", toastrOptions);
    } catch (error) {
      setIsLoading(false);
      callback();
    }
  };

  const enableEditView = ({ questionData }) => {
    setListView(false);
    setIsEdit(true);
    setEditQuestionData(questionData);
  };

  const enableEditViewFAQ = ({ questionData }) => {
    setListView(false);
    setTopic(true);
    console.log({ questionData });
    setEditQuestionData(questionData);
  };

  const disableEditView = () => {
    setListView(true);
    setIsEdit(false);
    setTopic(false);
  };

  const enableListView = () => {
    setListView(true);
    setIsEdit(false);
    setTopic(false);
  };
  const enableAddQuestionView = () => setListView(false);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result, index) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === "TOPIC") {
      const source = questions[result.source.index];
      const destination = questions[result.destination.index];
      const temp = [...questions];
      const payload = {
        sort_order: destination?.sort_order,
      };
      const topicSlug = source?.category?.slug;
      reorderTopic({ payload, topicSlug });
      const items = reorder(
        temp,
        result.source.index,
        result.destination.index
      );

      setQuestions(items);
    } else {
      const [answerId] = result?.draggableId.split("-");
      const source =
        questions[answerId]?.category.questions[result.source.index];
      const destination =
        questions[answerId]?.category.questions[result.destination.index];
      const payload = {
        sort_order: destination?.sort_order,
      };
      const questionId = source?.id;
      reorderQuestion({ payload, questionId });
      const reorderedSubQuestions = reorder(
        questions[answerId]?.category.questions,
        result.source.index,
        result.destination.index
      );
      const newQuestions = JSON.parse(JSON.stringify(questions));

      newQuestions[answerId].category.questions = reorderedSubQuestions;

      setQuestions(newQuestions);
    }
  };
  useEffect(() => {
    if (props?.data?.view) {
      getFAQQuestions();
    }
  }, [props.data?.view]);

  return (
    <>
      <Loader showLoader={isLoading} />

      {listView ? (
        <>
          <CardTitle className="d-flex justify-content-between align-items-start mb-4">
            <span className="cms-ppk">FAQ</span>
            {props?.data?.add ? (
              <div className="add-new-filter">
                <Button
                  type="submit"
                  color="primary"
                  onClick={enableAddQuestionView}
                >
                  Add New
                </Button>
              </div>
            ) : null}
          </CardTitle>
          <Accordion allowMultipleExpanded allowZeroExpanded>
            {questions?.length ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" type="TOPIC">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {questions?.map((subQuestions, index) => {
                        return (
                          <Draggable
                            key={subQuestions.id}
                            draggableId={`${subQuestions.id}`}
                            index={index}
                            isDragDisabled={!props.data?.change}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <AccordionItem key={subQuestions?.id}>
                                  <AccordionItemHeading
                                    {...provided.dragHandleProps}
                                  >
                                    <AccordionItemButton>
                                      <CMSRow
                                        key={subQuestions.id}
                                        questionData={{
                                          question:
                                            subQuestions?.category?.name,
                                          topicData: subQuestions,
                                          topic: subQuestions?.category,
                                        }}
                                        enableEditView={enableEditViewFAQ}
                                        deleteTopic={deleteTopicFAQ}
                                        isFaqHeading={true}
                                        editTopic={editTopicFAQ}
                                        disableDeleteTopic={
                                          subQuestions?.category?.questions
                                            ?.length
                                        }
                                        data={props.data}
                                      />
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel accordionId="1">
                                    {subQuestions?.category?.questions
                                      ?.length ? (
                                      <QuestionList
                                        enableAddQuestionView={
                                          enableAddQuestionView
                                        }
                                        questions={
                                          subQuestions?.category?.questions
                                        }
                                        enableEditView={enableEditView}
                                        deleteQuestion={deleteQuestionFAQ}
                                        onDragEnd={onDragEnd}
                                        isFaq={true}
                                        subQuestionid={subQuestions.id}
                                        questionNum={index}
                                        data={props.data}
                                      />
                                    ) : (
                                      <span>Not any questions added</span>
                                    )}
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <span>Not any topics added</span>
            )}
          </Accordion>
        </>
      ) : topic ? (
        <EditTopic
          enableListView={enableListView}
          editQuestionData={editQuestionData}
          editTopicFAQ={editTopicFAQ}
          data={props.data}
        />
      ) : (
        <AddQuestion
          isEdit={isEdit}
          enableListView={enableListView}
          editQuestionData={editQuestionData}
          postQuestion={postQuestionFAQ}
          editQuestion={editQuestionFAQ}
          isFaq={true}
          data={props.data}
        />
      )}
    </>
  );
}

export default FAQ;
