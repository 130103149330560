import { sortBy } from "lodash";
import {
  challengePermissionsGames,
  checkIfGamePermissionIsAvailable,
  permissionConstant,
  permissionConstantSecond,
  permissionGameConstantForLobbies,
} from "../constants/permissionConstant";
import challenges from "../../src/assets/images/icon_challenges.svg";

function PermissionPath() {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const notFound =
    obj === null
      ? true
      : obj.extras?.permissions?.length === 0
      ? true
      : obj.extras?.permissions.some((dataCheck) => {
          return dataCheck.label === "Static Page";
        });
  const gamePermissions = checkIfGamePermissionIsAvailable(obj);
  const path =
    obj === null
      ? "/login"
      : obj.extras?.permissions?.length === 0
      ? null
      : notFound
      ? obj.extras?.permissions?.map((data, idx) => {
          return permissionConstant.filter(
            (type) => type.permissionName === data.label
          );
        })
      : obj.extras?.permissions?.map((data, idx) => {
          return permissionConstantSecond.filter(
            (type) => type.permissionName === data.label
          );
        });

  //original name newPath
  let newPath =
    gamePermissions.length != 0
      ? [...path, [permissionGameConstantForLobbies[0]]]
      : path;

  const challengePath = challengePermissionsGames(gamePermissions)
  // const forChallenge =
  // gamePermissions.length != 0
  //   ? [...path, [permissionGameConstantForLobbies[0]]]
  //   : path;
  //remove after challenges permission
  const chal = [
    {
      permissionName: "Challenges",
      pathname: "/challenges",
      label: "Challenges",
      icon: challenges,
      order: 15,
    },
  ];
  newPath = challengePath.length ? [...newPath, chal] : newPath;
  if (newPath === null || newPath === "/login") {
    return null;
  } else if (newPath !== null || newPath !== "/login") {
    const extractArrayOfArrayPath = [];
    newPath.forEach((data) =>
      data.forEach((destructure) => extractArrayOfArrayPath.push(destructure))
    );
    const sortResult = sortBy(extractArrayOfArrayPath, ["order"], ["asc"]);
    return sortResult;
  } else {
    const extractArrayOfArrayPath = [];
    newPath.forEach((data) =>
      data.forEach((destructure) => extractArrayOfArrayPath.push(destructure))
    );
    return extractArrayOfArrayPath;
  }
}

export default PermissionPath;
