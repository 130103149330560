import { axiosApi, setToken } from "./index";
export async function getChallengesList(filterModel) {
  let params = new URLSearchParams();

  if (filterModel.sortBy)
    params.append(
      filterModel.sortBy == "1" || filterModel.sortBy == "2"
        ? "type"
        : "sort_by",
      filterModel.sortBy
    );
  if (filterModel.challengeMode)
    params.append("challenge_mode", filterModel.challengeMode);
  if (filterModel.game) params.append("game", filterModel.game);
  if (filterModel.status) params.append("status", filterModel.status);
  // if (filterModel.mode) params.append("type", filterModel.mode);
  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);

  setToken();

  return await axiosApi.get("challenges", { params }).then((response) => {
    return { data: response.results, total: response.total };
  });
}

export async function getChallengesById(challengeId) {
  setToken();
  return await axiosApi.get(`/challenges/${challengeId}/`);
}
export async function deleteChallenge(delId) {
  setToken();
  return await axiosApi.delete(`/challenges/${delId}/`);
}

export async function editChallenge(challengeId,model) {
  setToken();
  return await axiosApi.put(`/challenges/${challengeId}/`,model);
}
