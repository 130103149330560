import PropTypes from "prop-types";
import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import packageJson from "../package.json";
// import { getBuildDate } from "./helpers/util";
// import withClearCache from "./ClearCache";
// const ClearCacheComponent = withClearCache(MainApp);
// function App() {
//   return <ClearCacheComponent />;
// }
function App() {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <Switch>
          {authRoutes &&
            authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

          {userRoutes &&
            userRoutes.map((route, idx) => (
              <Authmiddleware
                exact={true}
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                permission={obj === null ? null : obj.extras?.permissions}
              />
            ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
// import PropTypes from "prop-types";
// import React from "react";

// import { Switch, BrowserRouter as Router } from "react-router-dom";
// import { connect } from "react-redux";

// // Import Routes all
// import { userRoutes, authRoutes } from "./routes/allRoutes";

// // Import all middleware
// import Authmiddleware from "./routes/middleware/Authmiddleware";

// // layouts Format
// import VerticalLayout from "./components/VerticalLayout/";
// import NonAuthLayout from "./components/NonAuthLayout";
// import withClearCache from "./ClearCache";
// import packageJson from "../package.json";
// import { getBuildDate } from "./helpers/util";

// // Import scss
// import "./assets/scss/theme.scss";
// import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from "react-toastify";
// const ClearCacheComponent = withClearCache(MainApp);
// console.log(getBuildDate(packageJson.buildDate), ">>>>>>>");
// function App() {
//   return <ClearCacheComponent />;
// }

// function MainApp() {
//   const obj = JSON.parse(localStorage.getItem("authUser"));
//   return (
//     <React.Fragment>
//       <ToastContainer />
//       <Router>
//         <Switch>
//           {authRoutes &&
//             authRoutes.map((route, idx) => (
//               <Authmiddleware
//                 path={route.path}
//                 layout={NonAuthLayout}
//                 component={route.component}
//                 key={idx}
//                 isAuthProtected={false}
//               />
//             ))}

//           {userRoutes &&
//             userRoutes.map((route, idx) => (
//               <Authmiddleware
//                 exact={true}
//                 path={route.path}
//                 layout={VerticalLayout}
//                 component={route.component}
//                 key={idx}
//                 isAuthProtected={true}
//                 permission={obj === null ? null : obj.extras?.permissions}
//               />
//             ))}
//         </Switch>
//       </Router>
//     </React.Fragment>
//   );
// }
// App.propTypes = {
//   layout: PropTypes.any,
// };

// const mapStateToProps = (state) => {
//   return {
//     layout: state.Layout,
//   };
// };

// export default connect(mapStateToProps, null)(App);
// //export default App;
