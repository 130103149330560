import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Button } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import { withRouter } from "react-router-dom";

import { changeAdminPassword } from "../../services/user_api_helper";

// toastr
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const ChangePassword = (props) => {
  const [loader, setLoader] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [showCurrentPassword, setshowCurrentPassword] = useState(false);

  const [disableSubmit, setDisableSubmit] = useState(false);
  async function handleValidSubmit(event, values) {
    event.preventDefault();
    let model = {
      current_password: values.currentpassword,
      new_password: values.newpassword,
      confirm_password: values.confirmpassword,
    };
    setLoader(true);
    setDisableSubmit(true);
    await changeAdminPassword(model)
      .then((res) => {
        setLoader(false);
        setDisableSubmit(false);
        toast.success(res.message, toastrOptions);
        setTimeout(() => {
          props.history.push("/logout");
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        setDisableSubmit(false);
      });
  }

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const toggleCurrentShow = () => {
    setshowCurrentPassword(!showCurrentPassword);
  };
  const toggleShow = () => {
    setshowPassword(!showPassword);
  };
  const toggleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="CHANGE PASSWORD" />
        <Row>
          {loader ? (
            <div
              class="spinner-grow spinner-class"
              role="status"
              style={{ marginTop: "40px" }}
            >
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <Col className="col-12">
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                {" "}
                <div className="col-5 mb-3 d-flex earningTimelines-box">
                  <AvField
                    name="currentpassword"
                    label="Current Password"
                    type={showCurrentPassword ? "text" : "password"}
                    required
                    placeholder="Enter Current Password"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Current password is required",
                      },
                    }}
                  />
                  <div className="daysBox">
                    <span>
                      <i
                        onClick={toggleCurrentShow}
                        class={
                          showCurrentPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="col-5 mb-3 d-flex earningTimelines-box">
                  <AvField
                    name="newpassword"
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder="Enter New Password"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "New password is required",
                      },
                    }}
                  />
                  <div className="daysBox">
                    <span>
                      <i
                        onClick={toggleShow}
                        class={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="col-5 mb-3 d-flex earningTimelines-box">
                  <AvField
                    name="confirmpassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    placeholder="Confirm Password"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Confirm password is required",
                      },
                      match: {
                        value: "newpassword",
                        errorMessage: "Confirm password didn't match",
                      },
                    }}
                  />
                  <div className="daysBox">
                    <span>
                      <i
                        onClick={toggleConfirmPassword}
                        class={
                          showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                </div>
                <FormGroup className="mt-4">
                  <div className="mt-4">
                    <Button
                      type="submit"
                      color="primary"
                      className="ms-1"
                      disabled={disableSubmit}
                    >
                      Update
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
    // <React.Fragment>
    //   <Loader showLoader={isLoading} />
    //   <div className="account-pages my-5 pt-sm-5">
    //     <Container>
    //       <Row className="justify-content-center">
    //         <Col md={8} lg={6} xl={5}>
    //           <Card className="overflow-hidden">
    //             <div className="card-body pt-5">
    //               <div className="p-2">
    //                 {successMsg ? (
    //                   <Alert
    //                     color="success"
    //                     className="text-center mb-4"
    //                     style={{ marginTop: "13px" }}
    //                   >
    //                     {successMsg}
    //                   </Alert>
    //                 ) : null}

    //               </div>
    //             </div>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>
  );
};

export default withRouter(ChangePassword);
