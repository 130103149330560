import React, { useCallback, useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getCompliantReports,
  getTransactionReport,
  getUserReports,
  getLobbiesReport,
  getCSV,
} from "../../services/user_api_helper";
import DateFiltersReports from "../../components/Common/DateFiltersReports";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { cloneDeep } from "lodash";
import FiltersResultInButton from "../../components/Common/FiltersResultInButton";
import TableBasedOnActiveTab from "../../components/Common/TableBasedOnActiveTab.js";

//Import images
import transaction_new from "../../assets/images/transaction_new.svg";
import user_new from "../../assets/images/user_new.svg";
import lobbies_new from "../../assets/images/lobbies_new.svg";
import complaints_new from "../../assets/images/complaints_new.svg";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { convertDateToHyphenFormat } from "../../helpers/util";
const Reports = (props) => {
  const intialPaginationState = { 1: 1, 2: 1, 3: 1, 4: 1 };
  const [isLoading, setisLoading] = useState(false);
  const [activeTab, setactiveTab] = useState(
    props.location.state === undefined ? "1" : props.location.state.active
  );
  const [detailView, setDetailView] = useState(
    props.location.state === undefined ? false : props.location.state.detailView
  );
  const [dateRange, setDateRange] = useState(
    props.location.state === undefined
      ? [null, null]
      : [props.location.state?.setDates[0], props.location.state?.setDates[1]]
  );
  const [pageNum, setPageNum] = useState(intialPaginationState);
  const [transactionFilter, setTransactionFilter] = useState(
    props.location.state === undefined ? 0 : props.location.state.filter
  );
  const [startDate, endDate] = dateRange;
  const [model, setModel] = useState(null);
  const [total, setTotal] = useState(null);
  const [active, setactive] = useState(
    props.location.state === undefined
      ? null
      : props.location.state.activeFilter
  );
  const activeTabOptions = [
    {
      type: 1,
      text: "transactions",
    },
    {
      type: 2,
      text: "users",
    },
    {
      type: 3,
      text: "lobbies",
    },
    {
      type: 4,
      text: "complaints",
    },
  ];

  const handleToday = useCallback(() => {
    const newEndDate = new Date();
    setDateRange([newEndDate, newEndDate]);
    setactive("1");
    setPageNum(intialPaginationState);
  }, []);

  const handleWeek = useCallback(() => {
    const newStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    const newEndDate = endOfWeek(new Date(), { weekStartsOn: 1 });
    setDateRange([newStartDate, newEndDate]);
    setactive("2");
    setPageNum(intialPaginationState);
  }, []);

  const handleMonth = useCallback(() => {
    const newStartDate = startOfMonth(new Date());
    const newEndDate = endOfMonth(new Date());
    setDateRange([newStartDate, newEndDate]);
    setactive("3");
    setPageNum(intialPaginationState);
  }, []);

  const handleYear = useCallback(() => {
    const newStartDate = startOfYear(new Date());
    const newEndDate = endOfYear(new Date());
    setDateRange([newStartDate, newEndDate]);
    setactive("4");
    setPageNum(intialPaginationState);
  }, []);
  const [clear, setClear] = useState(false);
  const handleClear = () => {
    setDateRange([null, null]);
    setClear(true);
    setactive("5");
    setPageNum(intialPaginationState);
  };
  const [fullLoader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const handleExport = () => {
    const reportType = activeTabOptions
      .filter((item) => item.type == activeTab)
      .map((dataExport) => {
        return dataExport.text;
      });
    let payload = {
      from_date: model.from_date,
      to_date: model.to_date,
      report: reportType,
      type: model.type,
    };
    setLoader(true);
    getCSV(payload)
      .then(async (res) => {
        const CREDENTIAL = {
          accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        };
        const bucketParams = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: `${process.env.REACT_APP_S3_CSV_EXPORT_FILE_PATH}/${res.filename}`,
        };
        const s3Client = new S3Client({
          region: process.env.REACT_APP_S3_REGION_NAME,
          credentials: CREDENTIAL,
        });
        const bucketData = new GetObjectCommand({
          Bucket: bucketParams.Bucket,
          Key: `${process.env.REACT_APP_S3_CSV_EXPORT_FILE_PATH}/${res.filename}`,
        });
        const url = await getSignedUrl(s3Client, bucketData);
        window.location.replace(url);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const updateDateFromPicker = useCallback((dates) => {
    const [start, end] = dates;
    if (start && end) {
      setPageNum(intialPaginationState);
      setactive(null);
    }
    setDateRange(dates);
  }, []);
  //default method
  useEffect(() => {
    if (props.location.state === undefined || props.location.state.filter === 0)
      handleMonth();
    //  else if(props.location.state.filter===0)handleMonth();
  }, []);

  //api call

  const getReport = ({ objReport, clear }) => {
    const payloadStartDate = cloneDeep(objReport.StartDate);
    const payloadEndDate = cloneDeep(objReport.EndDate);
    const from_date = convertDateToHyphenFormat(payloadStartDate);
    const to_date = convertDateToHyphenFormat(payloadEndDate);
    // only call api if both have dates or both dont have dates , if only one of them has date then dont call calendar picker date
    if ((from_date && to_date) || (!from_date && !to_date)) {
      if (activeTab === "1") {
        callTransaction(to_date, from_date, objReport.TransFilter, "1");
      } else if (activeTab === "2") {
        callUsers(to_date, from_date, objReport.TransFilter, "2");
      } else if (activeTab === "3") {
        callLobbies(to_date, from_date, objReport.TransFilter, "3");
      } else if (activeTab === "4") {
        callCompliant(to_date, from_date, objReport.TransFilter, "4");
      }

      if (objReport.TransFilter != 0) {
        setTableLoader(true);
      } else {
        setisLoading(true);
      }
    }
  };
  const [data, setData] = useState([]);
  const callCompliant = (to, from, type, reportType) => {
    const filters = filterModel(to, from, type, reportType);
    getCompliantReports(filters)
      .then((res) => {
        setData(res);
        setTotal(res?.total);
        setClear(false);
        showLoader();
      })
      .catch(() => {
        showLoader();
      });
  };
  const callUsers = (to, from, type, reportType) => {
    const filters = filterModel(to, from, type, reportType);
    getUserReports(filters)
      .then((res) => {
        setData(res);
        setTotal(res?.total);
        setClear(false);
        showLoader();
      })
      .catch(() => {
        showLoader();
      });
  };
  const [transactionData, setTransactionData] = useState({});
  const callTransaction = (to, from, type, reportType) => {
    const filters = filterModel(to, from, type, reportType);
    getTransactionReport(filters)
      .then((res) => {
        setTransactionData(res);
        setTotal(res?.total);
        setClear(false);
        showLoader();
      })
      .catch(() => {
        showLoader();
      });
  };
  const callLobbies = (to, from, type, reportType) => {
    const filters = filterModel(to, from, type, reportType);
    getLobbiesReport(filters)
      .then((res) => {
        setData(res);
        setTotal(res?.total);
        setClear(false);
        showLoader();
      })
      .catch(() => {
        showLoader();
      });
  };
  function filterModel(to, from, type = null, reportType) {
    if (clear) {
      let modelClear = {
        page_size: detailView ? 10 : 5,
        type: type === null ? undefined : type,
        page: pageNum?.[reportType],
      };
      setModel(modelClear);
      return modelClear;
    } else {
      let modelWithData = {
        from_date: from,
        to_date: to,
        page_size: detailView ? 10 : 5,
        type: type,
        page: pageNum?.[reportType],
      };
      setModel(modelWithData);
      return modelWithData;
    }
  }
  const showLoader = () => {
    if (transactionFilter != 0) {
      setTableLoader(false);
    } else {
      setisLoading(false);
    }
  };
  const handlePageClick = (page, type) => {
    const temp = { ...pageNum };
    temp[type] = page;
    setPageNum(temp);
  };
  const resetPagination = () => {
    setDetailView(false);
    setPageNum(intialPaginationState);
  };

  useEffect(() => {
    let objReport = {
      StartDate: startDate,
      EndDate: endDate,
      TransFilter: transactionFilter,
      //clear
    };
    getReport({ objReport, clear });
  }, [
    startDate,
    endDate,
    activeTab,
    detailView,
    transactionFilter,
    pageNum,
    clear,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Pages" breadcrumbItem="Reports" />
        {fullLoader ? (
          <div class="spinner-grow transaction-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="checkout-tabs">
            <Row>
              <Col lg="12">
                <Nav className="flex-row" pills>
                  {/* {permissionPPKFAQ.length === 0 ? null : (
                  <> */}
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "1"
                          ? "active d-flex"
                          : "d-flex justify-content-center"
                      }
                      onClick={() => {
                        setDetailView(false);
                        setactiveTab("1");
                        setPageNum(intialPaginationState);
                        setTransactionFilter(0);
                        setactive("3");
                        handleMonth();
                      }}
                    >
                      <img src={transaction_new} alt="transaction-icon" />
                      <p className="fw-bold mt-3 transaction-icon">
                        Transactions
                      </p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "2"
                          ? "active d-flex justify-content-center"
                          : "d-flex justify-content-center"
                      }
                      onClick={() => {
                        setDetailView(false);
                        setactiveTab("2");
                        setTransactionFilter(0);
                        setactive("3");
                        handleMonth();
                      }}
                    >
                      <img src={user_new} className="" alt="users-icon" />
                      <p className="fw-bold mt-3 transaction-icon">Users</p>
                    </NavLink>
                  </NavItem>
                  {/* </>
                )} */}

                  {/* {viewPermission ? (
                  <> */}
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "3"
                          ? "active d-flex justify-content-center"
                          : "d-flex justify-content-center"
                      }
                      onClick={() => {
                        setDetailView(false);
                        setactiveTab("3");
                        setPageNum(intialPaginationState);
                        setTransactionFilter(0);
                        setactive("3");
                        handleMonth();
                      }}
                    >
                      <img src={lobbies_new} className="" alt="lobbies-icon" />
                      <p className="fw-bold mt-3 transaction-icon">Lobbies</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "4"
                          ? "active d-flex justify-content-center"
                          : "d-flex justify-content-center"
                      }
                      onClick={() => {
                        //  callPrivacyPolicy();
                        setDetailView(false);
                        setactiveTab("4");
                        setPageNum(intialPaginationState);
                        setTransactionFilter(0);
                        setactive("3");
                        handleMonth();
                      }}
                    >
                      <img
                        src={complaints_new}
                        className=""
                        alt="complaints-icon"
                      />
                      <p className="fw-bold mt-3 transaction-icon">
                        Complaints
                      </p>
                    </NavLink>
                  </NavItem>
                  {/* </>
                ) : null} */}
                </Nav>
              </Col>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {isLoading ? (
                      <div
                        class="spinner-grow transaction-spinner"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <CardTitle className="h4 mb-5">
                            <DateFiltersReports
                              resetPagination={resetPagination}
                              detailView={detailView}
                              setDetailView={setDetailView}
                              startDate={startDate}
                              endDate={endDate}
                              updateDateFromPicker={updateDateFromPicker}
                              handleExport={handleExport}
                              handleToday={handleToday}
                              handleWeek={handleWeek}
                              handleMonth={handleMonth}
                              handleYear={handleYear}
                              active={active}
                              handleClear={handleClear}
                            />
                            <FiltersResultInButton
                              detailView={detailView}
                              data={transactionData}
                              transactionFilter={transactionFilter}
                              setTransactionFilter={setTransactionFilter}
                              type="1"
                            />
                            <div className="currentWalletBalance">
                              <span className="currentBalance">
                                Current Wallet Balance :
                                <span>
                                  {" "}
                                  {"$" +
                                    (transactionData?.wallet_balance
                                      ? transactionData?.wallet_balance
                                      : "")}
                                </span>
                              </span>
                            </div>
                            {tableLoader ? (
                              <div
                                class="spinner-grow transaction-spinner"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <TableBasedOnActiveTab
                                transactionFilter={transactionFilter}
                                pageNum={pageNum}
                                totalCount={total}
                                handlePageClick={handlePageClick}
                                data={transactionData}
                                detailView={detailView}
                                setDetailView={setDetailView}
                                type="1"
                                props={props}
                                active={active}
                                setDates={dateRange}
                              />
                            )}
                          </CardTitle>
                        </TabPane>
                        <TabPane tabId="2">
                          <CardTitle className="h4 mb-5">
                            <DateFiltersReports
                              resetPagination={resetPagination}
                              detailView={detailView}
                              setDetailView={setDetailView}
                              startDate={startDate}
                              endDate={endDate}
                              updateDateFromPicker={updateDateFromPicker}
                              handleExport={handleExport}
                              handleToday={handleToday}
                              handleWeek={handleWeek}
                              handleMonth={handleMonth}
                              handleYear={handleYear}
                              handleClear={handleClear}
                              active={active}
                              activeTab={activeTab}
                            />
                            <FiltersResultInButton
                              detailView={detailView}
                              setDetailView={setDetailView}
                              data={data}
                              type="2"
                              transactionFilter={transactionFilter}
                              setTransactionFilter={setTransactionFilter}
                            />

                            {tableLoader ? (
                              <div
                                class="spinner-grow transaction-spinner"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <TableBasedOnActiveTab
                                transactionFilter={transactionFilter}
                                pageNum={pageNum}
                                totalCount={total}
                                handlePageClick={handlePageClick}
                                detailView={detailView}
                                setDetailView={setDetailView}
                                data={data}
                                type="2"
                                props={props}
                                active={active}
                                setDates={dateRange}
                              />
                            )}
                          </CardTitle>
                        </TabPane>
                        <TabPane tabId="3">
                          <CardTitle className="h4 mb-5">
                            <DateFiltersReports
                              resetPagination={resetPagination}
                              detailView={detailView}
                              setDetailView={setDetailView}
                              startDate={startDate}
                              endDate={endDate}
                              updateDateFromPicker={updateDateFromPicker}
                              handleExport={handleExport}
                              handleToday={handleToday}
                              handleWeek={handleWeek}
                              handleMonth={handleMonth}
                              handleYear={handleYear}
                              handleClear={handleClear}
                              active={active}
                              activeTab={activeTab}
                            />
                            <FiltersResultInButton
                              detailView={detailView}
                              transactionFilter={transactionFilter}
                              setTransactionFilter={setTransactionFilter}
                              data={data}
                              type="3"
                            />

                            {tableLoader ? (
                              <div
                                class="spinner-grow transaction-spinner"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <TableBasedOnActiveTab
                                transactionFilter={transactionFilter}
                                pageNum={pageNum}
                                totalCount={total}
                                handlePageClick={handlePageClick}
                                detailView={detailView}
                                setDetailView={setDetailView}
                                data={data}
                                type="3"
                                props={props}
                                active={active}
                                setDates={dateRange}
                              />
                            )}
                          </CardTitle>
                        </TabPane>
                        <TabPane tabId="4">
                          <CardTitle className="h4 mb-5">
                            <DateFiltersReports
                              resetPagination={resetPagination}
                              detailView={detailView}
                              setDetailView={setDetailView}
                              startDate={startDate}
                              endDate={endDate}
                              updateDateFromPicker={updateDateFromPicker}
                              handleExport={handleExport}
                              handleToday={handleToday}
                              handleWeek={handleWeek}
                              handleMonth={handleMonth}
                              handleYear={handleYear}
                              handleClear={handleClear}
                              active={active}
                              activeTab={activeTab}
                            />
                            <FiltersResultInButton
                              detailView={detailView}
                              data={data}
                              type="4"
                              transactionFilter={transactionFilter}
                              setTransactionFilter={setTransactionFilter}
                            />

                            {tableLoader ? (
                              <div
                                class="spinner-grow transaction-spinner"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <TableBasedOnActiveTab
                                transactionFilter={transactionFilter}
                                pageNum={pageNum}
                                totalCount={total}
                                handlePageClick={handlePageClick}
                                detailView={detailView}
                                setDetailView={setDetailView}
                                data={data}
                                type="4"
                                props={props}
                                active={active}
                                setDates={dateRange}
                              />
                            )}
                          </CardTitle>
                        </TabPane>
                      </TabContent>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Reports;
