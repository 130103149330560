import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link, withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import {
  addEditGroups,
  getGroupLists,
  getPermissionLists,
  getGroupDetailByID,
} from "../../services/admin_user_api_helper";
import FindPermission from "../../helpers/Find_Permission";
import FindAllowedPermission from "../../helpers/FindAllowedPermission";
class AddEditGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  isLoading: false,
      editMode: false,
      groupId: null,
      errorMsg: null,
      groupName: null,
      assignedPermissionLists: [],
      allPermissions: [],
      givenPermission: [],
      allowedPermission: [],
      showPermission: false,
      defaultViewPermissionIds: [],
      uploadPermission: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getAllPermissionList();
    let groupId = this.props.match.params.groupId;
    if (groupId) {
      this.setState({
        editMode: true,
        groupId: groupId,
      });
      this.getGroupDetail(groupId);
    }
  }
  async getGroupDetail(groupId) {
    // this.showLoader(true);
    await getGroupDetailByID(groupId).then((res) => {
      this.mapDetailsToForm(res);
      //   this.showLoader(false);
    });
  }
  async getGroup() {
    await getGroupLists().then((res) => {
      this.setState({ options: res });
    });
  }
  async getAllPermissionList() {
    await getPermissionLists().then((res) => {
      this.setState({ allPermissions: res, showPermission: true });
    });
  }

  mapDetailsToForm(res) {
    this.setState({
      groupName: res.name,
      assignedPermissionLists: res.permissions,
    });
    this.callPermission(res.permissions);
  }
  callPermission = (trueValue) => {
    const setGivenPermission = FindPermission(trueValue);
    const setAllowedPermission = FindAllowedPermission(trueValue);
    let defaultViewId = [];
    let checkForDefaultUpload = [];
    setAllowedPermission.forEach((data) => {
      data.permissions.forEach((a) => {
        const permissionName = a.name.split(" ");
        if (permissionName[1] === "view" && data.permissions.length != 5) {
          defaultViewId.push(a.id);
        } else if (permissionName[1] === "Upload" && a.type_) {
          checkForDefaultUpload.push(a.id);
        }
      });
    });
    // const findDefaultViewPermissionObj =
    //   setAllowedPermission.permissions.at(-1);
    this.setState(
      {
        givenPermission: setGivenPermission,
        allowedPermission: setAllowedPermission,
        showPermission: true,
        defaultViewPermissionIds: defaultViewId,
        uploadPermission: checkForDefaultUpload,
      },
      () => {}
    );
  };
  async handleValidSubmit(event, values) {
    event.preventDefault();
    if (
      this.state.givenPermission.length === 0 &&
      this.state.defaultViewPermissionIds.length === 0
    ) {
      this.setState({ errorMsg: "Please allow some permissions" });
    } else {
      const finalPermissions = [
        ...this.state.givenPermission,
        ...this.state.defaultViewPermissionIds,
      ];
      const permissionsWithoutDuplicates = [...new Set(finalPermissions)];
      let model = {
        name: this.state.groupName,
        permissions: permissionsWithoutDuplicates,
      };
      this.showLoader(true);
      this.setState({ errorMsg: null });
      await addEditGroups(model, this.state.editMode, this.state.groupId).then(
        (_) => {
          if (_ != undefined) {
            this.showLoader(false);
            toast.success(
              `Group ${this.state.editMode ? "Updated" : "Added"} Successfully`,
              toastrOptions
            );
            this.goToListing();
          } else {
            this.showLoader(false);
            toast.error("Something went wrong", toastrOptions);
          }
        },
        (err) => {
          this.showLoader(false);
          if (err.length === 2) {
            this.setState({ errorMsg: null });
          } else {
            this.setState({ errorMsg: err });
          }
        }
      );
    }
  }

  goToListing() {
    this.props.history.push("/groups");
  }

  showLoader(isLoad) {
    this.setState({
      isLoading: isLoad,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleRadioPermission = (data, id, type) => {
    let findDefaultViewPermissionObj = [];
    data.permissions.forEach((a) => {
      const permissionName = a.name.split(" ");
      if (permissionName[1] === "view") {
        findDefaultViewPermissionObj.push(a.id);
      }
    });
    let checkForDefaultUpload = [];
    data.permissions.forEach((a) => {
      const permissionName = a.name.split(" ");
      if (permissionName[1] === "Upload" && a.type_) {
        checkForDefaultUpload.push(a.id);
      }
    });
    this.setState(
      {
        allowedPermission: this.state.allowedPermission.includes(data)
          ? this.state.allowedPermission.filter((n) => n !== data)
          : type
          ? [...this.state.allowedPermission, data]
          : this.state.allowedPermission.filter(
              (val) => val.label != data.label
            ),
        givenPermission: !type
          ? this.state.givenPermission.filter(
              (i) => !data.permissions.find((f) => f.id === i)
            )
          : [...this.state.givenPermission, ...checkForDefaultUpload],
        defaultViewPermissionIds: type
          ? [
              ...this.state.defaultViewPermissionIds,
              findDefaultViewPermissionObj[0],
            ]
          : this.state.defaultViewPermissionIds.includes(
              findDefaultViewPermissionObj[0]
            )
          ? this.state.defaultViewPermissionIds.filter(
              (n) => n !== findDefaultViewPermissionObj[0]
            )
          : this.state.defaultViewPermissionIds,
        uploadPermission: !type
          ? this.state.uploadPermission.filter(
              (i) => !data.permissions.find((f) => f.id === i)
            )
          : [...this.state.uploadPermission, ...checkForDefaultUpload],
      },
      () => {}
    );
  };
  handleCheckboxPermission = (newPermission, type, upload, allData) => {
    let findDefaultViewPermissionObj = [];
    if (upload == "Upload" && !type) {
      allData.forEach((a) => {
        const permissionName = a.name.split(" ");
        if (permissionName[1] === "view") {
          findDefaultViewPermissionObj.push(a.id);
        }
      });
    }
    if (this.state.editMode) {
      this.setState(
        {
          givenPermission: this.state.givenPermission.includes(newPermission)
            ? this.state.givenPermission.filter((n) => n !== newPermission)
            : type
            ? [...this.state.givenPermission, newPermission]
            : this.state.givenPermission,
          uploadPermission:
            upload == "Upload"
              ? this.state.uploadPermission.includes(newPermission)
                ? this.state.uploadPermission.filter((n) => n !== newPermission)
                : type
                ? [...this.state.uploadPermission, newPermission]
                : this.state.uploadPermission
              : this.state.uploadPermission,
          defaultViewPermissionIds:
            upload == "Upload"
              ? !type
                ? [
                    ...this.state.defaultViewPermissionIds,
                    findDefaultViewPermissionObj[0],
                  ]
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds.includes(newPermission)
              ? this.state.defaultViewPermissionIds.filter(
                  (n) => n !== newPermission
                )
              : this.state.defaultViewPermissionIds,

          errorMsg: null,
        },
        () => {
          const idsOfGameUpload = allData
            .filter((el) => this.state.givenPermission.includes(el.id))
            .map((filterId) => {
              return filterId.id;
            });
          //to remove ids if admin has selected upload permission (remove other game permission)
          const permissionAfterRemovingIds =
            type && upload === "Upload"
              ? this.state.givenPermission.includes(newPermission)
                ? this.state.givenPermission.filter((el) => {
                    if (el === newPermission) {
                      return idsOfGameUpload.includes(el);
                    } else {
                      return !idsOfGameUpload.includes(el);
                    }
                  })
                : this.state.givenPermission
              : this.state.givenPermission;
          let filterDview = [];
          if (type && upload === "Upload") {
            allData.forEach((a) => {
              const permissionName = a.name.split(" ");
              if (permissionName[1] === "view") {
                filterDview.push(a.id);
              }
            });
          }
          const viewIds =
            type && upload === "Upload"
              ? this.state.defaultViewPermissionIds.includes(filterDview[0])
                ? this.state.defaultViewPermissionIds.filter(
                    (n) => n !== filterDview[0]
                  )
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds;
          this.setState(
            {
              givenPermission: permissionAfterRemovingIds,
              defaultViewPermissionIds: viewIds,
            },
            () => {}
          );
        }
      );
    } else {
      this.setState(
        {
          givenPermission: this.state.givenPermission.includes(newPermission)
            ? this.state.givenPermission.filter((n) => n !== newPermission)
            : type
            ? [...this.state.givenPermission, newPermission]
            : this.state.givenPermission,

          uploadPermission:
            upload == "Upload"
              ? this.state.uploadPermission.includes(newPermission)
                ? this.state.uploadPermission.filter((n) => n !== newPermission)
                : type
                ? [...this.state.uploadPermission, newPermission]
                : this.state.uploadPermission
              : this.state.uploadPermission,
          defaultViewPermissionIds:
            upload == "Upload"
              ? !type
                ? [
                    ...this.state.defaultViewPermissionIds,
                    findDefaultViewPermissionObj[0],
                  ]
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds.includes(newPermission)
              ? this.state.defaultViewPermissionIds.filter(
                  (n) => n !== newPermission
                )
              : this.state.defaultViewPermissionIds,
        },
        () => {
          const idsOfGameUpload = allData
            .filter((el) => this.state.givenPermission.includes(el.id))
            .map((filterId) => {
              return filterId.id;
            });
          //to remove ids if admin has selected upload permission (remove other game permission)
          const permissionAfterRemovingIds =
            type && upload === "Upload"
              ? this.state.givenPermission.includes(newPermission)
                ? this.state.givenPermission.filter((el) => {
                    if (el === newPermission) {
                      return idsOfGameUpload.includes(el);
                    } else {
                      return !idsOfGameUpload.includes(el);
                    }
                  })
                : this.state.givenPermission
              : this.state.givenPermission;
          let filterDview = [];
          if (type && upload === "Upload") {
            allData.forEach((a) => {
              const permissionName = a.name.split(" ");
              if (permissionName[1] === "view") {
                filterDview.push(a.id);
              }
            });
          }
          const viewIds =
            type && upload === "Upload"
              ? this.state.defaultViewPermissionIds.includes(filterDview[0])
                ? this.state.defaultViewPermissionIds.filter(
                    (n) => n !== filterDview[0]
                  )
                : this.state.defaultViewPermissionIds
              : this.state.defaultViewPermissionIds;
          this.setState(
            {
              givenPermission: permissionAfterRemovingIds,
              defaultViewPermissionIds: viewIds,
            },
            () => {}
          );
        }
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        <Loader showLoader={this.state.isLoading} />
        <div className="page-content">
          <Breadcrumbs
            breadcrumbItem={(this.state.editMode ? "Edit" : "Add") + "Group"}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <p>
                        <Link to="/groups">
                          <i className="mdi mdi-arrow-left"></i> back
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Row className="add-staff-member">
                    <Col className="col-lg-8 col-md-8 col-sm-8 col-12">
                      <AvForm
                        onValidSubmit={(e, v) => {
                          this.handleValidSubmit(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="groupName"
                            label="Group Name*"
                            placeholder="Enter group name here"
                            type="text"
                            value={this.state.groupName}
                            onChange={this.handleChange}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Group name is required",
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "Group name can have 50 characters max",
                              },
                            }}
                          />
                        </div>
                        {this.state.showPermission ? (
                          <div className="mb-3 permissionsBoxes">
                            {this.state.editMode
                              ? this.state.assignedPermissionLists.map(
                                  (item, i) => {
                                    return (
                                      <div key={i} className="permission">
                                        <div className="d-flex justify-content-between">
                                          <lable className="permissionLable">
                                            {item.label}
                                          </lable>
                                          <div className="premissionsDiv">
                                            <span className="allowPermissions">
                                              <input
                                                type="radio"
                                                id={`permissionButton${i}`}
                                                name={item.label}
                                                //value={data?.id}
                                                onChange={() =>
                                                  this.handleRadioPermission(
                                                    item,
                                                    i,
                                                    true
                                                  )
                                                }
                                                defaultChecked={
                                                  item.permissions.some(
                                                    (e) => e?.type_ == true
                                                  )
                                                    ? "checked"
                                                    : ""
                                                }
                                              />
                                              Allow
                                            </span>
                                            <span>
                                              <input
                                                type="radio"
                                                id={`permissionButton${i}`}
                                                name={item.label}
                                                // value={data?.id}
                                                onChange={() =>
                                                  this.handleRadioPermission(
                                                    item,
                                                    i,
                                                    false
                                                  )
                                                }
                                                defaultChecked={
                                                  item.permissions.every(
                                                    (e) => e?.type_ == false
                                                  )
                                                    ? "checked"
                                                    : ""
                                                }
                                              />
                                              Deny
                                            </span>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                          {this.state.allowedPermission
                                            ?.length === 0
                                            ? null
                                            : this.state.allowedPermission?.map(
                                                (data, index) => {
                                                  if (data?.length === 0) {
                                                    return null;
                                                  } else if (
                                                    item.label !== data.label
                                                  ) {
                                                    return null;
                                                  } else {
                                                    return data?.permissions?.map(
                                                      (
                                                        permissionLists,
                                                        idx
                                                      ) => {
                                                        const permissionName =
                                                          permissionLists.name.split(
                                                            " "
                                                          );
                                                        const disableWholeIndex =
                                                          data?.permissions.filter(
                                                            (it) =>
                                                              this.state.uploadPermission.includes(
                                                                it.id
                                                              )
                                                          );
                                                        return (
                                                          <div
                                                            className={
                                                              permissionName[1] ==
                                                              "Upload"
                                                                ? "d-flex allPermissions col-4"
                                                                : "d-flex allPermissions col-2"
                                                            }
                                                            key={idx}
                                                          >
                                                            {item.label ==
                                                            data.label ? (
                                                              <>
                                                                <input
                                                                  className={
                                                                    permissionName[1] ==
                                                                    "Upload"
                                                                      ? "form-check-input viewUploadInput"
                                                                      : "form-check-input"
                                                                  }
                                                                  type="checkbox"
                                                                  id={`permissionButton${idx}`}
                                                                  name={
                                                                    permissionLists.codename
                                                                  }
                                                                  // value={permissionLists?.id}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.handleCheckboxPermission(
                                                                      permissionLists.id,
                                                                      e.target
                                                                        .checked,
                                                                      permissionName[1],
                                                                      data?.permissions
                                                                    )
                                                                  }
                                                                  defaultChecked={
                                                                    permissionLists.type_ ===
                                                                    true
                                                                      ? "checked"
                                                                      : permissionName[1] ==
                                                                        "view"
                                                                      ? "checked"
                                                                      : this.state.givenPermission.includes(
                                                                          permissionLists.id
                                                                        )
                                                                      ? "checked"
                                                                      : ""
                                                                  }
                                                                  disabled={
                                                                    disableWholeIndex.length !=
                                                                    0
                                                                      ? this.state.uploadPermission.some(
                                                                          (
                                                                            itemUpload
                                                                          ) =>
                                                                            itemUpload ===
                                                                            permissionLists.id
                                                                        )
                                                                        ? false
                                                                        : true
                                                                      : this.state.defaultViewPermissionIds.includes(
                                                                          permissionLists.id
                                                                        )
                                                                      ? true
                                                                      : permissionName[1] ==
                                                                        "view"
                                                                      ? true
                                                                      : false
                                                                  }
                                                                />
                                                                <p>
                                                                  {permissionName[1] ==
                                                                  "Upload"
                                                                    ? permissionLists.name
                                                                    : permissionName[1].toUpperCase()}
                                                                </p>
                                                              </>
                                                            ) : null}
                                                          </div>
                                                        );
                                                      }
                                                    );
                                                  }
                                                }
                                              )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : this.state.allPermissions.map((item, i) => {
                                  return (
                                    <div key={i} className="permission">
                                      <div className="d-flex justify-content-between">
                                        <lable className="permissionLable">
                                          {item.label}
                                        </lable>
                                        <div className="premissionsDiv">
                                          <span className="allowPermissions">
                                            <input
                                              type="radio"
                                              id={`permissionButton${i}`}
                                              name={item.label}
                                              //value={data?.id}
                                              onChange={() =>
                                                this.handleRadioPermission(
                                                  item,
                                                  i,
                                                  true
                                                )
                                              }
                                              defaultChecked={
                                                item.permissions.some(
                                                  (e) => e?.type_ == true
                                                )
                                                  ? "checked"
                                                  : ""
                                              }
                                            />
                                            Allow
                                          </span>
                                          <span>
                                            <input
                                              type="radio"
                                              id={`permissionButton${i}`}
                                              name={item.label}
                                              // value={data?.id}
                                              onChange={() =>
                                                this.handleRadioPermission(
                                                  item,
                                                  i,
                                                  false
                                                )
                                              }
                                              defaultChecked={
                                                item.permissions.every(
                                                  (e) => e?.type_ == false
                                                )
                                                  ? "checked"
                                                  : ""
                                              }
                                            />
                                            Deny
                                          </span>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between mt-3">
                                        {this.state.allowedPermission
                                          ?.length === 0
                                          ? null
                                          : this.state.allowedPermission?.map(
                                              (data, index) => {
                                                if (data?.length === 0) {
                                                  return null;
                                                } else if (
                                                  item.label !== data.label
                                                ) {
                                                  return null;
                                                } else {
                                                  return data?.permissions?.map(
                                                    (permissionLists, idx) => {
                                                      const permissionName =
                                                        permissionLists.name.split(
                                                          " "
                                                        );
                                                      const disableWholeIndex =
                                                        data?.permissions.filter(
                                                          (ind) =>
                                                            this.state.uploadPermission.includes(
                                                              ind.id
                                                            )
                                                        );
                                                      return (
                                                        <div
                                                          className={
                                                            permissionName[1] ==
                                                            "Upload"
                                                              ? "d-flex allPermissions col-4"
                                                              : "d-flex allPermissions col-2"
                                                          }
                                                          key={idx}
                                                        >
                                                          {item.label ==
                                                          data.label ? (
                                                            <>
                                                              <input
                                                                className={
                                                                  permissionName[1] ==
                                                                  "Upload"
                                                                    ? "form-check-input viewUploadInput"
                                                                    : "form-check-input"
                                                                }
                                                                type="checkbox"
                                                                id={`permissionButton${idx}`}
                                                                name={
                                                                  permissionLists.codename
                                                                }
                                                                // value={permissionLists?.id}
                                                                onChange={(e) =>
                                                                  this.handleCheckboxPermission(
                                                                    permissionLists.id,
                                                                    e.target
                                                                      .checked,
                                                                    permissionName[1],
                                                                    data?.permissions
                                                                  )
                                                                }
                                                                defaultChecked={
                                                                  permissionLists.type_ ===
                                                                  true
                                                                    ? "checked"
                                                                    : permissionName[1] ==
                                                                      "view"
                                                                    ? "checked"
                                                                    : this.state.givenPermission.includes(
                                                                        permissionLists.id
                                                                      )
                                                                    ? "checked"
                                                                    : ""
                                                                }
                                                                disabled={
                                                                  disableWholeIndex.length !=
                                                                  0
                                                                    ? this.state.uploadPermission.some(
                                                                        (
                                                                          itemDisable
                                                                        ) =>
                                                                          itemDisable ===
                                                                          permissionLists.id
                                                                      )
                                                                      ? false
                                                                      : true
                                                                    : this.state.defaultViewPermissionIds.includes(
                                                                        permissionLists.id
                                                                      )
                                                                    ? true
                                                                    : permissionName[1] ==
                                                                      "view"
                                                                    ? true
                                                                    : false
                                                                }
                                                              />
                                                              <p>
                                                                {permissionName[1] ==
                                                                "Upload"
                                                                  ? permissionLists.name
                                                                  : permissionName[1].toUpperCase()}
                                                              </p>
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              }
                                            )}
                                      </div>
                                    </div>
                                  );
                                })}
                          </div>
                        ) : null}
                        {this.state.errorMsg ? (
                          <p className="error-msg">{this.state.errorMsg}</p>
                        ) : null}

                        <FormGroup className="mt-4">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="ms-1"
                              disabled={this.state.isLoading}
                            >
                              Submit
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddEditGroup);
