import React, { useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { deleteChallenge } from "../../services/challenges_api_helper";
import SweetAlert from "react-bootstrap-sweetalert";
const DeleteAlert = (props) => {
  const [disableSwal2, setDisabled2] = useState(false);
  const handleDelete = async () => {
    props.Loader();
    props.onClose();
    setDisabled2(true);
    await deleteChallenge(props.DeleteId)
      .then((res) => {
        props.StopLoader();
        props.callApi();
        toast.success(res.message, toastrOptions);
        setDisabled2(false);
      })
      .catch(() => {
        props.onClose();
        props.StopLoader();
        setDisabled2(false);
      });
  };
  const closeAlert = () => {
    props.onClose();
  };
  return (
    <SweetAlert
      title="Delete Challenge"
      warning
      showCancel
      confirmButtonText="Yes"
      confirmBtnBsStyle="success"
      cancelButtonText="No"
      cancelBtnBsStyle="danger"
      onConfirm={() => handleDelete()}
      onCancel={() => closeAlert()}
      disabled={disableSwal2}
    >
      <p>Are you sure you want to delete this challenge?</p>
      <strong>{props.Versus}</strong>
    </SweetAlert>
  );
};
DeleteAlert.propTypes = {
  onClose: PropTypes.func,
  callApi: PropTypes.func,
  Loader: PropTypes.func,
  StopLoader: PropTypes.func,
};
export default DeleteAlert;
