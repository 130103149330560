import { axiosApi, setToken } from "./index";

// API Methods
export async function getAdminUserList(filterModel) {
  let params = new URLSearchParams();

  if (filterModel.searchTerm && filterModel.searchTerm !== "")
    params.append("query", filterModel.searchTerm);

  if (filterModel.sortBy && filterModel.sortBy !== "")
    params.append("sort_by", filterModel.sortBy);

  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);

  setToken();

  return await axiosApi.get("staff/", { params }).then((response) => {
    return { data: response.results, total: response.total };
  });
}

export async function getAdminUserDetail(userId) {
  setToken();
  return await axiosApi.get(`staff/${userId}/`);
}

export async function addeditAdminUser(model, mode, adminId) {
  setToken();
  if (mode) {
    return await axiosApi.put(`staff/${adminId}/`, model);
  } else {
    return await axiosApi.post(`staff/`, model);
  }
}

export async function changeAdminStatus(userId, activeStatus) {
  setToken();
  return await axiosApi.put(`staff/${userId}/update-status/`, {
    status: activeStatus,
  });
}
export async function getGroupLists() {
  setToken();
  return await axiosApi.get("groups/");
}
export async function getPermissionLists() {
  setToken();
  return await axiosApi.get("permissions/");
}
export async function getStaffDetailByID(adminId) {
  setToken();
  return await axiosApi.get(`staff/${adminId}/`);
}

export async function deleteAdmin(id) {
  setToken();
  return await axiosApi.delete(`staff/${id}/`);
}
export async function getAdminUserListLobby() {
  setToken();
  return await axiosApi.get(`staff-members/`);
}

//Groups module api start

export async function getGroupList() {
  // filterModel
  //   let params = new URLSearchParams();

  //   if (filterModel.searchTerm && filterModel.searchTerm !== "")
  //     params.append("query", filterModel.searchTerm);

  //   if (filterModel.sortBy && filterModel.sortBy !== "")
  //     params.append("sort_by", filterModel.sortBy);

  //   if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

  //   if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);

  setToken();

  return await axiosApi.get("groups/").then((response) => {
    return { data: response };
  });
}
export async function addEditGroups(model, mode, groupId) {
  setToken();
  if (mode) {
    return await axiosApi.put(`groups/${groupId}/`, model);
  } else {
    return await axiosApi.post(`groups/`, model);
  }
}
export async function getGroupDetailByID(groupId) {
  setToken();
  return await axiosApi.get(`groups/${groupId}/`);
}
export async function deleteGroup(id) {
  setToken();
  return await axiosApi.delete(`groups/${id}/`);
}
//Groups module api end
