import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// import images
import logo from "../../assets/images/logo-sm-dark.png";
import { forgotpassword } from "../../services/auth_api_helper";
import Loader from "../../components/Common/Loader";

const ForgetPasswordPage = (props) => {
  const [successMsg, setsuccessMsg] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState(null);

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  async function handleValidSubmit(event, values) {
    event.preventDefault();
    toggleLoader(true);
    setsuccessMsg(null);
    seterrorMessage(null);
    await forgotpassword(values.email)
      .then((res) => {
        toggleLoader(false);
        setsuccessMsg(res.message);
      })
      .catch((err) => {
        toggleLoader(false);
        if (err) {
          seterrorMessage(err);
        }
      });
  }

  function toggleLoader(isLoad) {
    setisLoading(isLoad);
  }

  return (
    <React.Fragment>
      <Loader showLoader={isLoading} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Reset Password</h5>
                    <a href="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </a>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    {successMsg ? (
                      <Alert
                        color="success"
                        className="text-center mb-4"
                        style={{ marginTop: "13px" }}
                      >
                        {successMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          validate={{
                            email: {
                              value: true,
                              errorMessage: "Invalid email address format",
                            },
                            required: {
                              value: true,
                              errorMessage: "Email address is required",
                            },
                            maxLength: {
                              value: 50,
                              errorMessage:
                                "Email address can have 50 characters max",
                            },
                          }}
                        />
                      </div>

                      {errorMessage ? (
                        <p className="error-msg">{errorMessage}</p>
                      ) : null}

                      <Row className="row mb-0">
                        <Col className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>

                      <div className="mt-5 text-center">
                        <p>
                          Remember It ?{" "}
                          <Link to="/login" className="fw-medium text-primary">
                            {" "}
                            Sign In here
                          </Link>{" "}
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
