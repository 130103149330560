import { axiosApi } from "./index";
export async function login({ email, password }) {
  const body = {
    email: email,
    password: password,
  };

  const url = process.env.REACT_APP_APIURL + "login/";
  const isProduction =
    process.env.REACT_APP_PRODUCTION === "true" ? true : false;
  let headers;

  if (!isProduction) {
    headers = {
      Authorization: `Bearer ${process.env.REACT_APP_CLIENTTOKEN}`,
      "Content-Type": "application/json",
    };
  }

  return await axiosApi
    .post(url, body, { headers })
    .then((res) => {
      if (res !== undefined) {
        localStorage.setItem("authUser", JSON.stringify(res));
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function forgotpassword(email) {
  const body = {
    email: email,
  };

  const url = process.env.REACT_APP_APIURL + "forgot-password/";

  const isProduction =
    process.env.REACT_APP_PRODUCTION === "true" ? true : false;

  let headers;

  if (!isProduction) {
    headers = {
      Authorization: `Bearer ${process.env.REACT_APP_CLIENTTOKEN}`,
      "Content-Type": "application/json",
    };
  }

  return await axiosApi.post(url, body, { headers });
}

export async function confirmpassword(password, id, token) {
  const body = {
    password: password,
    confirm_password: password,
  };

  const url =
    process.env.REACT_APP_APIURL + `reset-password/?id=${id}&token=${token}`;

  const isProduction =
    process.env.REACT_APP_PRODUCTION === "true" ? true : false;

  let headers;

  if (!isProduction) {
    headers = {
      Authorization: `Bearer ${process.env.REACT_APP_CLIENTTOKEN}`,
      "Content-Type": "application/json",
    };
  }

  return await axiosApi.put(url, body, { headers });
}

export async function logoutuserEvent() {
  return await axiosApi.put(`/logout/`);
}
