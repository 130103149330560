import { Link, withRouter } from "react-router-dom";

import { Card, CardBody, Col, Row } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import React, { Component } from "react";
import { getAdminUserDetail } from "../../services/admin_user_api_helper";

class AdminUserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminUser: null,
    };
  }

  componentDidMount() {
    let userId = this.props.match.params.adminId;
    if (userId) {
      getAdminUserDetail(userId)
        .then((res) => {
          this.setState({ adminUser: res });
        })
        .catch((err) => {
          this.goToListing();
        });
    }
  }

  goToListing() {
    this.props.history.push("/staff");
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Breadcrumbs breadcrumbItem="Staff Member Detail" />
          <Row>
            <Col>
              <Card>
                {this.state.adminUser && (
                  <CardBody>
                    <Row className="mb-4">
                      <Col>
                        <p>
                          <Link to="/staff">
                            <i className="mdi mdi-arrow-left"></i> back
                          </Link>
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <h4 className="mb-4">
                          {this.state.adminUser?.first_name}{" "}
                          {this.state.adminUser?.last_name}
                        </h4>
                      </Col>
                      <Col className="col-12 mb-3">
                        Username: {this.state.adminUser?.username}
                      </Col>
                      <Col className="col-12 mb-3">
                        Email address: {this.state.adminUser?.email || "NA"}
                      </Col>
                      <Col className="col-12 mb-3">
                        Phone Number: {this.state.adminUser?.phone || "NA"}
                      </Col>
                      <Col className="col-12 mb-3">
                        Address: {this.state.adminUser?.address?.full || "NA"}
                      </Col>
                      <Col className="col-12 mb-3">
                        Profile Status:{" "}
                        {this.state.adminUser?.profile?.is_verified
                          ? "Verified"
                          : "Unverified"}
                      </Col>
                    </Row>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AdminUserDetail);
